<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Reset Password v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <a href="{{ 'CRYPTOIN.CRYPTOINVPN_URL' | translate }}" class="brand-logo align-items-center">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="47" />
          <h2 class="brand-text text-primary ml-1 mb-0">{{ coreConfig.app.appName }}</h2>
        </a>

        <h4 class="card-title mb-1">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.TITLE' | translate }}</h4>
        <p class="card-text mb-2">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.TEXT' | translate }}</p>

        <form class="auth-reset-password-form mt-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-new">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.NEW_PASSWORD' | translate }}</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input [type]="passwordTextType ? 'text' : 'password'"
                     formControlName="newPassword"
                     class="form-control form-control-merge"
                     placeholder="············"
                     aria-describedby="reset-password-new"
                     tabindex="1"
                     autofocus
                     [ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer">
                  <i class="feather font-small-4"
                     [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                     (click)="togglePasswordTextType()"></i>
                </span>
              </div>
            </div>
            <div *ngIf="submitted && f.newPassword.errors"
                 class="invalid-feedback"
                 [ngClass]="{ 'd-block': submitted && f.newPassword.errors }">
              <div *ngIf="f.newPassword.errors.required">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.NEW_PASSWORD_ERROR1' | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-confirm">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.CONFIRM_PASSWORD' | translate }}</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input [type]="confPasswordTextType ? 'text' : 'password'"
                     formControlName="confirmPassword"
                     class="form-control form-control-merge"
                     placeholder="············"
                     aria-describedby="reset-password-confirm"
                     tabindex="2"
                     [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer">
                  <i class="feather font-small-4"
                     [ngClass]="{
                      'icon-eye-off': confPasswordTextType,
                      'icon-eye': !confPasswordTextType
                    }"
                     (click)="toggleConfPasswordTextType()"></i>
                </span>
              </div>
            </div>
            <div *ngIf="f.confirmPassword.value!=f.newPassword.value && !f.confirmPassword.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.confirmPassword.value!=f.newPassword.value }">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.CONFIRM_PASSWORD_ERROR1' | translate }}</div>
            <div *ngIf="submitted && f.confirmPassword.errors"
                 class="invalid-feedback"
                 [ngClass]="{ 'd-block': submitted && f.confirmPassword.errors }">
              <div *ngIf="f.confirmPassword.errors.required">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.CONFIRM_PASSWORD_ERROR1' | translate }}</div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="3" rippleEffect>{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.ACTION.RESET' | translate }}</button>
          <p class="text-center mt-2">
            <a routerLink="/login"><i data-feather="chevron-left" class="mb-25"></i> {{ 'ACCOUNT.DIALOG.RESET_PASSWORD.ACTION.LOGIN' | translate }}</a>
          </p>

          <hr class="invoice-spacing mt-2" />

          <div class="form-group row pl-0 mt-2 mb-0">
            <div class="pl-1 col-form-label">
              <label>{{ 'ACCOUNT.DIALOG.LOGIN.LOCALE' | translate }}</label>
            </div>
            <select [(ngModel)]="selectedMenuLocale" formControlName="locale" class="form-control ml-1 col-3" (change)="setDialogLanguage()">
              <option *ngFor="let loc of menuLocale" [ngValue]="loc.value">{{ loc.name }}</option>
            </select>
          </div>

        </form>

      </div>
    </div>
    <!-- /Reset password-->
  </div>
</div>
