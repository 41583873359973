<!-- app-content-header start -->
<ng-container *ngIf="contentHeader">
  <div class="content-header row">
    <div class="content-header-left col-md-9 col-12 mb-2">
      <div class="row breadcrumbs-top">
        <div class="col-12 d-flex">
          <h2 class="content-header-title float-left mb-0">
            {{ contentHeader.headerTitle }}
          </h2>
          <!-- app-breadcrumb component -->
          <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb"></app-breadcrumb>
        </div>
      </div>
    </div>
    <ng-container *ngIf="contentHeader.actionButton">
      <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
        <div class="form-group breadcrum-right">
          <div ngbDropdown>
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-primary btn-round btn-sm"
              type="button"
              rippleEffect
            >
              <span [data-feather]="'grid'"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownSettings">
              <a ngbDropdownItem [routerLink]="['/' + urlList.VPNClients ]"><span [data-feather]="'download'" [class]="'mr-50'"></span>{{ 'COMMON.QUICK_MENU.VPN_CLIENTS' | translate }}</a>
              <a ngbDropdownItem [routerLink]="['/' + urlList.OrderNow ]"><span [data-feather]="'shopping-cart'" [class]="'mr-50'"></span> {{ 'COMMON.QUICK_MENU.BUY_NOW' | translate }}</a>
              <a ngbDropdownItem [routerLink]="['/' + urlList.MyProfile ]"><span [data-feather]="'user'" [class]="'mr-50'"></span> {{ 'COMMON.QUICK_MENU.MY_PROFILE' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<!-- app-content-header end -->
