import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CommonService {
  //-- Paimame sisteminės įmonės kodą iš localstorage, nes jis naudojamas indentifikuojant įmonę connection DB
  systemCompanyCode: string = localStorage.getItem('ccode');

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string
  ) { }

  getCryptoinServicesPriceList<CryptoinServicesPricesList>(serviceType: number = -1): Observable<CryptoinServicesPricesList[]> {
    var url = this.baseUrl + "api/common/CryptoinServicesPriceList";
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("serviceType", serviceType);
    return this.http.get<CryptoinServicesPricesList[]>(url, { headers, params });
  }

  getAppSetting(settingName, type): Observable<any> {
    var url = this.baseUrl + "api/common/GetAppSetting";
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("settingName", settingName).set("type", type);
    return this.http.get(url, { headers, params, responseType: "text" });
  }

  getCryptoinServicesDiscount(cryptoinServiceID: number = -1, merchantEmail: string = null): Observable<number> {
    var url = this.baseUrl + "api/common/CryptoinServiceDiscount";

    if (merchantEmail)
      var headers = new HttpHeaders().set("ccode", window.btoa(merchantEmail));
    else {
      var headers = new HttpHeaders().set("ccode", this.systemCompanyCode == null ? "" : this.systemCompanyCode);
    }

    var params = new HttpParams().set("cryptoinServiceID", cryptoinServiceID);
    return this.http.get<number>(url, { headers, params});
  }
}
