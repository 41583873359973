import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { UrlList } from 'app/constant';
import { CryptoinMessagesList } from 'app/cryptoin-vpn/common/common.data';
import { SubscriptionResolve } from 'app/cryptoin-vpn/common/subscription/subscription.resolve';

@Component({
  selector: 'navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  public crytpoinMessagesList: CryptoinMessagesList[];
  urlList = UrlList;
  _unsubscribeAll: Subject<any>;

  constructor(
    public _subscriptionService: SubscriptionResolve,
    private _coreTranslationService: CoreTranslationService
  ) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en);
  }

  ngOnInit(): void {
    // Subscribe to Message List
    this._subscriptionService.onNewSOLCrmMessagesListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.crytpoinMessagesList = res;
    });
  }
}
