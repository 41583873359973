<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown">
    <i class="ficon" data-feather="bell"></i>
    <span class="badge badge-pill badge-danger badge-up">{{ crytpoinMessagesList?.length }}</span>
  </a>
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">{{ 'CRYPTOIN.NOTIFICATION.TITLE' | translate }}</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ crytpoinMessagesList?.length }} {{ 'CRYPTOIN.NOTIFICATION.NEW' | translate }}
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let message of crytpoinMessagesList">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar bg-light-{{ message.messageColorCode ? message.messageColorCode : 'primary' }}">
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="'message-circle'"></i></div>
            </div>
          </div>
          <div class="media-body">
            <!--<p class="media-heading " [innerHTML]="message.title"></p>-->
            <p class="{{ message.messagePriorityColorCode!='' ? 'media-heading font-weight-bold text-' + message.messagePriorityColorCode : 'media-heading' }}" [innerHTML]="message.title"></p>
            <small class="notification-text">{{ message.description }}</small>
          </div>
        </div>
      </a>
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" [routerLink]="['/' + urlList.StartupPage ]">{{ 'CRYPTOIN.NOTIFICATION.ACTION.SHOW_ALL' | translate }}</a>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>
