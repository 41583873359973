import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

//import { VBCart, VBCartList } from 'app/cryptoin-vpn/plan-management/plan-management.data';
//import { PlanManagementService } from 'app/so-libra/plan-management/plan-management.service';
import { SubscriptionResolve } from 'app/cryptoin-vpn/common/subscription/subscription.resolve';
import { CommonService } from 'app/cryptoin-vpn/common/common.service';
import { AppSettingName, AppSettingsTypeList } from 'app/constant';
import { environment } from 'environments/environment';

@Component({
  selector: 'navbar-cart',
  templateUrl: './navbar-cart.component.html'
})
export class NavbarCartComponent implements OnInit {
  public cartList: any[] = [];
  public cartListLength: number = 0;
  private _appSettingsList = AppSettingsTypeList;

  processPaymentStatus: boolean = false;
  total: number = 0;
  vatSum: number = 0;
  totalAmount: number = 0;
  _unsubscribeAll: Subject<any>;

  constructor(
    //public _planManagementService: PlanManagementService,
    public _subscriptionService: SubscriptionResolve,
    private _coreTranslationService: CoreTranslationService,
    private _commonService: CommonService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en);
  }

  ngOnInit(): void {
    // Subscribe to Cart List
    this._subscriptionService.onCartListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.cartList = res;
      this.cartListLength = this.cartList.length;
      this.calculateTotals();
    });
  }

  refreshCart() {
    //-- Atnaujiname krepšelio turinį
    /*
    this._planManagementService.getCartList<VBCartList>().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
      this.cartList = result;
      this._subscriptionService.onCartListChange.next(this.cartList);
    });
    */
  }

  calculateTotals() {
    //-- Suskaičiojame sumą
    this.total = 0;
    this.cartList.forEach((item) => {
      this.total += (item.quantity * item.price);
    });
    this.vatSum = this.total * (21 / 100);
    this.totalAmount = this.total + this.vatSum;
  }

  makePayment() {
    this.processPaymentStatus = true;
    this.processPayment();
  }

  processPayment() {
    /*
    this._commonService.getAppSetting(AppSettingName.SystemCompanyEmail, this._appSettingsList.find(({ name }) => name === AppSettingName.SystemCompanyEmail).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => { 
        let _email: string = result;
        this._planManagementService.makePayment(_email, environment.apiUrl).subscribe(result => {
          window.open(result, '_self');
          this.refreshCart();
        });
      })
    */
  }
}
