import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { CoreCommonModule } from '@core/common.module';
import { TranslateModule } from '@ngx-translate/core';

// Payment systems
import { NgxPayPalModule } from 'ngx-paypal';

import { PaymentPageComponent } from 'app/pages/web/payment-page/payment-page.component';
import { InstructionPageComponent } from 'app/pages/web/instruction-page/instruction-page.component';
import { PrivacyPolicyPageComponent } from 'app/pages/web/privacy-policy-page/privacy-policy-page.component';
import { WebService } from 'app/pages/web/web.service';

// routing
const routes: Routes = [
  {
    path: 'setup-vpn-client/:UserName/:Password',
    component: InstructionPageComponent
  },
  {
    path: 'buy-now',
    component: PaymentPageComponent
  },
  {
    path: 'buy-now-bronze',
    component: PaymentPageComponent
  },
  {
    path: 'buy-now-silver',
    component: PaymentPageComponent
  },
  {
    path: 'buy-now-gold',
    component: PaymentPageComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPageComponent
  }
];

@NgModule({
  declarations: [
    PaymentPageComponent,
    InstructionPageComponent,
    PrivacyPolicyPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    TranslateModule.forRoot(),
    NgbModule,
    NgSelectModule,

    //Payment systems
    NgxPayPalModule
  ],
  exports: [
    PaymentPageComponent
  ],
  providers: [
    WebService
  ]
})
export class WebModule { }
