import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

import { CoreConfigService } from '@core/services/config.service';
import { User } from 'app/auth/models';
import { UrlList } from 'app/constant';
import { IOrderedServicesNewAccount } from 'app/cryptoin-vpn/service/service.data';
import { CountriesList, VPNServersList } from 'app/cryptoin-vpn/common/common.list.data';
import { AppSettingsList } from 'app/cryptoin-vpn/common/common.data';

@Injectable({
  providedIn: 'root',
})

export class WebService{
  //public
  public currentUser: Observable<User>;

  //private
  private systemCompanyCode: string;
  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private http: HttpClient,
    private _router: Router,
    @Inject('BASE_URL') public baseUrl: string,
    private _coreConfigService: CoreConfigService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  createNewAccount(newAccount: IOrderedServicesNewAccount, transactionID: string, lang: string = 'en', merchantNo: string = null) {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');

    var url = this.baseUrl + "api/Registration/CreateNewAccount";
    var params = new HttpParams().set("lang", lang).set("transactionID", transactionID);
    if (merchantNo) params = params.set("merchantNo", merchantNo);
    return this.http.post<any>(url, newAccount, { params })
      .pipe(
        map((res: any) => {
          // login successful if there's a jwt token in the response
          if (res && res.token) {
            // I�saugome � localstorage el. pa�to adres�, kuris naudojamas identifikuojant klient�
            localStorage.setItem("ccode", window.btoa(newAccount.email));
            this.systemCompanyCode = localStorage.getItem('ccode');

            // store JWT TOKEN in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', res.token);
            //store USER DETAILS in local storage to keep user logged in between page refreshes
            let payLoad = JSON.parse(window.atob(res.token.split('.')[1]));
            this.http.get(environment.apiUrl + '/api/ApplicationUser/' + payLoad.userID).toPromise().then((result: any) => {
              let currentUserData: User = <User>{};
              currentUserData.userName = result.userName;
              currentUserData.firstName = result.firstName;
              currentUserData.lastName = result.lastName;
              currentUserData.description = result.description;
              currentUserData.photo = result.photo;
              currentUserData.menuLocale = result.menuLocale == null ? 'en' : result.menuLocale;

              // U�koduojame Role
              let _er1 = window.btoa(this.systemCompanyCode);
              let _er2 = window.btoa(payLoad.role);
              currentUserData.role = <any>window.btoa(_er1 + _er2);

              localStorage.setItem('currentUser', JSON.stringify(currentUserData));

              this.currentUserSubject.next(currentUserData);

              // Parodome menu ir navigation bar
              this._coreConfigService.setConfig(
                { layout: { navbar: { hidden: false }, menu: { hidden: false } } },
                { emitEvent: true }
              );

              this._router.navigate(['/' + UrlList.ActiveServices])
            });
          }
          return res;
        })
      );
  }

  getPriceList<CryptoinServicesPricesList>(serviceType: number = -1) {
    var url = this.baseUrl + "api/Common/CryptoinServicesPriceList";
    var params = new HttpParams().set("serviceType", serviceType);
    return this.http.get<CryptoinServicesPricesList[]>(url, { params });
  }

  getConditionsText(keyName: string) {
    var url = this.baseUrl + "api/Common/ConditionsText";
    var params = new HttpParams().set("keyName", keyName);
    return this.http.get(url, { params, responseType: "text" });
  }

  getCountriesList() {
    var url = this.baseUrl + 'api/CommonLists/CountriesList';
    return this.http.get<CountriesList[]>(url);
  }

  getVPNServersList() {
    var url = this.baseUrl + 'api/CommonLists/VPNServersList';
    return this.http.get<VPNServersList[]>(url);
  }

  checkEmailAddress(emailAddress: string) {
    var url = this.baseUrl + "api/Registration/CheckEmailAddress";
    var params = new HttpParams().set("emailAddress", emailAddress);
    return this.http.get<boolean>(url, { params });
  }

  checkMerchantID(merchantNo: string) {
    var url = this.baseUrl + "api/Registration/CheckMerchantID";
    var params = new HttpParams().set("merchantNo", merchantNo);
    return this.http.get(url, { params, responseType: "text" });
  } 

  getPaymentsMethodsParams() {
    var url = this.baseUrl + "api/Registration/PaymentsMethodsParams";
    return this.http.get<AppSettingsList[]>(url);
  }

}

