<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- account setting page -->
    <section id="page-account-settings">
      <div class="row nav-vertical d-flex">
        <div class="col-md-3 mb-2 mb-md-0">
          <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
            <li class="nav-item" ngbNavItem>
              <a ngbNavLink
                 class="nav-link d-flex py-75"
                 id="account-general"
                 data-toggle="pill"
                 href="#account-general"
                 aria-expanded="true">
                <i data-feather="user" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">{{ 'ACCOUNT.PROFILE.GENERAL.TITLE' | translate }}</span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- form -->
                    <form class="validate-form mt-0">
                      <div class="d-flex flex-column flex-md-row align-items-center pl-0 mb-1 ">
                        <div class="col-md-6 col-12 pl-0">
                          <div class="d-flex flex-column flex-md-row">

                            <div class="d-flex font-small-3 align-items-center mr-md-1">
                              <div class="badge badge-pill badge-light-{{ editedUserProfile.statusColorCode }}" ngbTooltip="{{ 'ACCOUNT.PROFILE.GENERAL.STATUS_INFO' | translate }}" container="body">
                                <span class="m-50 font-small-3">{{ editedUserProfile.statusName }}</span>
                              </div>
                            </div>

                            <div class="d-flex align-items-center pt-50">
                              <div class="custom-control custom-control-danger custom-switch">
                                <label class="pr-50">
                                  <span class="{{ editedUserProfile.accountType==0 ? 'font-medium-2 font-weight-bolder': ''}}">{{ 'Personal' }}</span>
                                </label>
                                <input type="checkbox"
                                       class="custom-control-input"
                                       [(ngModel)]="editedUserProfile.accountType" [ngModelOptions]="{standalone: true}"
                                       id="PersonalBusiness" />
                                <label class="custom-control-label" for="PersonalBusiness">
                                  <span class="{{ editedUserProfile.accountType==1 ? 'font-medium-2 font-weight-bolder': ''}}">{{ 'Business' }}</span>
                                </label>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div class="col-md-6 col-12 pl-0 pr-0 pt-md-0 pt-50 font-small-3 text-muted text-md-right">
                          {{ 'ACCOUNT.PROFILE.GENERAL.CLIENT_NO' | translate }}:
                          {{ editedUserProfile.clientNo }}
                        </div>
                      </div>

                      <hr class="invoice-spacing mt-0" />

                      <div class="row">
                        <div class="d-md-flex col-12 pl-0">
                          <div class="col-12 col-md-{{ editedUserProfile.accountType==1 ? '4': '6'}} pr-md-1 pr-0">
                            <div class="form-group">
                              <label for="account-firstName">{{ 'ACCOUNT.PROFILE.GENERAL.FIRST_NAME' | translate }}<span class="text-danger pl-25">*</span></label>
                              <input type="text"
                                     [(ngModel)]="editedUserProfile.firstName"
                                     class="form-control"
                                     id="account-firstName"
                                     name="firstName" />
                            </div>
                          </div>
                          <div class="col-12 col-md-{{ editedUserProfile.accountType==1 ? '4 pr-md-1 pr-0': '6 pr-0'}}">
                            <div class="form-group">
                              <label for="account-lastName">{{ 'ACCOUNT.PROFILE.GENERAL.LAST_NAME' | translate }}</label>
                              <input type="text"
                                     [(ngModel)]="editedUserProfile.lastName"
                                     class="form-control"
                                     id="account-lastName"
                                     name="lastName" />
                            </div>
                          </div>
                          <div class="col-12 col-md-4 pr-0" *ngIf="editedUserProfile.accountType==1">
                            <div class="form-group">
                              <label for="account-position">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.POSITION' | translate }}</label>
                              <input type="text"
                                     [(ngModel)]="editedUserProfile.position"
                                     class="form-control"
                                     name="position"
                                     id="account-position"
                                     placeholder="{{ 'ACCOUNT.PROFILE.COMPANY_INFO.POSITION_PLACEHOLDER' | translate }}" />
                            </div>
                          </div>
                        </div>

                        <div class="d-md-flex col-12 pl-0">
                          <div class="col-12 col-md-6 pr-md-1 pr-0">
                            <div class="form-group">
                              <label for="account-e-mail">{{ 'ACCOUNT.PROFILE.GENERAL.EMAIL' | translate }}<span class="text-danger pl-25">*</span></label>
                              <input type="email"
                                     [(ngModel)]="editedUserProfile.email"
                                     class="form-control"
                                     [ngClass]="{ 'is-invalid': editedUserProfile.email=='' || !editedUserProfile.email?.includes('@') }"
                                     id="account-e-mail"
                                     name="email" />
                              <div *ngIf="editedUserProfile.email=='' || !editedUserProfile.email?.includes('@')" class="invalid-feedback">
                                <div *ngIf="editedUserProfile.email==''">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_ERROR1' | translate }}</div>
                                <div *ngIf="editedUserProfile.email!='' && !editedUserProfile.email?.includes('@')">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_ERROR2' | translate }}</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 pr-0">
                            <div class="form-group">
                              <label for="account-mobilePhone">{{ 'ACCOUNT.PROFILE.GENERAL.MOBILE_PHONE' | translate }}<span class="text-danger pl-25">*</span></label>
                              <input type="text"
                                     [(ngModel)]="editedUserProfile.mobilePhone"
                                     class="form-control"
                                     id="account-mobilePhone"
                                     name="mobilePhone" />
                            </div>
                          </div>
                        </div>

                        <div class="col-12 mt-75" *ngIf="emailFound">
                          <div class="alert alert-danger mb-50" role="alert">
                            <h4 class="alert-heading">{{ 'COMMON.NOTIFICATION.FOUND_EMAIL.TITLE' | translate }}{{ 'COMMON.NOTIFICATION.FOUND_EMAIL.TEXT' | translate }}</h4>
                          </div>
                        </div>
                        <div class="col-12 mt-75" *ngIf="confirmEmail || userProfile.confirmEmail!=''">
                          <div class="alert alert-warning mb-50" role="alert">
                            <h4 class="alert-heading">{{ 'COMMON.NOTIFICATION.CONFIRM_EMAIL.TITLE' | translate }}{{ 'COMMON.NOTIFICATION.CONFIRM_EMAIL.TEXT_1' | translate }}{{ userProfile.confirmEmail }}{{ 'COMMON.NOTIFICATION.CONFIRM_EMAIL.TEXT_2' | translate }}</h4>
                          </div>
                        </div>

                        <div class="col-12 mt-75" *ngIf="editedUserProfile.accountType==1">
                          <div class="row">
                            <div class="col-12">
                              <div class="divider divider-left-center">
                                <div class="divider-text font-weight-bold font-small-3 text-danger">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.TITLE' | translate }}</div>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="form-group">
                                <label for="account-company-name">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.NAME' | translate }}<span class="text-danger pl-25">*</span></label>
                                <input type="text"
                                       [(ngModel)]="editedUserProfile.companyName"
                                       class="form-control"
                                       name="name"
                                       id="account-company-name" />
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="form-group">
                                <label for="account-company-code">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.CODE' | translate }}<span class="text-danger pl-25">*</span></label>
                                <input type="text"
                                       [(ngModel)]="editedUserProfile.companyCode"
                                       class="form-control"
                                       name="code"
                                       id="account-company-code" />
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="form-group">
                                <label for="account-company-vat-code">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.VAT_CODE' | translate }}</label>
                                <input type="text"
                                       [(ngModel)]="editedUserProfile.vatCode"
                                       class="form-control"
                                       name="vatCode"
                                       id="account-company-vat-code"
                                       (focusout)="checkVATValidation()" />
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="form-group">
                                <label for="account-company-address">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.ADDRESS' | translate }}<span class="text-danger pl-25">*</span></label>
                                <input type="text"
                                       [(ngModel)]="editedUserProfile.address"
                                       class="form-control"
                                       name="address"
                                       id="account-company-address" />
                              </div>
                            </div>
                            <div class="col-12 col-md-5">
                              <div class="form-group">
                                <label for="account-company-city">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.CITY' | translate }}<span class="text-danger pl-25">*</span></label>
                                <input type="text"
                                       [(ngModel)]="editedUserProfile.city"
                                       class="form-control"
                                       name="city"
                                       id="account-company-city" />
                              </div>
                            </div>
                            <div class="col-12 col-md-5">
                              <div class="form-group">
                                <label for="account-company-district">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.DISTRICT' | translate }}</label>
                                <input type="text"
                                       [(ngModel)]="editedUserProfile.district"
                                       class="form-control"
                                       name="district"
                                       id="account-company-district" />
                              </div>
                            </div>
                            <div class="col-12 col-md-2">
                              <div class="form-group">
                                <label for="account-company-zip">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.ZIP' | translate }}<span class="text-danger pl-25">*</span></label>
                                <input type="text"
                                       [(ngModel)]="editedUserProfile.zip"
                                       class="form-control"
                                       name="zip"
                                       id="account-company-zip" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="d-md-flex col-12 pl-0 pr-md-1 pr-0">
                          <div class="col-12 col-md-6 {{ editedUserProfile.accountType==0 ? 'mt-75' : '' }}">
                            <div class="form-group">
                              <label for="accountSelect">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.COUNTRY' | translate }}<span class="text-danger pl-25">*</span></label>
                              <ng-select [clearable]="false" 
                                         required [items]="countriesList" 
                                         [(ngModel)]="editedUserProfile.countryID" 
                                         bindLabel="fullName" 
                                         bindValue="keyID" 
                                         (change)="checkVATValidation()"                                        
                                         [ngModelOptions]="{standalone: true}">
                              </ng-select>
                            </div>
                          </div>

                          <div class="col-12 col-md-6 pr-md-0 pr-1" *ngIf="editedUserProfile.accountType==1">
                            <div class="form-group">
                              <label for="account-company-phone">{{ 'ACCOUNT.PROFILE.COMPANY_INFO.PHONE' | translate }}</label>
                              <input type="text"
                                     [(ngModel)]="editedUserProfile.phone"
                                     class="form-control"
                                     name="phone"
                                     id="account-company-phone"
                                     placeholder="{{ 'ACCOUNT.PROFILE.COMPANY_INFO.PHONE_PLACEHOLDER' | translate }}" />
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <hr class="invoice-spacing mt-1" />
                        </div>

                        <div class="col-12">
                          <button [disabled]="!vatIsValid"
                                  (click)="onUpdateUserProfile(1)"
                                  class="btn btn-primary mt-1 mr-1"
                                  rippleEffect>
                            {{ 'COMMON.ACTION.SAVE_CHANGES' | translate }}
                          </button>
                        </div>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem>
              <a ngbNavLink
                 class="nav-link d-flex py-75"
                 id="account-password"
                 data-toggle="pill"
                 href="#account-password"
                 aria-expanded="false">
                <i data-feather="lock" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">{{ 'ACCOUNT.PROFILE.PASSWORD.TITLE' | translate }}</span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- form -->
                    <form class="validate-form" [formGroup]="resetPasswordForm" (ngSubmit)="onResetPassword()">
                      <div class="row">
                        <div class="col-12 pl-50">
                          <span class="font-small-3">
                            <span class="m-50 font-small-3">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME' | translate }}</span>
                            <span class="badge badge-pill badge-light-{{ editedUserProfile.statusColorCode }}">
                              <span class="m-50 font-small-3">{{ loginName }}</span>
                            </span>
                          </span>
                        </div>

                        <div class="col-12">
                          <hr class="invoice-spacing mt-1" />
                        </div>

                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-new-password">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.NEW_PASSWORD' | translate }}</label>
                            <div class="input-group input-group-merge form-password-toggle">
                              <input [type]="passwordTextType ? 'text' : 'password'"
                                     formControlName="newPassword"
                                     class="form-control form-control-merge"
                                     placeholder="************"
                                     aria-describedby="reset-password-new"
                                     tabindex="1"
                                     [ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }" />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                  <i class="feather font-small-4"
                                     [ngClass]="{ 'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType }"
                                     (click)="togglePasswordTextType()"></i>
                                </span>
                              </div>
                            </div>
                            <div *ngIf="submitted && f.newPassword.errors"
                                 class="invalid-feedback"
                                 [ngClass]="{ 'd-block': submitted && f.newPassword.errors }">
                              <div *ngIf="f.newPassword.errors.required">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.NEW_PASSWORD_ERROR1' | translate }}</div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-retype-new-password">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.CONFIRM_PASSWORD' | translate }}</label>
                            <div class="input-group input-group-merge form-password-toggle">
                              <input [type]="confPasswordTextType ? 'text' : 'password'"
                                     formControlName="confirmPassword"
                                     class="form-control form-control-merge"
                                     placeholder="************"
                                     aria-describedby="reset-password-confirm"
                                     tabindex="2"
                                     [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }" />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                  <i class="feather font-small-4"
                                     [ngClass]="{ 'icon-eye-off': confPasswordTextType,'icon-eye': !confPasswordTextType }"
                                     (click)="toggleConfPasswordTextType()"></i>
                                </span>
                              </div>
                            </div>
                            <div *ngIf="f.confirmPassword.value!=f.newPassword.value && !f.confirmPassword.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.confirmPassword.value!=f.newPassword.value }">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.CONFIRM_PASSWORD_ERROR1' | translate }}</div>
                            <div *ngIf="submitted && f.confirmPassword.errors"
                                 class="invalid-feedback"
                                 [ngClass]="{ 'd-block': submitted && f.confirmPassword.errors }">
                              <div *ngIf="f.confirmPassword.errors.required">{{ 'ACCOUNT.DIALOG.RESET_PASSWORD.CONFIRM_PASSWORD_ERROR1' | translate }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-primary mr-1 mt-1" rippleEffect>{{ 'COMMON.ACTION.SAVE_CHANGES' | translate }}</button>
                        </div>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem>
              <a ngbNavLink
                 class="nav-link d-flex py-75"
                 id="account-pill-notifications"
                 data-toggle="pill"
                 href="#account-vertical-notifications"
                 aria-expanded="false">
                <i data-feather="bell" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">{{ 'ACCOUNT.PROFILE.NOTIFICATION.TITLE' | translate }}</span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 mt-1 mb-2">
                        <div class="custom-control custom-control-warning custom-switch">
                          <input type="checkbox"
                                 class="custom-control-input"
                                 [(ngModel)]="editedUserProfile.allowInvoiceReminder" [ngModelOptions]="{standalone: true}"
                                 disabled 
                                 id="accountSwitch1" />
                          <label class="custom-control-label" for="accountSwitch1">
                            {{ 'ACCOUNT.PROFILE.NOTIFICATION.INVOICE' | translate }}
                            <span class="switch-icon-left"><i data-feather="check"></i></span>
                          </label>
                        </div>
                      </div>
                      <div class="col-12 mb-2">
                        <div class="custom-control custom-switch">
                          <input type="checkbox"
                                 class="custom-control-input"
                                 [(ngModel)]="editedUserProfile.allowNewsLetterReminder" [ngModelOptions]="{standalone: true}"
                                 id="accountSwitch2" />
                          <label class="custom-control-label" for="accountSwitch2">
                            {{ 'ACCOUNT.PROFILE.NOTIFICATION.NEWS_LETTER' | translate }}
                            <span class="switch-icon-left"><i data-feather="check"></i></span>
                          </label>
                        </div>
                      </div>
                      <div class="col-12 mb-75">
                        <div class="custom-control custom-switch">
                          <input type="checkbox"
                                 class="custom-control-input"
                                 [(ngModel)]="editedUserProfile.allowNewServicesReminder" [ngModelOptions]="{standalone: true}"
                                 id="accountSwitch3" />
                          <label class="custom-control-label" for="accountSwitch3">
                            {{ 'ACCOUNT.PROFILE.NOTIFICATION.NEW_SERVICE' | translate }}
                            <span class="switch-icon-left"><i data-feather="check"></i></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-12">
                        <hr class="invoice-spacing mt-1" />
                      </div>

                      <div class="col-12">
                        <button (click)="onUpdateUserProfile(3)"
                                class="btn btn-primary mt-1 mr-1" rippleEffect>
                          {{ 'COMMON.ACTION.SAVE_CHANGES' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
      </div>
    </section>
    <!--/ account setting page -->
  </div>
</div>
