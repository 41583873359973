<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    &copy; {{ year }}
    <a class="ml-25" href="javascript:;">{{ 'CRYPTOIN.COMPANY.NAME' | translate }}</a>
    <span class="d-none d-sm-inline-block">, All rights reserved</span>
  </span>
  <span class="float-md-right d-none d-md-block">
    <span [data-feather]="'heart'" [class]="'pink'"></span>
  </span>
</p>
<h1 style="color: #F1F1F1; opacity: 0.0;">{{ 'WEB.LANDING_PAGE.SECTION.TITLE_1' | translate }}</h1>

<!--/ Footer -->
<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
