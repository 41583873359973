import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CoreConfigService } from '@core/services/config.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { AccountService } from 'app/cryptoin-vpn/account/account.service';

@Component({
  selector: 'email-confirmed',
  templateUrl: './email-confirmed.component.html',
  styleUrls: ['./email-confirmed.component.scss']
})
export class EmailConfirmedComponent implements OnInit {
  _environment = environment;
  public coreConfig: any;
  public confirmError: boolean = false;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _authenticationService: AuthenticationService,
    private _accountService: AccountService,
    private _toastrService: ToastrService,
    private _route: ActivatedRoute,
    private _coreConfigService: CoreConfigService,
    private _coreTranslationService: CoreTranslationService,
    public _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };

    this._coreTranslationService.translate(en);
  }

  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

    let _locale: any = JSON.parse(localStorage.getItem('currentUser'))
    if (_locale && _locale?.menuLocale != '') this._translateService.use(_locale.menuLocale);
    //=== Email address confirmation ===
    //--- Check is it valid confirmation code and if Yes, when run confirmation process
    let confirmAccessCode = this._route.snapshot.paramMap.get('confirmAccessCode');
    this._authenticationService.checkResetAccessCode(confirmAccessCode)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(found => {
        if (found) {
          this._accountService.confirmEmailAddress(confirmAccessCode)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(success => {
              if (!success) {
                this.confirmError = true;
                this._toastrService.error(
                  this._translateService.instant('COMMON.NOTIFICATION.CONFIRM_ERROR.TEXT'),
                  this._translateService.instant('COMMON.NOTIFICATION.CONFIRM_ERROR.TITLE'),
                  { toastClass: 'toast ngx-toastr', closeButton: false }
                );
              }
            })
        } else {
          this.confirmError = true;
          this._toastrService.error(
            this._translateService.instant('COMMON.NOTIFICATION.CONFIRM_CODE_NOT_FOUND.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.CONFIRM_CODE_NOT_FOUND.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        }
      })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
