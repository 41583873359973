import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from '../../_i18n/en';

import { UrlList } from 'app/constant';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { Login } from 'app/auth/models/login';
import { User } from 'app/auth/models/user';
import { Role } from 'app/auth/models/role';
import { CountriesList } from '../../common/common.list.data';
import { SettingService } from '../../common/setting.service';
import { UserProfile } from '../account.data';
import { AccountService } from '../account.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UserProfileComponent implements OnInit, OnDestroy {
  userProfile: UserProfile = <UserProfile>{};
  editedUserProfile: UserProfile = <UserProfile>{};
  countriesList: CountriesList[];
  listUrl = UrlList;
  vatIsValid: boolean = true;
  loginName: string = '';
  emailFound: boolean = false;
  confirmEmail: boolean = false;

  resetPasswordForm: FormGroup;
  public passwordTextType: boolean;
  public confPasswordTextType: boolean;
  public submitted = false;

  contentHeader: object;
  _unsubscribeAll: Subject<any>;
         
  constructor(
    private _toastrService: ToastrService,
    private _route: ActivatedRoute,
    private _settingService: SettingService,
    private _accountService: AccountService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en);
    this.loginName = this._accountService._currentUser.userName;
  }

  ngOnInit() {
    this._accountService.getClientID().subscribe(clientID => {
      this._accountService.getUserProfile<UserProfile>(clientID).subscribe(result => {
        this.userProfile = result;

        // Kopijuojame, nes naudosime saugojimo mentu kai bus spaud�iamas mygtumas "Save changes" atskiruose tabuose.
        this.editedUserProfile = <UserProfile>{};
        Object.assign(this.editedUserProfile, this.userProfile);
      });
    })

    this.resetPasswordForm = this._formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    this.formHeader();
    this._settingService.getCountriesList().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
      this.countriesList = result;
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  toggleConfPasswordTextType() {
    this.confPasswordTextType = !this.confPasswordTextType;
  }

  formHeader() {
    this.contentHeader = {
      headerTitle: this._translateService.instant('ACCOUNT.CONTENT.HEADER.TITLE'),
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('ACCOUNT.CONTENT.HEADER.NAME'),
            isLink: false,
            link: '/' + UrlList.MyProfile
          }
        ]
      }
    };
  }

  onUpdateUserProfile(tabNo: number) {
    if (tabNo == 1) {

      //=== Check field values ===
      //--- Personal account
      if (this.editedUserProfile.firstName == '' || this.editedUserProfile.firstName == null) {
        this.infoDialog(this._translateService.instant('ACCOUNT.PROFILE.GENERAL.FIRST_NAME'));
        return;
      }
      if (this.editedUserProfile.mobilePhone == '' || this.editedUserProfile.mobilePhone == null) {
        this.infoDialog(this._translateService.instant('ACCOUNT.PROFILE.GENERAL.MOBILE_PHONE'));
        return;
      }
      if (this.editedUserProfile.email == '' || this.editedUserProfile.email == null) {
        this.infoDialog(this._translateService.instant('ACCOUNT.PROFILE.GENERAL.EMAIL'));
        return;
      }
      //--- Business account
      if (this.editedUserProfile.accountType == 1 && (this.editedUserProfile.companyName == '' || this.editedUserProfile.companyName == null)) {
        this.infoDialog(this._translateService.instant('ACCOUNT.PROFILE.COMPANY_INFO.NAME'));
        return;
      }
      if (this.editedUserProfile.accountType == 1 && (this.editedUserProfile.companyCode == '' || this.editedUserProfile.companyCode == null)) {
        this.infoDialog(this._translateService.instant('ACCOUNT.PROFILE.COMPANY_INFO.CODE'));
        return;
      }
      if (this.editedUserProfile.accountType == 1 && (this.editedUserProfile.address == '' || this.editedUserProfile.address == null)) {
        this.infoDialog(this._translateService.instant('ACCOUNT.PROFILE.COMPANY_INFO.ADDRESS'));
        return;
      }
      if (this.editedUserProfile.accountType == 1 && (this.editedUserProfile.city == '' || this.editedUserProfile.city == null)) {
        this.infoDialog(this._translateService.instant('ACCOUNT.PROFILE.COMPANY_INFO.CITY'));
        return;
      }
      if (this.editedUserProfile.accountType == 1 && (this.editedUserProfile.zip == '' || this.editedUserProfile.zip == null)) {
        this.infoDialog(this._translateService.instant('ACCOUNT.PROFILE.COMPANY_INFO.ZIP'));
        return;
      }

      this.userProfile.firstName = this.editedUserProfile.firstName;
      this.userProfile.lastName = this.editedUserProfile.lastName;
      this.userProfile.mobilePhone = this.editedUserProfile.mobilePhone;
      this.userProfile.accountType = this.editedUserProfile.accountType ? 1 : 0;
      this.userProfile.countryID = this.editedUserProfile.countryID;

      if (this.userProfile.accountType == 1) {
        this.userProfile.position = this.editedUserProfile.position;
        this.userProfile.phone = this.editedUserProfile.phone;
        this.userProfile.companyName = this.editedUserProfile.companyName;
        this.userProfile.companyCode = this.editedUserProfile.companyCode;
        this.userProfile.vatCode = this.editedUserProfile.vatCode;
        this.userProfile.address = this.editedUserProfile.address;
        this.userProfile.city = this.editedUserProfile.city;
        this.userProfile.district = this.editedUserProfile.district;
        this.userProfile.zip = this.editedUserProfile.zip;
      }

      //--- Save data ---
      if (this.userProfile.email != this.editedUserProfile.email)
        this.checkEmail();
      else {
        this.updateUserProfile(false);
      }
    } if (tabNo == 3) {
      this.userProfile.allowInvoiceReminder = this.editedUserProfile.allowInvoiceReminder ? 1 : 0;
      this.userProfile.allowNewsLetterReminder = this.editedUserProfile.allowNewsLetterReminder ? 1 : 0;
      this.userProfile.allowNewServicesReminder = this.editedUserProfile.allowNewServicesReminder ? 1 : 0;

      //--- Save data ---
      this.updateUserProfile(false);
    }
  }

  updateUserProfile(sendConfirm: boolean) {
    this._accountService.getClientID().subscribe(clientID => {
      this._accountService.updateUserProfile<UserProfile>(clientID, this.userProfile).subscribe(result => {
        if (result) {
          if (sendConfirm) {
            //--- Sending confirmation link to new email address
            this._accountService.sendEmailConfirmationLink().subscribe(sent => {
              if (sent) {
                this.confirmEmail = true;               
              }
            });
          }
          this._toastrService.success(
            this._translateService.instant('COMMON.NOTIFICATION.SAVED.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.SAVED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        } else {
          this._toastrService.error(
            this._translateService.instant('COMMON.NOTIFICATION.NOT_SAVED.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.NOT_SAVED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        }
      });
    })
  }

  checkEmail() {
    this.emailFound = false;
    this.confirmEmail = false;
    this._accountService.getClientID().subscribe(clientID => {
      this._accountService.checkUserProfileEmail(this.editedUserProfile.email, clientID).subscribe(found => {
        if (!found) {
          this.userProfile.confirmEmail = this.editedUserProfile.email;
          this.updateUserProfile(true);
        } else
          this.emailFound = true;
      });
    })    
  }

  checkVATValidation() {
    let _country = this.countriesList.find(({ keyID }) => keyID === this.editedUserProfile.countryID);
    if (_country.isEU == 1 && (this.editedUserProfile.vatCode != '' && this.editedUserProfile.vatCode != null)) {
      let _substrVatNo = this.editedUserProfile.vatCode.substr(2).trim();
      if (_substrVatNo.length == 0) _substrVatNo = '777';
      this._accountService.checkVATValidation(_country.shortName, _substrVatNo)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
          if (result.valid) {
            this.vatIsValid = true;
            //--- Save user profile data ---
            //this.updateUserProfile(false);

            this._toastrService.success(
              result.name,
              this._translateService.instant('COMMON.DIALOG.VIES.VALID'),
              { toastClass: 'toast ngx-toastr', closeButton: false });
          } else {
            this.vatIsValid = false;

            this._toastrService.error(
              this._translateService.instant('COMMON.DIALOG.VIES.NOT_VALID'),
              this._translateService.instant('COMMON.DIALOG.VIES.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: false });
            /*
            this._toastrService.error(
              this._translateService.instant('COMMON.NOTIFICATION.NOT_SAVED.TEXT'),
              this._translateService.instant('COMMON.NOTIFICATION.NOT_SAVED.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: false }
            );
            */
          }
        });
    }
    else {
      this.vatIsValid = true;
      //--- Save user profile data ---
      //this.updateUserProfile(false);
    }
  }

  onResetPassword() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      this._toastrService.error(
        this._translateService.instant('COMMON.NOTIFICATION.NOT_SAVED.TEXT'),
        this._translateService.instant('COMMON.NOTIFICATION.NOT_SAVED.TITLE'),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );   
      return;
    }

    if (this.resetPasswordForm.get("newPassword").value == this.resetPasswordForm.get("confirmPassword").value) {
      let _login: Login = <Login>{};
      let payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      _login.userName = payLoad.userID; // Taip kaip visi account'ai yra vienoje Db, psswd kei�iame pagal user ID
      _login.password = this.resetPasswordForm.get("newPassword").value;
      this._authenticationService.changePassword(_login).subscribe((res: boolean) => {
        if (res) {
          this._toastrService.success("",
            this._translateService.instant('ACCOUNT.DIALOG.RESET_PASSWORD.INFO.PASSWORD_CHANGED'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        } else {
          this._toastrService.error("",
            this._translateService.instant('ACCOUNT.DIALOG.RESET_PASSWORD.INFO.PASSWORD_NOT_CHANGED'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        }
      });
    } else {
      this._toastrService.error("",
        this._translateService.instant('ACCOUNT.DIALOG.RESET_PASSWORD.CONFIRM_PASSWORD_ERROR1'),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );
    }
  }

  infoDialog(field: string) {
    this._toastrService.warning(field + this._translateService.instant('COMMON.NOTIFICATION.NOT_EMPTY.TEXT'),
      this._translateService.instant('COMMON.NOTIFICATION.NOT_EMPTY.TITLE'),
      { toastClass: 'toast ngx-toastr', closeButton: false }
    );
  }
}
