import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { CoreConfigService } from '@core/services/config.service';

import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { KeyName, UrlList } from 'app/constant';
import { WebService } from '../web.service';
import { CryptoinServicesPricesList } from 'app/cryptoin-vpn/common/common.data';
import { IOrderedServicesNewAccount } from 'app/cryptoin-vpn/service/service.data';
import { FAQList } from 'app/cryptoin-vpn/help/help.data';
import { HelpService } from 'app/cryptoin-vpn/help/help.service';
import { VPNServersList } from 'app/cryptoin-vpn/common/common.list.data';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent implements OnInit {
  coreConfig: any;
  year: number = new Date().getFullYear();

  _environment = environment;
    languageOptions: any;
  selectedLanguage: any;

  merchantID: string;
  merchantEmail: string;
  selectedClientID: number = -1;
  buttonsEnabled: boolean = true;
  infoText1: string;
  infoText2: string;

  selectedService: IOrderedServicesNewAccount;
  selectedCryptoinServicePrice: CryptoinServicesPricesList;
  showPaymentMethods: boolean = false;

  priceList: CryptoinServicesPricesList[];
  vpnServersList: VPNServersList[];
  faq: FAQList[];

  vpnServersIsCollapsed = true;
  termsOfUse: string;
  privacyPolicy: string;
  bonusProgramConditions: string;
  conditionHeader: string;
  conditionBody: string

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _route: ActivatedRoute,
    private _webService: WebService,
    private _helpService: HelpService,
    private _modalService: NgbModal,
    private _coreConfigService: CoreConfigService,
    private _coreTranslationService: CoreTranslationService,
    private _router: Router,
    private _toastrService: ToastrService,
    public _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();

    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'gb'
      }
    };

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };

    // Pasiimame naudojimosi sąlygų tekstą
    this._webService.getConditionsText(KeyName.CryptoinVPNTermsOfUse)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_conditions => {
        this.termsOfUse = _conditions;
    });

    // Privatumo palitikos tekstą
    this._webService.getConditionsText(KeyName.CryptoinVPNPrivacyPolicy)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_conditions => {
        this.privacyPolicy = _conditions;
      });

    // Merchant programos tekstą
    this._webService.getConditionsText(KeyName.CryptoinVPNMerchantProgram)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_conditions => {
        this.bonusProgramConditions = _conditions;
      });

    //--- Get FAQ: common questions ---
    this._helpService.getFAQ<FAQList>('landing-page')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.faq = result;
      });

    this._coreTranslationService.translate(en);
  }

  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

    // Paslėpiame menu ir navigation bar
    this._coreConfigService.setConfig(
      { layout: { navbar: { hidden: true }, menu: { hidden: true } } },
      { emitEvent: true }
    );

    let _pageLanguage = localStorage.getItem('pageLanguage');
    if (_pageLanguage) this._translateService.use(_pageLanguage);

    // Patikriname ar egzistuoja toks Merchant ID
    this.merchantID = this._route.snapshot.paramMap.get('mid');
    if (this.merchantID) {
      this._webService.checkMerchantID(this.merchantID)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
          if (result)
            this.merchantEmail = result;
          else {
            this._toastrService.warning(
              this._translateService.instant('CRYPTOIN.MERCHANT.NOTIFICATION.NOT_FOUND.TEXT'),
              this._translateService.instant('CRYPTOIN.MERCHANT.NOTIFICATION.NOT_FOUND.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: false }
            );
            this.merchantID = null;
            this._router.navigate(['/']);
          }
        });
    }

    this.getLists(_pageLanguage);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  scrollToPriceList() {
    document.querySelector('#priceList').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  getLists(_pageLanguage: string) {
    //Gauname planų kainas
    if (!_pageLanguage) _pageLanguage = 'en';
    this._webService.getPriceList<CryptoinServicesPricesList>()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.priceList = result;
      });

    //Gauname VPN serverių sąrašą
    this._webService.getVPNServersList()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.vpnServersList = result;
      });
  }

  setLanguage(language): void {
    this._translateService.use(language);
    localStorage.setItem('pageLanguage', language);

    // Gauname planų pavadinimus pasirinkta kalba
    this.getLists(language);
  }

  login() {
    this._router.navigate(['/login']);
  }

  onConditionsOpen(conditionsDialog, conditionType) {
    if (conditionType == KeyName.CryptoinVPNTermsOfUse) {
      this.conditionHeader = this._translateService.instant('WEB.TERM_OF_USE.HEADER');
      this.conditionBody = this.termsOfUse;
    }
    else if (conditionType == KeyName.CryptoinVPNPrivacyPolicy) {
      this.conditionHeader = this._translateService.instant('WEB.PRIVACY_POLICY.HEADER');
      this.conditionBody = this.privacyPolicy;
    }
    else {
      this.conditionHeader = this._translateService.instant('WEB.MERCHANT_PROGRAM.HEADER');
      this.conditionBody = this.bonusProgramConditions;
    }

    this._modalService.open(conditionsDialog, {
      centered: true,
      size: 'xl',
      windowClass: 'modal modal-primary'
    });
  }

  onBuyPlan(_cryptoinServiceID: number) {
    this.selectedService = <IOrderedServicesNewAccount>{};
    this.selectedService.serviceID = _cryptoinServiceID;
    this.selectedCryptoinServicePrice = this.priceList.find(({ serviceID }) => serviceID === _cryptoinServiceID);

    this.buttonsEnabled = false;
    this.showPaymentMethods = true;

    // Log info to FaceBook Pixel
    this.facebookPixel('InitiateCheckout', this.selectedCryptoinServicePrice.quantity * this.selectedCryptoinServicePrice.discountPrice);
  }

  onPaymentMethodSelect() {
    this.buttonsEnabled = true;
    this.showPaymentMethods = false;
  }

  onPaymentMethodCancel() {
    this.buttonsEnabled = true;
    this.showPaymentMethods = false;
  }

  facebookPixel(eventName: string, totalAmount: number) {
    (function (f: any, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }; if (!f._fbq) f._fbq = n;
      n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    (window as any).fbq('track', eventName, { value: totalAmount.toFixed(2), currency: 'EUR' });
  }
}
