import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';

//CryptoinVPN modules
import { HelpModule } from './help/help.module';
import { ServiceModule } from './service/service.module';
import { AccountModule } from './account/account.module';
import { CommonService } from './common/common.service';

@NgModule({
    declarations: [],
  imports: [
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    CoreSidebarModule,

    //CryptoinVPN modules
    HelpModule,
    ServiceModule,
    AccountModule,
  ],
  exports: [
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    DecimalPipe,
    CommonService
  ]  
})
export class CryptoinVPNModule {}
