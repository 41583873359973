<li ngbDropdown class="nav-item dropdown-cart mr-25">
  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarCartDropdown"
    ><span [data-feather]="'shopping-cart'" [class]="'ficon'"></span
    ><span class="badge badge-pill badge-primary badge-up cart-item-count">{{ cartListLength }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarCartDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Cart header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">{{ 'VB.CART.TITLE' | translate }}</h4>
        <div class="badge badge-pill badge-light-primary">{{ cartListLength }} {{ cartListLength==1 ? ('VB.CART.SERVICE_ONE' | translate) : ('VB.CART.SERVICE_MANY' | translate) }}</div>
      </div>
    </li>
    <!--/ Cart header -->

    <!-- Cart content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <ng-container *ngIf="cartList.length">
        <ng-container *ngFor="let item of cartList">
          <div class="media align-items-center">
            <img class="d-block rounded mr-1" [src]="item.serviceType==1 ? 'assets/images/vb/bullet1.png' : 'assets/images/vb/bullet3.png'" alt="donuts" width="40" />
            <div class="media-body">
              <!--
              <button *ngIf="item.serviceType!=3" class="btn p-0" (click)="removeFromCart(item.serviceID)">
                <i class="ficon cart-item-remove" data-feather="x"></i>
              </button>
              -->
              <div class="media-heading">
                <h6 class="cart-item-title">
                  {{ item.serviceName }}
                </h6>
                <small class="cart-item-by" [ngbTooltip]="tipContent">{{ item.description }}</small>
                <ng-template #tipContent>
                  <span>                    
                    <span class="font-italic">{{ item.serviceName }}</span><br /><br />
                    {{ item.description }}<br />
                  </span>
                </ng-template>
              </div>
              <div class="cart-item-qty">
                <div class="input-group touchspin-cart">
                  <core-touchspin [numberValue]="item.quantity" [minValue]="1" [maxValue]="1" [disable]="true"></core-touchspin>
                </div>
              </div>
              <h5 class="cart-item-price">{{ item.price }}{{ 'COMMON.CURRENCY_SYMBOL' | translate }}</h5>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </li>
    <!--/ Cart content -->

    <!-- Cart footer -->
    <li class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">{{ 'VB.CART.TOTAL' | translate }}</h6>
        <h6 class="text-primary font-weight-bolder mb-0">{{ total.toFixed(2)}}</h6>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bold mb-0">{{ 'VB.CART.VAT' | translate }}</h6>
        <h6 class="text-primary font-weight-bold mb-0">{{ vatSum.toFixed(2)}}</h6>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder font-medium-1 mb-0">{{ 'VB.CART.TOTAL_AMOUNT' | translate }}</h6>
        <h6 class="text-primary font-weight-bolder font-medium-1 mb-0">{{ totalAmount.toFixed(2)}}</h6>
      </div>
      <button class="btn btn-primary btn-block"
              [disabled]="totalAmount==0 || processPaymentStatus"
              (click)="makePayment()"
              rippleEffect>
        <span class="spinner-border spinner-border-sm mr-50" *ngIf="processPaymentStatus"></span>
        {{ 'VB.CART.ACTION.PAYMENT' | translate }}
      </button>
    </li>
    <!--/ Cart footer -->
  </ul>
</li>
