import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models/user';
import { Login } from 'app/auth/models/login';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { UrlList } from 'app/constant';

import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

@Component({
  selector: 'auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLoginComponent implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;
  public currentUser: User;
  _environment = environment;

  public menuLocale = [
    { name: 'en', value: 'en' },
  ];
  public selectedMenuLocale: string = "en";

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _toastrService: ToastrService,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService
  ) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };

    // Set the translations for the menu
    this._coreTranslationService.translate(en);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  setDialogLanguage() {
    // Use the selected language id for translations
    this._translateService.use(this.selectedMenuLocale);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this.loading = true;

    // redirect to home page
    let _login: Login = <Login>{};
    _login.userName = this.loginForm.get("email").value;
    _login.password = this.loginForm.get("password").value;
    
    // Išsaugome į localstorage el. pašto adresą, kuris naudojamas identifikuojant klientą
    localStorage.setItem("ccode", window.btoa(this.loginForm.get("email").value));

    this._authenticationService.login(_login).subscribe(
      (res: any) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant('ACCOUNT.DIALOG.LOGIN.SUCCESS.TEXT'),
            this._translateService.instant('ACCOUNT.DIALOG.LOGIN.SUCCESS.TITLE_START') + this._translateService.instant('ACCOUNT.DIALOG.LOGIN.SUCCESS.TITLE_END'),
            { toastClass: 'toast ngx-toastr', closeButton: true }
          );

          // Parodome menu ir navigation bar
          this._coreConfigService.setConfig(
            { layout: { navbar: { hidden: false }, menu: { hidden: false } } },
            { emitEvent: true }
          );

          this.loading = false;
          /*
          // Patikriname ar loginas nėra daromos iš paslaugos apmokėjimo lango
          if (localStorage.getItem("__cart_storage__") != null) {
            this._router.navigate(['/' + UrlList.OrderNow]);
          }
          else {
            this._router.navigate(['/' + UrlList.StartupPage]);
          }
          */
        }, 100);
      },
      err => {
        if (err.status == 400) {
          this._toastrService.error(
            this._translateService.instant('ACCOUNT.DIALOG.LOGIN.FAILED.TEXT'),
            this._translateService.instant('ACCOUNT.DIALOG.LOGIN.FAILED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
          this.loading = false;
        }
        else if (err.status == 409) {
          this._toastrService.warning(
            err.error.message,
            this._translateService.instant('ACCOUNT.DIALOG.LOGIN.FAILED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
          this.loading = false;
        }
        else {
          this.loading = false;
          this._toastrService.error(
            this._translateService.instant('ACCOUNT.DIALOG.LOGIN.FAILED.TEXT'),
            this._translateService.instant('ACCOUNT.DIALOG.LOGIN.FAILED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        }
      }
    );
  }

  ngOnInit(): void {
    let _ccode = '';
    if (localStorage.getItem("ccode")) {
      _ccode = window.atob(localStorage.getItem("ccode"));
    }

    this.loginForm = this._formBuilder.group({
      email: [_ccode, [Validators.required, Validators.email]],
      password: ['', Validators.required],
      locale: ['en']
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

    let _pageLanguage = localStorage.getItem('pageLanguage');
    if (_pageLanguage) {
      this.selectedMenuLocale = _pageLanguage;
      this._translateService.use(_pageLanguage);
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
