import { CoreMenu } from '@core/types'
import { UrlList } from 'app/constant';
import { Role } from 'app/auth/models';

export const menu: CoreMenu[] = [
  {
    id: 'services',
    title: 'Services',
    translate: 'MENU.SERVICES.SECTION',
    type: 'section',
    children: [
      {
        id: 'services-orders',
        title: 'Active Services',
        translate: 'MENU.SERVICES.SERVICES',
        type: 'item',
        icon: 'server',
        url: UrlList.ActiveServices
      },
      {
        id: 'services-order-new',
        title: 'Order Now',
        translate: 'MENU.SERVICES.ORDER_NEW',
        type: 'item',
        icon: 'shopping-cart',
        url: UrlList.OrderNow
      }
    ]
  },
  {
    id: 'help',
    title: 'Help',
    translate: 'MENU.HELP.SECTION',
    type: 'section',
    children: [
      {
        id: 'help-vpn-clients',
        title: 'VPN Clients',
        translate: 'MENU.HELP.VPN_CLIENTS',
        type: 'item',
        icon: 'download',
        url: UrlList.VPNClients
      },
      {
        id: 'help-faq',
        title: 'FAQ',
        translate: 'MENU.HELP.FAQ',
        type: 'item',
        icon: 'help-circle',
        url: UrlList.FAQ
      },
    ]
  },
  {
    id: 'account-settings',
    title: 'Account',
    translate: 'MENU.ACCOUNT.SECTION',
    type: 'section',
    children: [
      {
        id: 'account-settings-my-profile',
        title: 'My Profile',
        translate: 'MENU.ACCOUNT.MY_PROFILE',
        type: 'item',
        icon: 'user',
        url: UrlList.MyProfile
      }
    ]
  },
  {
    id: 'logout',
    title: 'Logout',
    translate: 'MENU.LOGOUT',
    type: 'item',
    icon: 'log-out',
    url: UrlList.Logout,    
  }
]