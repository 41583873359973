import { AuthGuard } from '../../auth/helpers/auth.guards';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreSidebarModule } from '@core/components';
import { Role } from 'app/auth/models';

import { UrlList } from 'app/constant';
import { ServiceListComponent } from './service-list/service-list.component';
import { ServiceBuyComponent } from './service-buy/service-buy.component';
import { ServiceService } from './service.service';
import { PaymentModule } from '../payment/payment.module';

const routes: Routes = [
  {
    path: UrlList.ActiveServices,
    component: ServiceListComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SaleListComponent' }
  },
  {
    path: UrlList.OrderNow,
    component: ServiceBuyComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SaleBuyComponent' },
  }
];

@NgModule({
  declarations: [
    ServiceListComponent,
    ServiceBuyComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    CoreCommonModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    Ng2FlatpickrModule,
    ContentHeaderModule,
    CoreSidebarModule,
    PaymentModule
  ],
  exports: [
  ],
  providers: [
    ServiceService
  ]
})
export class ServiceModule {}
