import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class HelpService {
  public _currentUser: User;
  public _bodytext: string;

  //-- Paimame sistemin�s �mon�s kod� i� localstorage, nes jis naudojamas indentifikuojant �mon� connection DB
  systemCompanyCode: string = localStorage.getItem('ccode');

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.currentUser.subscribe(x => (this._currentUser = x));
  }

  getFAQTitles<FAQTitlesList>(): Observable<FAQTitlesList[]> {
    var url = this.baseUrl + 'api/Common/FAQTitles';
    return this.http.get<FAQTitlesList[]>(url);
  }

  getFAQ<FAQList>(searchValue): Observable<FAQList[]> {
    var url = this.baseUrl + 'api/Common/FAQ';
    if (searchValue) var params = new HttpParams().set("searchValue", searchValue);
    return this.http.get<FAQList[]>(url, { params });
  }
}
