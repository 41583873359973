import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from '../../_i18n/en';

import { CryptoinServicesPricesList, ICartStorage } from '../../common/common.data';
import { CommonService } from '../../common/common.service';
import { FAQList } from '../../help/help.data';
import { HelpService } from '../../help/help.service';
import { IOrderedServices } from '../service.data';
import { AccountService } from '../../account/account.service';
import { ServiceService } from '../service.service';

@Component({
  selector: 'service-buy',
  templateUrl: './service-buy.component.html',
  styleUrls: ['./service-buy.component.scss']
})
export class ServiceBuyComponent implements OnInit, OnDestroy {
  public priceList: CryptoinServicesPricesList[];
  selectedCryptoinServicePrice: CryptoinServicesPricesList;
  _environment = environment;

  selectedClientID: number = -1;
  buttonsEnabled: boolean = true;
  infoText1: string;
  infoText2: string;

  selectedService: IOrderedServices;
  showPaymentMethods: boolean = false;

  faq: FAQList[];

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _commonService: CommonService,
    private _serviceService: ServiceService,
    private _accountService: AccountService,
    private _helpService: HelpService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(en);
    this._unsubscribeAll = new Subject();

  }

  ngOnInit(): void {
    //--- Get client ID ---
    this._accountService.getClientID().subscribe(clientID => {
      this.selectedClientID = clientID;

      //--- Get price list ---
      this._commonService.getCryptoinServicesPriceList<CryptoinServicesPricesList>(-1)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
          this.priceList = result;

          // Patikriname ar � �� page'� n�ra nukreipta i� web puslapio paslaugos apmok�jimo lango
          if (localStorage.getItem("__cart_storage__") != null) {
            let cartStorage: ICartStorage = <ICartStorage>{};
            cartStorage = JSON.parse(localStorage.getItem('__cart_storage__'));
            let _orderedService = window.atob(cartStorage.serviceID);
            this.onBuyPlan(+_orderedService);
            localStorage.removeItem("__cart_storage__")
          }
        });
    })

    //--- Get FAQ: common questions ---
    this._helpService.getFAQ<FAQList>('most common')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.faq = result;
      });

    //--- Assign info text ---
    this.infoText1 = this._translateService.instant('SERVICES.BUY.INFO.TEXT_1')
    this.infoText2 = this._translateService.instant('SERVICES.BUY.INFO.TEXT_2')
  }

  ngOnDestroy(): void {
    //--- Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onBuyPlan(_cryptoinServiceID: number) {
    this.selectedService = <IOrderedServices>{};
    this.selectedService.orderedServiceID = -1;
    this.selectedService.serviceID = _cryptoinServiceID;
    this.selectedService.clientID = this.selectedClientID;
    this.selectedCryptoinServicePrice = this.priceList.find(({ serviceID }) => serviceID === _cryptoinServiceID);

    this.buttonsEnabled = false;
    this.showPaymentMethods = true;
  }

  onPaymentMethodSelect() {
    this.buttonsEnabled = true;
    this.showPaymentMethods = false;
  }

  onPaymentMethodCancel() {
    this.buttonsEnabled = true;
    this.showPaymentMethods = false;
  }
}
