<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="pricing-plan">
      <div class="col-xl-12 col-md-12 col-12 pl-0">
        <a class="btn btn-primary mt-md-5 mt-2 mb-2 btn-sm-block" (click)="onPaymentCancel()" rippleEffect>
          <i data-feather="arrow-left-circle" class="mr-50"></i>
          {{ 'COMMON.ACTION.BACK' | translate }}
        </a>
      </div>

      <div class="navbar-horizontal col-xl-12 col-md-12 col-12 pl-0">
        <div class="row invoice-edit">
          <!-- Receipt -->
          <div class="d-flex flex-column col-xxl-7 col-12 px-xxl-1 pl-1 pr-0">
            <div class="text-center mb-1">
              <span class="font-medium-5 font-weight-bold">{{ 'PAYMENT.ORDER_SUMMARY' | translate }}</span>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between invoice-spacing mt-0 mb-0">
                  <div class="col-4 d-flex flex-column justify-content-center align-items-center mr-1">
                    <div class="mt-1 mb-50">
                      <span class="brand-logo">
                        <img height="60" [src]="cryptoinLogo" alt="brand-logo" />
                      </span>
                    </div>
                    <div class="logo-wrapper">
                      <span class="text-primary font-medium-2 font-weight-bolder text-center">{{ appSettings.cryptoinVPNCompanyName }}</span>
                    </div>
                  </div>

                  <!-- Address and Contact starts -->
                  <div class="col-8 align-items-center text-right justify-content-center border-left pr-2">
                    <h6 class="mb-50 font-italic text-muted">{{ 'PAYMENT.BUYER.TITLE' | translate }}</h6>
                    <h6 class="card-text mb-25" *ngIf="userProfile.accountType==0">{{ userProfile.firstName + ' ' + userProfile.lastName}}</h6>
                    <h6 class="card-text mb-25" *ngIf="userProfile.accountType==1">{{ userProfile.companyName }}</h6>
                    <p class="card-text mb-25" *ngIf="userProfile.accountType==1">{{ 'SALE.INVOICE.PRINT.COMPANY_CODE' | translate }}{{ userProfile.companyCode }}</p>
                    <p class="card-text mb-25" *ngIf="userProfile.accountType==1">{{ 'SALE.INVOICE.PRINT.VAT_CODE' | translate }}{{ userProfile.vatCode }}</p>
                    <p class="card-text mb-25" *ngIf="userProfile.accountType==1">{{ userProfile.address }}, {{ userProfile.zip + ' ' + (userProfile.city!= '' ? userProfile.city + ',' : '')  }} {{ userProfile.country }}</p>
                    <p class="card-text mb-25" *ngIf="userProfile.email!=''">{{ userProfile.email }}</p>
                    <p class="card-text mb-0" *ngIf="userProfile.accountType==0">{{ userProfile.country }}</p>
                  </div>
                  <!-- Address and Contact ends -->
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body pb-0">
                <div class="d-flex flex-column">
                  <div class="align-items-center mr-1">
                    <p class="font-medium-2 text-{{ cryptoinServicePrice.colorCode }} font-weight-bold mb-25">{{ cryptoinServicePrice.serviceName }}</p>
                    <p class="card-text text-nowrap">
                      <span class="font-medium-1 font-weight-bolder text-primary">
                        <span>
                          <i data-feather="calendar" class="mr-25" size="17"></i>
                        </span>
                        <span class="font-small-4 font-weight-normal text-primary">{{ 'PAYMENT.DATE_FROM' | translate }}</span>
                        {{ dateFrom | date:'yyyy-MM-dd' }}
                        <span class="font-small-4 font-weight-normal text-primary">{{ 'PAYMENT.DATE_TO' | translate }}</span>
                        {{ dateTo | date:'yyyy-MM-dd' }}
                      </span>
                    </p>
                  </div>

                  <div class="d-flex justify-content-between">
                    <div class="col-7 justify-content-center align-items-center"></div>
                    <div class="col-5 align-items-center text-right justify-content-center">
                      <h6 class="mb-50 text-muted">{{ 'PAYMENT.AMOUNT' | translate }}</h6>
                    </div>
                  </div>

                  <hr class="invoice-spacing mt-0 mb-75" />

                  <div class="d-flex justify-content-between font-medium-1">
                    <div class="col-7 align-items-center mb-75 pl-0">
                      <span class="font-weight-bold">{{ cryptoinServicePrice.quantity }}</span>
                      <span> {{ cryptoinServicePrice.measure }} </span>
                      x
                      <span class="font-weight-bold">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ cryptoinServicePrice.price }}</span>
                    </div>
                    <div class="col-5 align-items-center text-right justify-content-center">
                      <span class="font-weight-bolder font-medium-2 text-primary">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ (cryptoinServicePrice.quantity * cryptoinServicePrice.price).toFixed(2) }}</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between font-medium-1" *ngIf="discountPercent>0">
                    <div class="col-7 align-items-center mb-75 pl-0">
                      <div class="font-weight-normal font-small-4 text-danger">{{ 'COMMON.FORM.DISCOUNT' | translate }} {{ discountPercent + '%' }}</div>
                    </div>
                    <div class="col-5 align-items-center text-right justify-content-center">
                      <div class="font-weight-bold font-medium-2 text-danger">-{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ (cryptoinServicePrice.quantity * discountAmount).toFixed(2) }}</div>
                    </div>
                  </div>

                  <hr class="invoice-spacing mt-0 mb-75" />

                  <div class="d-flex justify-content-between">
                    <div class="col-5 d-flex flex-column align-self-end pl-0 mb-2"></div>
                    <div class="col-7 align-items-center d-flex justify-content-end mb-1 pb-25">
                      <!-- Total -->
                      <div class="d-flex flex-column pr-0">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="font-weight-normal font-small-4">{{ 'COMMON.FORM.TOTAL' | translate }}</div>
                          <div class="font-weight-bold font-medium-2">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('T') }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mt-25">
                          <div class="font-weight-normal font-small-4">{{ 'COMMON.FORM.VAT' | translate }} {{ userProfile.vatPercent + '%' }}</div>
                          <div class="font-weight-bold font-small-4">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('V') }}</div>
                        </div>
                        <hr class="invoice-spacing mt-50 mb-25" />
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="font-weight-normal font-small-3 text-muted mb-0 mt-0">{{ 'COMMON.FORM.TAX_COUNTRY' | translate  }}</div>
                          <div class="font-weight-bold font-small-3 text-muted mb-0 mt-0">{{ userProfile.country }}</div>
                        </div>
                        <hr class="invoice-spacing mt-25 mb-50" />
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="font-weight-normal font-small-4">{{ 'COMMON.FORM.TOTAL_AMOUNT' | translate }}</div>
                          <div class="ml-1 font-weight-bold font-medium-5 text-{{ cryptoinServicePrice.colorCode }}">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}</div>
                        </div>
                      </div>
                      <!--/ Total -->
                    </div>
                  </div>
                </div>
              </div>
              <!--/ Receipt  -->
            </div>
          </div>

          <!-- Payment Methods -->
          <div class="col-xxl-5 col-12 invoice-actions pr-0">
            <div class="text-center mb-1">
              <span class="font-medium-5 font-weight-bold">{{ 'PAYMENT.PAYMENT_METHOD' | translate }}</span>
            </div>
            <div class="card collapse-icon">
              <ngb-accordion [closeOthers]="true" activeIds="ngb-panel-0">
                <!-- Paypal / Credit card payment -->
                <ngb-panel [disabled]="stripePaymentSelected" *ngIf="appSettings.paypalGatewayEnabled==1">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title">
                      <img src="{{ _environment.picsPath }}/payment/paypal.png" class="ml-1" />
                      <img src="{{ _environment.picsPath }}/payment/creditcard.png" class="ml-1 pt-50" />
                    </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="mb-1">
                      <div class="">
                        <div class="row">
                          <div class="col-12">
                            <div class="col-12 pl-2">
                              <div class="font-small-3 text-muted mb-1" [innerHTML]="paypalText"></div>
                              <div class="col-12 pt-25 pl-0">
                                <section *ngIf="buttonsEnabled">
                                  <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <!--/ Paypal / Credit card payment -->
                <!-- Stripe payment -->
                <ngb-panel *ngIf="appSettings.stripeGatewayEnabled==1">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title">
                      <img src="{{ _environment.picsPath }}/payment/stripe.png" class="ml-2" />
                    </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body collapse-icon pt-0">
                      <div class="row">
                        <div class="col-12">
                          <div class="col-12 pl-2">
                            <div class="font-small-3 text-muted" [innerHTML]="stripeText"></div>
                            <div class="col-12 pt-25 pl-0">
                              <section *ngIf="!stripePaymentSelected">
                                <button [disabled]="!buttonsEnabled"
                                        (click)="onPaymentMethodSelect('STRIPE')"
                                        class="btn btn-block btn-primary mt-2"
                                        rippleEffect>
                                  <i data-feather="credit-card" class="mr-50"></i>
                                  {{ 'PAYMENT.STRIPE.MAKE_PAYMENT' | translate }}
                                  {{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}
                                </button>
                              </section>

                              <section *ngIf="stripePaymentSelected">
                                <form id="payment-form" class="mt-1">
                                  <div id="payment-element"></div>
                                  <button type="submit"
                                          [disabled]="processPaymentStatus"
                                          class="btn btn-block btn-primary mt-2"
                                          rippleEffect>
                                    <span class="spinner-border spinner-border-sm mr-50" *ngIf="processPaymentStatus"></span>
                                    <span *ngIf="!processPaymentStatus"><i data-feather="credit-card" class="mr-50"></i></span>
                                    {{ 'PAYMENT.STRIPE.COMPLETE_PURCHASE' | translate }}
                                    {{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}
                                  </button>
                                  <button type="reset"
                                          [disabled]="processPaymentStatus"
                                          class="btn btn-block btn-outline-secondary mt-2"
                                          rippleEffect>
                                    <i data-feather="x-circle" class="mr-50"></i>
                                    {{ 'COMMON.ACTION.CANCEL_PURCHASE' | translate }}
                                  </button>
                                </form>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <!--/ Stripe payment -->
                <!-- Paysera payment -->
                <ngb-panel [disabled]="stripePaymentSelected" *ngIf="appSettings.payseraGatewayEnabled==1">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title">
                      <img src="{{ _environment.picsPath }}/payment/paysera.png" class="ml-2" />
                    </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body collapse-icon pt-0">
                      <div class="row">
                        <div class="col-12">
                          <div class="col-12 pl-2">
                            <div class="font-small-3 text-muted" [innerHTML]="payseraText"></div>
                            <div class="col-12 pt-25 pl-0">
                              <button [disabled]="!buttonsEnabled"
                                      (click)="onPaymentMethodSelect('PAYSERA')"
                                      class="btn btn-block btn-primary mt-2"
                                      rippleEffect>
                                <span class="spinner-border spinner-border-sm mr-50" *ngIf="processPaymentStatus"></span>
                                <span *ngIf="!processPaymentStatus"><i data-feather="credit-card" class="mr-50"></i></span>
                                {{ 'PAYMENT.PAYSERA.MAKE_PAYMENT' | translate }}
                                {{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <!--/ Paysera payment -->
                <!-- Crypto payment -->
                <ngb-panel [disabled]="stripePaymentSelected" *ngIf="appSettings.bitpayGatewayEnabled==1">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title">
                      <img src="{{ _environment.picsPath }}/payment/bitpay.png" class="ml-1" />
                      <img src="{{ _environment.picsPath }}/payment/crypto.png" class="ml-1" />
                    </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body collapse-icon pt-0">
                      <div class="row">
                        <div class="col-12">
                          <div class="col-12 pl-2">
                            <div class="font-small-3 text-muted" [innerHTML]="cryptoText"></div>
                            <div class="col-12 pt-25 pl-0">
                              <button [disabled]="!buttonsEnabled"
                                      (click)="onPaymentMethodSelect('CRYPTO')"
                                      class="btn btn-block btn-primary mt-2"
                                      rippleEffect>
                                <span class="spinner-border spinner-border-sm mr-50" *ngIf="processPaymentStatus"></span>
                                <span *ngIf="!processPaymentStatus"><i data-feather="credit-card" class="mr-50"></i></span>
                                {{ 'PAYMENT.CRYPTO.MAKE_PAYMENT' | translate }}
                                {{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <!--/ Crypto payment -->
              </ngb-accordion>
            </div>
          </div>
          <!--/Payment Method  -->
        </div>
      </div>
    </section>
  </div>
</div>
