export enum UrlList {
  //Dashboard = 'dashboard',
  ActiveServices = 'services',
  OrderNow = 'buy',
  VPNClients = 'vpn-clients',
  FAQ = 'faq',
  Invoices = 'invoices',
  MyProfile = 'my-profile',
  SetupVpnClient = 'setup-vpn-client',
  Logout = 'logout',
  StartupPage = ActiveServices,
  PayseraPaymentAccept = 'paysera-payment-accept',
  PaypalPaymentAccept = 'paypal-payment-accept',
  CryptoPaymentAccept = 'crypto-payment-accept',
  StripePaymentAccept = 'stripe-payment-accept'
};

interface PeriodType { period: string };
export const PeriodsList: PeriodType[] = [
  { period: "1h" },
  { period: "2h" },
  { period: "4h" },
  { period: "8h" },
  { period: "12h" },
  { period: "1d" },
  { period: "3d" },
  { period: "5d" },
  { period: "1w" },
  { period: "2w" },
  { period: "1m" },
  { period: "3m" },
  { period: "6m" },
  { period: "9m" },
  { period: "1y" }
];

//-- App Settings konstantų nustatymas (Pradžia)
export enum AppSettingName {
  VpnAccountNotPaidStatus = 'VpnAccountNotPaidStatus',
  CryptoinVPNCompanyName = 'CryptoinVPNCompanyName',
  CryptoinVPNCurrency = 'CryptoinVPNCurrency',
  CryptoinVPNCompanyCode = 'CryptoinVPNCompanyCode',
  CryptoinVPNCompanyVatCode = 'CryptoinVPNCompanyVatCode',
  CryptoinVPNCompanyFullAddress = 'CryptoinVPNCompanyFullAddress',
  CryptoinVPNCompanyPhone = 'CryptoinVPNCompanyPhone',
  CryptoinVPNCompanyEmail = 'CryptoinVPNCompanyEmail',
  PaypalGatewayEnabled = 'PaypalGatewayEnabled',
  StripeGatewayEnabled = 'StripeGatewayEnabled',
  BitpayGatewayEnabled = 'BitpayGatewayEnabled',
  PayseraGatewayEnabled = 'PayseraGatewayEnabled',
  PaypalClientID = 'PaypalClientID',
  StripePublishableKey = 'StripePublishableKey',
  CryptoinVPNCompanyVatPercent = 'CryptoinVPNCompanyVatPercent',
  CryptoinVPNCountry = 'CryptoinVPNCountry',
  MerchantID = 'MerchantID',
  MerchantLogo = 'MerchantLogo',
  MerchantLogoWidth = 'MerchantLogoWidth'
};

interface AppSettingType { name: string; type: string; };
export const AppSettingsTypeList: AppSettingType[] = [
  { name: 'VpnAccountNotPaidStatus', type: 'I' },
  { name: 'CryptoinVPNCompanyName', type: 'S' },
  { name: 'CryptoinVPNCurrency', type: 'S' },
  { name: 'PaypalGatewayEnabled', type: 'I' },
  { name: 'StripeGatewayEnabled', type: 'I' },
  { name: 'BitpayGatewayEnabled', type: 'I' },
  { name: 'PayseraGatewayEnabled', type: 'I' },
  { name: 'PaypalClientID', type: 'S' },
  { name: 'StripePublishableKey', type: 'S' },
  { name: 'MerchantID', type: 'S' },
  { name: 'MerchantLogo', type: 'S' },
  { name: 'MerchantLogoWidth', type: 'I' },
];
//-- App Settings konstantų nustatymas (Pabaiga)

export enum SettingHeader {
};

export enum KeyName {
  CryptoinVPNTermsOfUse = 'CryptoinVPNTermsOfUse',
  CryptoinVPNPrivacyPolicy = 'CryptoinVPNPrivacyPolicy',
  CryptoinVPNMerchantProgram = 'CryptoinVPNMerchantProgram'
};

export const ShowRows: string[] = ['10', '25', '50', '100', '250', '500', '1000'];
export const DefaultShowRows: number = 10;
export const VBMessageActionID: number = 777;

export enum CrmTableStatusValue {
  Open = 1,
  Close = 0,
  Read = 2
};

//-- Spalvų konstantų nustatymas
interface ColorType { className: string; colorCode: string; };
export const ColorsList: ColorType[] = [
  { className: "font-medium-1 text-primary", colorCode: "primary" },
  { className: "font-medium-1 text-success", colorCode: "success" },
  { className: "font-medium-1 text-danger", colorCode: "danger" },
  { className: "font-medium-1 text-warning", colorCode: "warning" },
  { className: "font-medium-1 text-info", colorCode: "info" },
  { className: "font-medium-1 text-secondary", colorCode: "secondary" },
  { className: "font-medium-1 text-dark", colorCode: "dark" }
];

export const CHART_COLORS = {
  red: 'rgb(255, 68, 68)',
  orange: 'rgb(255, 187, 51)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(0, 128, 0)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
  white: 'rgb(255, 255, 255)'
};

export const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
];

