import { AuthGuard } from '../../auth/helpers/auth.guards';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { UrlList } from 'app/constant';
import { VPNClientListComponent } from './vpn-client-list/vpn-client-list.component';
import { FaqComponent } from './faq/faq.component';
import { HelpService } from './help.service';

const routes: Routes = [
  {
    path: UrlList.VPNClients,
    component: VPNClientListComponent,
    canActivate: [AuthGuard],
    data: { animation: 'FaqComponent' }
  },
  {
    path: UrlList.FAQ,
    component: FaqComponent,
    canActivate: [AuthGuard],
    data: { animation: 'FaqComponent' }
  }
];

@NgModule({
  declarations: [
    VPNClientListComponent,
    FaqComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    CoreCommonModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    ContentHeaderModule
  ],
  exports: [],
  providers: [
    HelpService
  ]
})
export class HelpModule { }
