import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreCommonModule } from '@core/common.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthLoginComponent } from 'app/pages/authentication/auth-login/auth-login.component';
import { AuthForgotPasswordComponent } from 'app/pages/authentication/auth-forgot-password/auth-forgot-password.component';
import { AuthResetPasswordV2Component } from 'app/pages/authentication/auth-reset-password/auth-reset-password.component';

// routing
const routes: Routes = [
  {
    path: 'login',
    component: AuthLoginComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'forgot-password',
    component: AuthForgotPasswordComponent
  },
  {
    path: 'reset-password/:ResetAccessCode',
    component: AuthResetPasswordV2Component
  }
];

@NgModule({
  declarations: [
    AuthLoginComponent,
    AuthForgotPasswordComponent,
    AuthResetPasswordV2Component
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule,
    TranslateModule.forRoot()
  ]
})
export class AuthenticationModule {}
