<div class="navbar navbar-shadow fixed-top navbar-light navbar-shadow">
  <div class="col-xxl-2 col-sm-1"></div>

  <div class="col-xxl-8 col-sm-10 col-12 px-0">
    <div class="d-flex flex-md-row flex-column">
      <div class="d-flex col-md-6 col-12 align-content-center align-items-center justify-content-md-start justify-content-center pl-0">
        <div class="">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="40" />
        </div>
        <div>
          <span class="font-large-1 font-weight-bolder text-primary ml-1">{{ coreConfig.app.appName }}</span>
        </div>
      </div>

      <div class="d-flex col-md-6 col-12 align-content-center justify-content-md-end justify-content-between pr-0 pl-0 mt-md-0 mt-50">
        <div class="pr-1 pt-md-0 pt-50" *ngIf="!showPaymentMethods">
          <a (click)="scrollToPriceList()" class="btn btn-success font-medium-4 font-weight-bolder text-success">
            <i size="23" data-feather="shopping-cart" class="pr-25"></i>
            {{ 'COMMON.ACTION.ORDER_NOW' | translate }}
          </a>
        </div>

        <div class="pt-md-0 pt-50" *ngIf="!showPaymentMethods">
          <a (click)="login()" class="btn btn-outline-primary font-medium-4 font-weight-bolder text-primary">
            <i size="23" data-feather="log-in" class="pr-25"></i>
            {{ 'WEB.LANDING_PAGE.ACTION.BUTTON_LOGIN' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xxl-2 col-sm-1"></div>
</div>

<div class="d-flex flex-column pt-2 align-items-center">
  <div class="col-xxl-2 col-sm-1"></div>

  <div class="col-xxl-8 col-sm-10 col-12 px-0" *ngIf="!showPaymentMethods">
    <div class="text-center p-md-0 pt-2 my-md-3 mt-4 mb-1">
      <h2 class="font-large-2 font-weight-bolder text-primary py-2">{{ 'WEB.LANDING_PAGE.SECTION.TITLE' | translate }}</h2>
    </div>

    <div class="col-12 p-0">
      <div class="d-flex flex-xxl-row flex-column text-justify col-12 p-0">
        <div class="card border border-light rounded col-xxl-4 col-12 px-md-3 py-md-2 p-2">
          <div class="d-flex flex-column">
            <h2 class="text-center font-large-1 font-weight-bolder mb-1">
              <div class="font-large-2 font-weight-bolder text-center mb-50">
                <i size="33" data-feather="globe" class="text-primary"></i>
              </div>
              {{ 'WEB.LANDING_PAGE.SECTION.VULNERABLE.TITLE' | translate }}
            </h2>
            <div>
              <div class="font-medium-3 mb-2 text-secondary font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.VULNERABLE.P1' | translate }}</div>
              <div class="font-medium-3 mb-2 text-secondary font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.VULNERABLE.P2' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="card border border-light rounded col-xxl-4 col-12 mx-xxl-1 px-md-3 py-md-2 p-2">
          <div class="d-flex flex-column">
            <h2 class="text-center font-large-1 font-weight-bolder mb-1">
              <div class="font-large-2 font-weight-bolder text-center mb-50">
                <i size="33" data-feather="key" class="text-primary"></i>
              </div>
              {{ 'WEB.LANDING_PAGE.SECTION.GET_SECURE.TITLE' | translate }}
            </h2>
            <div>
              <h1 class="font-medium-3 mb-2 text-secondary font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.GET_SECURE.P1' | translate }}</h1>
              <h1 class="font-medium-3 mb-2 text-secondary font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.GET_SECURE.P2' | translate }}</h1>
              <h1 class="font-medium-3 mb-2 text-secondary font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.GET_SECURE.P3' | translate }}</h1>
            </div>
          </div>
        </div>
        <div class="card border border-light rounded col-xxl-4 col-12 px-md-3 py-md-2 p-2">
          <div class="d-flex flex-column">
            <h2 class="text-center font-large-1 font-weight-bolder mb-1">
              <div class="font-large-2 font-weight-bolder text-center mb-50">
                <i size="33" data-feather="lock" class="text-primary"></i>
              </div>
              {{ 'WEB.LANDING_PAGE.SECTION.PROTECT_DEVICE.TITLE' | translate }}
            </h2>
            <div>
              <h1 class="font-medium-3 mb-2 text-secondary font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.PROTECT_DEVICE.P1' | translate }}</h1>
              <h1 class="font-medium-3 mb-2 text-secondary font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.PROTECT_DEVICE.P2' | translate }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-2 my-2">
      <div class="text-justify p-0">
        <h2 class="text-center mb-2 font-large-1 font-weight-bolder">{{ 'WEB.LANDING_PAGE.SECTION.WHY_VPN.TITLE' | translate }}</h2>
        <div class="font-medium-3 mb-2 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.WHY_VPN.P1' | translate }}</div>
      </div>

      <div class="row align-items-center text-justify">
        <div class="col-md-8 col-12 pr-md-3">
          <div class="">
            <h3 class="mb-2 font-medium-4 font-weight-bold">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="33" data-feather="wifi" class="pr-50 text-success"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.WHY_VPN.R_1.TITLE' | translate }}
            </h3>
            <div class="font-medium-3 mb-2 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.WHY_VPN.R_1.TEXT' | translate }}</div>
          </div>
          <div class="pt-1">
            <h3 class="mb-2 font-medium-4 font-weight-bold">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="33" data-feather="cloud" class="pr-50 text-success"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.WHY_VPN.R_2.TITLE' | translate }}
            </h3>
            <div class="font-medium-3 mb-2 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.WHY_VPN.R_2.TEXT' | translate }}</div>
          </div>
          <div class="pt-1">
            <h3 class="mb-2 font-medium-4 font-weight-bold">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="33" data-feather="globe" class="pr-50 text-success"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.WHY_VPN.R_3.TITLE' | translate }}
            </h3>
            <div class="font-medium-3 mb-2 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.WHY_VPN.R_3.TEXT' | translate }}</div>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <img alt="{{ 'WEB.LANDING_PAGE.SECTION.WHY_VPN.P1' | translate }}" class="img-fluid" src="assets/images/pages/under-maintenance.svg" width="450" height="450" />
        </div>
      </div>
    </div>

    <div class="p-2 my-2">
      <div class="text-justify">
        <h2 class="text-center mb-2 font-large-1 font-weight-bolder">{{ 'WEB.LANDING_PAGE.SECTION.WHAT_VPN_DO.TITLE' | translate }}</h2>
        <div class="font-medium-3 mb-2 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.WHAT_VPN_DO.P1' | translate }}</div>
      </div>

      <div class="row align-items-center text-justify">
        <div class="col-md-4 col-12 pb-md-0 pb-2">
          <img alt="{{ 'WEB.LANDING_PAGE.SECTION.WHAT_VPN_DO.P1' | translate }}" class="img-fluid" src="assets/images/pages/login-v2.svg" width="500" height="500" />
        </div>
        <div class="col-md-8 col-12 pl-md-4">
          <div class="">
            <h3 class="mb-2 font-medium-4 font-weight-bold">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="33" data-feather="map-pin" class="pr-50 text-primary"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.WHAT_VPN_DO.R_1.TITLE' | translate }}
            </h3>
            <div class="font-medium-3 mb-2 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.WHAT_VPN_DO.R_1.TEXT' | translate }}</div>
          </div>
          <div class="pt-1">
            <h3 class="mb-2 font-medium-4 font-weight-bold">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="33" data-feather="hash" class="pr-50 text-primary"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.WHAT_VPN_DO.R_2.TITLE' | translate }}
            </h3>
            <div class="font-medium-3 mb-2 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.WHAT_VPN_DO.R_2.TEXT' | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-md-2 p-0">
      <div class="card border rounded col-12 px-md-3 px-0">
        <div class="d-flex flex-column">
          <div class="mt-2 mb-3 px-md-0 px-2">
            <h2 class="text-center font-large-1 font-weight-bolder mb-2">{{ 'WEB.LANDING_PAGE.SECTION.HOW_VPN_WORK.TITLE' | translate }}</h2>
            <div class="font-medium-3 mb-1 text-secondary font-weight-normal">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="cloud" class="text-primary"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.HOW_VPN_WORK.P1' | translate }}
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="corner-right-down" class="mt-1 text-warning"></i>
              </span>
            </div>
            <div class="font-medium-3 mb-1 text-secondary font-weight-normal">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="corner-down-right" class="text-warning"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.HOW_VPN_WORK.P2' | translate }}
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="corner-right-down" class="mt-1 text-warning"></i>
              </span>
            </div>
            <div class="font-medium-3 mb-1 text-secondary font-weight-normal">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="corner-down-right" class="text-warning"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.HOW_VPN_WORK.P3' | translate }}
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="corner-right-down" class="mt-1 text-warning"></i>
              </span>
            </div>
            <div class="font-medium-3 mb-1 text-secondary font-weight-normal">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="corner-down-right" class="text-warning"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.HOW_VPN_WORK.P4' | translate }}
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="corner-right-down" class="mt-1 text-warning"></i>
              </span>
            </div>
            <div class="font-medium-3 text-secondary font-weight-normal">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="corner-down-right" class="text-warning"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.HOW_VPN_WORK.P5' | translate }}
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="flag" class="text-success"></i>
              </span>
            </div>
          </div>

          <div class="col-12 d-flex flex-md-row flex-column mb-md-1 pl-md-0">
            <div class="border border-secondary border-lighten-3 rounded col-md-6 col-12 p-2 mr-md-1 mb-md-0 mb-1">
              <h2 class="font-large-1 font-weight-bolder mb-1">{{ 'WEB.LANDING_PAGE.SECTION.HOW_TUNNEL_WORK.TITLE' | translate }}</h2>
              <div class="font-medium-3 mb-2 text-secondary font-weight-normal">
                <span class="font-medium-2 font-weight-bolder text-center">
                  <i size="28" data-feather="chevrons-right" class="text-warning"></i>
                </span>
                {{ 'WEB.LANDING_PAGE.SECTION.HOW_TUNNEL_WORK.P1' | translate }}
              </div>
              <div class="font-medium-3 mb-2 text-secondary font-weight-normal">
                <span class="font-medium-2 font-weight-bolder text-center">
                  <i size="28" data-feather="chevrons-right" class="text-warning"></i>
                </span>
                {{ 'WEB.LANDING_PAGE.SECTION.HOW_TUNNEL_WORK.P2' | translate }}
              </div>
              <div class="font-medium-3 text-secondary font-weight-normal">
                <span class="font-medium-2 font-weight-bolder text-center">
                  <i size="28" data-feather="chevrons-right" class="text-warning"></i>
                </span>
                {{ 'WEB.LANDING_PAGE.SECTION.HOW_TUNNEL_WORK.P3' | translate }}
              </div>
            </div>
            <div class="border border-secondary border-lighten-3 rounded col-md-6 col-12 p-2">
              <h2 class="font-large-1 font-weight-bolder mb-1">{{ 'WEB.LANDING_PAGE.SECTION.HOW_SERVER_WORK.TITLE' | translate }}</h2>
              <div class="font-medium-3 mb-2 text-secondary font-weight-normal">
                <span class="font-medium-2 font-weight-bolder text-center">
                  <i size="28" data-feather="chevrons-right" class="text-warning"></i>
                </span>
                {{ 'WEB.LANDING_PAGE.SECTION.HOW_SERVER_WORK.P1' | translate }}
              </div>
              <div class="font-medium-3 text-secondary font-weight-normal">
                <span class="font-medium-2 font-weight-bolder text-center">
                  <i size="28" data-feather="chevrons-right" class="text-warning"></i>
                </span>
                {{ 'WEB.LANDING_PAGE.SECTION.HOW_SERVER_WORK.P2' | translate }}
              </div>
            </div>
          </div>

          <div class="mb-3 p-md-0 p-1">
            <div class="border border-secondary border-lighten-3 rounded p-2">
              <h2 class="text-center font-large-1 font-weight-bolder mb-2">{{ 'WEB.LANDING_PAGE.SECTION.ENCRYPTION_PROTOCOL.TITLE' | translate }}</h2>
              <div class="font-medium-3 mb-1 text-secondary font-weight-normal">
                {{ 'WEB.LANDING_PAGE.SECTION.ENCRYPTION_PROTOCOL.P1' | translate }}
              </div>
              <div class="font-medium-3 mb-1 text-dark font-weight-bold">
                {{ 'WEB.LANDING_PAGE.SECTION.ENCRYPTION_PROTOCOL.P2' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-2 mt-3 mb-2">
      <div class="text-center p-0">
        <h2 class="mb-2 font-large-1 font-weight-bolder">{{ 'WEB.LANDING_PAGE.SECTION.HOW_CHOOSE_VPN.TITLE' | translate }}</h2>
      </div>

      <div class="row align-items-center text-justify">
        <div class="col-md-8 col-12 pr-md-3">
          <div class="">
            <h2 class="mb-2 font-medium-4 font-weight-bold">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="33" data-feather="loader" class="pr-50 text-success"></i>
                <i size="33" data-feather="key" class="pr-50 text-success"></i>
                <i size="33" data-feather="lock" class="pr-50 text-success"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.HOW_CHOOSE_VPN.R_1.TITLE' | translate }}
            </h2>
            <div class="font-medium-3 mb-2 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.HOW_CHOOSE_VPN.R_1.TEXT' | translate }}</div>
          </div>
          <div class="pt-1">
            <h2 class="mb-2 font-medium-4 font-weight-bold">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="33" data-feather="meh" class="pr-50 text-success"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.HOW_CHOOSE_VPN.R_2.TITLE' | translate }}
            </h2>
            <div class="font-medium-3 mb-2 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.HOW_CHOOSE_VPN.R_2.TEXT1' | translate }}</div>
            <div class="font-medium-3 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.HOW_CHOOSE_VPN.R_2.TEXT2' | translate }}</div>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <img alt="{{ 'WEB.LANDING_PAGE.SECTION.HOW_CHOOSE_VPN.R_1.TITLE' | translate }}" class="img-fluid" src="assets/images/pages/error.svg" width="450" height="450" />
        </div>
      </div>
    </div>

    <div class="my-2">
      <div class="col-12 pl-0">
        <div class="d-flex flex-column flex-md-row justify-content-between align-text-top mt-3">
          <div class="card border rounded col-md-6 col-12 p-md-3 p-2 mr-md-1">
            <h2 class="text-center font-large-1 font-weight-bolder mb-2">{{ 'WEB.LANDING_PAGE.SECTION.MOBILE_DEVICE.TITLE' | translate }}</h2>
            <div class="font-medium-3 mb-1 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.MOBILE_DEVICE.P1' | translate }}</div>
            <div class="font-medium-3 mb-1 font-weight-normal">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="chevrons-right" class="text-dark"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.MOBILE_DEVICE.R_1.TEXT' | translate }}
            </div>
            <div class="font-medium-3 mb-1 font-weight-normal">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="chevrons-right" class="text-dark"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.MOBILE_DEVICE.R_2.TEXT' | translate }}
            </div>
            <div class="font-medium-3 font-weight-normal">
              <span class="font-medium-2 font-weight-bolder text-center">
                <i size="28" data-feather="chevrons-right" class="text-dark"></i>
              </span>
              {{ 'WEB.LANDING_PAGE.SECTION.MOBILE_DEVICE.R_3.TEXT' | translate }}
            </div>
          </div>
          <div class="card border rounded col-md-6 col-12 p-md-3 p-2">
            <h2 class="text-center font-large-1 font-weight-bolder mb-2">{{ 'WEB.LANDING_PAGE.SECTION.SECURE_UP_TO.TITLE' | translate }}</h2>
            <div class="font-medium-3 mb-1 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.SECURE_UP_TO.P1' | translate }}</div>
            <div class="font-medium-3 mb-1 font-weight-bolder">{{ 'WEB.LANDING_PAGE.SECTION.SECURE_UP_TO.P2' | translate }}</div>
            <div class="font-medium-3 mb-1 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.SECURE_UP_TO.P3' | translate }}</div>
            <div class="font-medium-3 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.SECURE_UP_TO.P4' | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-2">
      <div class="row">
        <div class="d-flex font-medium-1 col-12 justify-content-center">
          <div class="col-12 accordion collapse-margin collapse-icon p-0">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
              <ngb-panel>
                <ng-template ngbPanelTitle>
                  <h2 class="font-large-1 font-weight-bolder py-1">
                    <i size="33" data-feather="server" class="text-success mr-50"></i>
                    {{ 'WEB.LANDING_PAGE.SECTION.SERVER_LIST.TITLE' | translate }}
                  </h2>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 5%">{{ 'WEB.LANDING_PAGE.SECTION.SERVER_LIST.ONLINE' | translate }}</th>
                          <th style="width: 10%">{{ 'WEB.LANDING_PAGE.SECTION.SERVER_LIST.COUNTRY_CODE' | translate }}</th>
                          <th style="width: 85%">{{ 'WEB.LANDING_PAGE.SECTION.SERVER_LIST.CITY' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody *ngFor="let server of vpnServersList; let i = index">
                        <tr>
                          <td class="text-center">
                            <span class="font-medium-2 font-weight-bolder text-center" *ngIf="server.status==1">
                              <i size="23" data-feather="check-circle" class="text-success"></i>
                            </span>
                            <span class="font-medium-2 font-weight-bolder text-center" *ngIf="server.status==0">
                              <i size="23" data-feather="x-circle" class="text-danger"></i>
                            </span>
                          </td>
                          <td>
                            <img [src]="server.countryFlag" />
                            <span class="ml-50 font-medium-3">
                              {{ server.countryCode }}
                            </span>
                          </td>
                          <td>
                            <span class="font-medium-3">
                              {{ server.city}}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </div>
    </div>

    <div class="border border-primary rounded text-center my-3">
      <div class="font-large-1 font-weight-bolder text-primary p-2">{{ 'WEB.LANDING_PAGE.SECTION.PRICE_LIST.TITLE' | translate }}</div>
    </div>

    <div id="priceList"></div>

    <div class="row pricing-card mt-3">
      <!-- <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto"> -->
      <div class="col-12 col-lg-12 col-xl-12 col-xxl-10 mx-auto">
        <div class="row">
          <div class="col-12 col-md-4" *ngFor="let price of priceList; let i = index">
            <div class="card standard-pricing {{ (price.markingComment!='' && price.markingComment!=null) ? 'popular': '' }} text-center">
              <div class="card-body">
                <div class="pricing-badge text-right {{ (price.markingComment!='' && price.markingComment!=null) ? '': 'mb-2' }}">
                  <div class="badge badge-pill badge-light-primary">{{ price.markingComment }}</div>
                </div>
                <h3>{{ price.serviceName }}</h3>
                <p class="card-text">{{ price.description }}</p>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <span class="font-medium-3 font-weight-bold text-muted" *ngIf="price.discount==0">{{ '&nbsp;' }}</span>
                    <span class="font-medium-3 font-weight-bold text-muted" *ngIf="price.discount!=0"><strike>{{ price.price | currency:'EUR' }}</strike></span>
                  </div>
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">{{ 'COMMON.CURRENCY_SYMBOL' | translate }} </sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ price.discountPrice | number: '1.2-2' }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold"> /{{ price.measure }}</sub>
                  </div>
                  <div class="plan-price mt-50" *ngIf="price.quantity!=0">
                    <sub class="pricing-duration text-success font-medium-1 font-weight-bold">{{ 'SERVICES.BUY.PERIOD_1' | translate }}{{ price.quantity + ' ' + price.measure }}{{ 'SERVICES.BUY.PERIOD_2' | translate }}</sub>
                  </div>
                  <div class="plan-price mt-25 mb-1" *ngIf="price.quantity!=0">
                    <sub class="pricing-duration font-small-3 text-warning">{{ 'SERVICES.BUY.TOTAL_PAYMENT_1' | translate }}{{ price.quantity + ' ' + price.measure }}{{ 'SERVICES.BUY.TOTAL_PAYMENT_2' | translate }}<span class="font-weight-bold font-small-4">{{ (price.quantity * price.discountPrice).toFixed(2) | currency:'EUR' }}</span></sub>
                  </div>
                  <div class="plan-price mt-xxl-25 mb-1">
                    <sub class="pricing-duration font-small-3 font-weight-bold text-muted">{{ 'COMMON.FORM.VAT_INFO' | translate }}</sub>
                  </div>
                </div>
                <div class="text-left pt-1" [innerHTML]="price.comments"></div>
                <button id="buyNowButton"
                        (click)="onBuyPlan(price.serviceID)"
                        class="btn btn-block {{ (price.markingComment!='' && price.markingComment!=null) ? 'btn-primary': 'btn-outline-primary' }} mt-2"
                        rippleEffect>
                  {{ 'COMMON.ACTION.ORDER_NOW' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="misc-inner pt-0 pb-0">
      <!-- money back guarantee -->
      <div class="pricing-free-trial d-flex justify-content-center col-12 ml-0">
        <div class="d-flex col-12 col-lg-10">
          <div class="pricing-trial-content d-flex pb-md-0 pb-2">
            <div class="text-center text-md-left mt-2">
              <h3 class="text-primary">{{ 'SERVICES.BUY.GUARANTEE.TITLE' | translate }}</h3>
              <h4 class="mt-4">{{ 'SERVICES.BUY.GUARANTEE.TEXT' | translate }}</h4>
            </div>

            <img src="{{ _environment.picsPath}}badge.svg"
                 class="pricing-trial-img img-fluid"
                 alt="svg img" />
          </div>
        </div>
      </div>
      <!--/ money back guarantee -->
    </div>

    <div class="misc-inner pt-0 pb-2">
      <div class="row">
        <div class="d-flex flex-column col-12 pricing-faq">
          <h2 class="text-center font-large-1 font-weight-bolder">{{ 'SERVICES.BUY.FAQ.TITLE' | translate }}</h2>
          <span class="text-center font-medium-3 font-weight-normal">{{ 'WEB.LANDING_PAGE.SECTION.FAQ.TITLE' | translate }}</span>

          <div class="row my-2">
            <div class="d-flex font-medium-1 col-12 justify-content-center">
              <div class="col-md-10 col-12 accordion collapse-margin collapse-icon">
                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                  <ngb-panel id="deliveryId{{ i }}"
                             [cardClass]="'collapse-margin'"
                             *ngFor="let qandA of faq; let i = index">
                    <ng-template ngbPanelTitle>
                      <span>{{ qandA.question }}</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <span [innerHTML]="qandA.answer"></span>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xxl-8 col-sm-10 col-12 px-0" *ngIf="showPaymentMethods">
    <payment-page (onSelect)="onPaymentMethodSelect()"
                  (onCancel)="onPaymentMethodCancel()"
                  [merchantID]="merchantID"
                  [merchantEmail]="merchantEmail"
                  [cryptoinServicePrice]="selectedCryptoinServicePrice"
                  [orderedService]="selectedService">
    </payment-page>
  </div>

  <div class="col-xxl-2 col-sm-1"></div>
</div>


<div class="navbar navbar-shadow floating-nav navbar-light navbar-shadow pt-2 pb-2">
  <div class="col-xxl-2 col-sm-1"></div>

  <div class="d-flex col-xxl-8 col-sm-10 col-12 px-2">
    <div class="d-flex flex-column col-6 align-content-center justify-content-between">
      <div>
        <span class="float-left d-block d-md-inline-block mt-md-25">
          &copy; {{ year }}
          <a class="ml-md-25 text-dark font-weight-bold" href="{{ 'CRYPTOIN.COMPANY.WWW' | translate }}" target="_blank">{{ 'CRYPTOIN.COMPANY.NAME' | translate }}</a>
        </span>
      </div>
      <div>
        <span>
          <a class="text-dark" href="mailto:{{ 'CRYPTOIN.COMPANY.EMAIL' | translate }}" target="_blank">{{ 'CRYPTOIN.COMPANY.EMAIL' | translate }}</a>
        </span>
      </div>
    </div>
    <div class="d-flex col-6 align-content-center align-items-center justify-content-center justify-content-md-end">
      <div class="d-flex flex-md-row flex-column align-items-center">
        <div>
          <a class="ml-md-25 text-primary" (click)="onConditionsOpen(conditionsDialog, 'CryptoinVPNTermsOfUse')">{{ 'WEB.TERM_OF_USE.HEADER' | translate }}</a>
        </div>
        <div class="ml-md-2">
          <a class="ml-md-25 text-primary" (click)="onConditionsOpen(conditionsDialog, 'CryptoinVPNPrivacyPolicy')">{{ 'WEB.PRIVACY_POLICY.HEADER' | translate }}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xxl-2 col-sm-1"></div>
</div>

<ng-template #conditionsDialog let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="conditionsDialog">{{ conditionHeader }}</h5>
  </div>
  <div class="modal-body text-justify" tabindex="0" ngbAutofocus [innerHTML]="conditionBody"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()" rippleEffect>
      {{ 'WEB.ACTION.ACCEPT' | translate }}
    </button>
  </div>
</ng-template>