import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreSidebarModule } from '@core/components';
import { Role } from 'app/auth/models';

import { AccountLogout } from './account.logout';
import { UrlList } from 'app/constant';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AccountService } from './account.service';

const routes: Routes = [
  {
    path: UrlList.MyProfile,
    component: UserProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: UrlList.Logout,
    component: AccountLogout,
  }
];

@NgModule({
  declarations: [
    UserProfileComponent,
    AccountLogout
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    CoreCommonModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    ContentHeaderModule,
    CoreSidebarModule
  ],
  providers: [
    AccountService
  ]
})
export class AccountModule {}
