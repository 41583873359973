<<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Forgot Password v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <a href="{{ 'CRYPTOIN.CRYPTOINVPN_URL' | translate }}" class="brand-logo align-items-center">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="47" />
          <h2 class="brand-text text-primary ml-1 mb-0">{{ coreConfig.app.appName }}</h2>
        </a>

        <h4 class="card-title mb-1">{{ 'ACCOUNT.DIALOG.FORGOT_PASSWORD.TITLE' | translate }}</h4>
        <p class="card-text mb-2">{{ 'ACCOUNT.DIALOG.FORGOT_PASSWORD.TEXT' | translate }}</p>

        <form class="auth-forgot-password-form mt-2" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label class="form-label" for="forgot-password-email">{{ 'ACCOUNT.DIALOG.FORGOT_PASSWORD.EMAIL' | translate }}</label>
            <input class="form-control"
                   type="text"
                   formControlName="email"
                   placeholder="{{ 'ACCOUNT.DIALOG.FORGOT_PASSWORD.EMAIL_PLACEHOLDER' | translate }}"
                   aria-describedby="forgot-password-email"
                   autofocus=""
                   tabindex="1"
                   [ngClass]="{ 'is-invalid': submitted && !linkSend && f.email.errors }" />
            <div *ngIf="submitted && !linkSend && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">{{ 'ACCOUNT.DIALOG.FORGOT_PASSWORD.EMAIL_ERROR1' | translate }}</div>
              <div *ngIf="f.email.errors.email">{{ 'ACCOUNT.DIALOG.FORGOT_PASSWORD.EMAIL_ERROR2' | translate }}</div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="2" rippleEffect>{{ 'ACCOUNT.DIALOG.FORGOT_PASSWORD.ACTION.RESET' | translate }}</button>
          <p class="text-center mt-2">
            <a routerLink="/login"><i data-feather="chevron-left" class="mb-25"></i> {{ 'ACCOUNT.DIALOG.FORGOT_PASSWORD.ACTION.LOGIN' | translate }}</a>
          </p>

          <hr class="invoice-spacing mt-2" />

          <div class="form-group row pl-0 mt-2 mb-0">
            <div class="pl-1 col-form-label">
              <label>{{ 'ACCOUNT.DIALOG.LOGIN.LOCALE' | translate }}</label>
            </div>
            <select [(ngModel)]="selectedMenuLocale" formControlName="locale" class="form-control ml-1 col-3" (change)="setDialogLanguage()">
              <option *ngFor="let loc of menuLocale" [ngValue]="loc.value">{{ loc.name }}</option>
            </select>
          </div>
        </form>
      </div>
    </div>
    <!-- /Forgot password-->
  </div>
</div>
