<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="pricing-plan">
      <div class="text-center">
        <h1 class="mt-5">{{ 'HELP.VPN_CLIENTS.TITLE' | translate }}</h1>
        <p class="mt-50 mb-2 pl-md-5 pr-md-5 ml-md-5 mr-md-5">{{ 'HELP.VPN_CLIENTS.SUB_TITLE' | translate }}</p>
      </div>

      <!-- VPN clients list -->
      <div class="row pricing-card">
        <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
          <div class="row mt-50">
            <!-- Windows -->
            <div class="col-12 col-md-4">
              <div class="card standard-pricing text-center">
                <div class="card-body">
                  <h3>{{ 'HELP.VPN_CLIENTS.WINDOWS.TITLE' | translate }}</h3>
                  <p class="card-text">{{ 'HELP.VPN_CLIENTS.WINDOWS.SUB_TITLE' | translate }}</p>
                  <img src="{{ _environment.picsPath + 'windows.png' }}" height="150" class="mb-2" />
                  <button (click)="onWindowsDownload()"
                          class="btn btn-block btn-outline-primary mt-2" rippleEffect>
                    {{ 'HELP.VPN_CLIENTS.WINDOWS.ACTION.DOWNLOAD' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <!--/ Windows -->
            <!-- Mac -->
            <div class="col-12 col-md-4">
              <div class="card standard-pricing text-center  pb-xl-0 pb-md-1">
                <div class="card-body">
                  <h3>{{ 'HELP.VPN_CLIENTS.MAC.TITLE' | translate }}</h3>
                  <p class="card-text">{{ 'HELP.VPN_CLIENTS.MAC.SUB_TITLE' | translate }}</p>
                  <img src="{{ _environment.picsPath + 'apple.png' }}" height="150" class="mb-2" />
                  <button (click)="onMacDownload()"
                          class="btn btn-block btn-outline-primary mt-2" rippleEffect>
                    {{ 'HELP.VPN_CLIENTS.MAC.ACTION.DOWNLOAD' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <!--/ Mac -->
            <!-- Linux -->
            <div class="col-12 col-md-4">
              <div class="card standard-pricing text-center">
                <div class="card-body">
                  <h3>{{ 'HELP.VPN_CLIENTS.LINUX.TITLE' | translate }}</h3>
                  <p class="card-text">{{ 'HELP.VPN_CLIENTS.LINUX.SUB_TITLE' | translate }}</p>
                  <img src="{{ _environment.picsPath + 'linux.png' }}" height="150" class="mb-2" />
                  <button (click)="onLinuxDownload()"
                          class="btn btn-block btn-outline-primary mt-2" rippleEffect>                 
                    {{ 'HELP.VPN_CLIENTS.LINUX.ACTION.DOWNLOAD' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <!--/ Linux -->
          </div>
          <div class="row mt-25">
            <!-- Android -->
            <div class="col-12 col-md-6">
              <div class="card standard-pricing text-center">
                <div class="card-body">
                  <h3>{{ 'HELP.VPN_CLIENTS.ANDROID.TITLE' | translate }}</h3>
                  <p class="card-text">{{ 'HELP.VPN_CLIENTS.ANDROID.SUB_TITLE' | translate }}</p>
                  <img src="{{ _environment.picsPath + 'android.png' }}" height="150" class="mb-2" />
                  <div class="d-flex flex-column flex-xl-row">
                    <div class="col-xl-6 col-12 mt-2">
                      <button (click)="onGooglePayOpen()"
                              class="btn btn-block btn-outline-primary" rippleEffect>
                        {{ 'HELP.VPN_CLIENTS.ANDROID.ACTION.DOWNLOAD_1' | translate }}
                      </button>
                    </div>
                    <div class="col-xl-6 col-12 mt-xl-2 mt-75">
                      <button (click)="onAndroidDownload()"
                              class="btn btn-block btn-outline-primary" rippleEffect>                      
                        {{ 'HELP.VPN_CLIENTS.ANDROID.ACTION.DOWNLOAD_2' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Android -->
            <!-- iOS -->
            <div class="col-12 col-md-6">
              <div class="card standard-pricing text-center pb-xl-0 pb-md-4">
                <div class="card-body">
                  <h3>{{ 'HELP.VPN_CLIENTS.IOS.TITLE' | translate }}</h3>
                  <p class="card-text">{{ 'HELP.VPN_CLIENTS.IOS.SUB_TITLE' | translate }}</p>
                  <img src="{{ _environment.picsPath + 'ios.png' }}" height="150" class="mb-2" />
                  <button (click)="oniTunesOpen()"
                          class="btn btn-block btn-outline-primary mt-2" rippleEffect>                    
                    {{ 'HELP.VPN_CLIENTS.IOS.ACTION.DOWNLOAD' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <!--/ iOS -->
          </div>
        </div>
      </div>
      <!--/ VPN clients list -->
    </section>
  </div>
</div>
