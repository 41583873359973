import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { decode } from 'js-base64';
import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { UrlList } from 'app/constant';
import { ICartStorage } from '../../common/common.data';
import { IOrderedServices, IOrderedServicesNewAccount } from 'app/cryptoin-vpn/service/service.data';
import { WebService } from 'app/pages/web/web.service';
import { PaymentService } from '../../payment/payment.service';

@Component({
  selector: 'stripe-payment-accept',
  template:
`
  <div class="content-wrapper container-xxl p-0">
    <div class="card p-2">
      <div class="font-large-1 font-weight-bolder pb-1 text-warning">{{ 'COMMON.NOTIFICATION.PAYMENT_PROCESS_STARTED.TITLE' | translate }}</div>
      <div class="font-medium-5 font-weight-bolder text-primary" *ngIf="loggedIn!='y'">{{ 'COMMON.NOTIFICATION.PAYMENT_PROCESS_STARTED.TEXT_NOTLOGGED' | translate }}</div>
      <div class="font-medium-5 font-weight-bolder text-primary" *ngIf="loggedIn=='y'">{{ 'COMMON.NOTIFICATION.PAYMENT_PROCESS_STARTED.TEXT_LOGGED' | translate }}</div>
    </div>
  </div>
`
})
export class StripePaymentAccept implements OnInit {
  loggedIn: string;

  constructor(
    private _webService: WebService,
    private _paymentService: PaymentService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string
  ) {
    this._coreTranslationService.translate(en);
  }

  ngOnInit() {
    var _systemCompanyCode = localStorage.getItem('ccode');
    this.loggedIn = this._route.snapshot.queryParams['_l'];
    var paymentIntent = this._route.snapshot.queryParams['payment_intent'];
    var piClientSecret = this._route.snapshot.queryParams['payment_intent_client_secret'];
    var redirectStatus = this._route.snapshot.queryParams['redirect_status'];
    var url = this.baseUrl + "api/Stripe/PaymentAccepted";
    var headers = new HttpHeaders().set("ccode", _systemCompanyCode)
    var params = new HttpParams()
      .set("paymentIntent", paymentIntent)
      .set("piClientSecret", piClientSecret)
      .set("redirectStatus", redirectStatus);
    if (this.loggedIn == 'y') {
      this.http.get<string>(url, { headers, params }).subscribe(tID => {
        this.paymentAccepted(tID, this.loggedIn);
      });
    }
    else {
      this.http.get<string>(url, { params }).subscribe(tID => {
        this.paymentAccepted(tID, this.loggedIn);
      });
    }
  }

  paymentAccepted(tID, loggedIn) {
    if (tID != null) {
      if (loggedIn == 'y') {
        this.stripePaymentReceived_LoggedIn(tID);
      }
      else {
        this.stripePaymentReceived_NOT_LoggedIn(tID);
      }
    }
    else {
      this._toastrService.error(
        this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TEXT'),
        this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TITLE'),
        { toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true }
      );
    }
  }

  stripePaymentReceived_NOT_LoggedIn(transactionID: string) {
    //--- Payment received: Create VPN service entry
    let cartStorage: ICartStorage = <ICartStorage>{};
    cartStorage = JSON.parse(localStorage.getItem('__cart_storage__'));
    let orderedService: IOrderedServicesNewAccount = <IOrderedServicesNewAccount>{};
    orderedService.serviceID = +window.atob(cartStorage.serviceID);
    orderedService.firstName = decode(window.atob(cartStorage.firstName));
    orderedService.lastName = decode(window.atob(cartStorage.lastName));
    orderedService.email = window.atob(cartStorage.email);
    orderedService.countryID = +window.atob(cartStorage.countryID);
    orderedService.merchantID = cartStorage.merchantID;
    let _totalAmount: number = cartStorage.totalAmount;
    localStorage.removeItem('__cart_storage__');

    this._webService.createNewAccount(orderedService, transactionID, orderedService.merchantID)
      .subscribe(result => {
        let _success = result.message.includes('successfully');
        let _userName = result.userName;
        let _password = result.password;
        if (_success) {
          setTimeout(() => {
            this._router.navigate(['/' + UrlList.SetupVpnClient + '/' + _userName + '/' + _password])

            this._toastrService.success(
              this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_SUCCESS.TEXT'),
              this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_SUCCESS.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 10000 }
            );
          }, 500);

          // Log info to FaceBook Pixel
          this.facebookPixel('Purchase', _totalAmount);

        } else {
          this._toastrService.error(
            this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 10000 }
          );
        }
      }, error => {
        this._toastrService.error(
          this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TEXT'),
          this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TITLE'),
          { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 10000 }
        );
      });
  }

  stripePaymentReceived_LoggedIn(transactionID: string) {
    //--- Payment received: Create VPN service entry
    let cartStorage: ICartStorage = <ICartStorage>{};
    cartStorage = JSON.parse(localStorage.getItem('__cart_storage__'));
    let orderedService: IOrderedServices = <IOrderedServices>{};
    orderedService.serviceID = +window.atob(cartStorage.serviceID);
    orderedService.orderedServiceID = +window.atob(cartStorage.orderedServiceID);
    orderedService.clientID = +window.atob(cartStorage.clientID);
    localStorage.removeItem('__cart_storage__');

    this._paymentService.paypalPayment(transactionID, orderedService)
      .subscribe(result => {
        if (result) {
          this._router.navigate(['/' + UrlList.ActiveServices])

          this._toastrService.success(
            this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_SUCCESS.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_SUCCESS.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 10000 }
          );
        } else {
          this._toastrService.error(
            this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        }
      }, error => {
        this._toastrService.error(
          this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TEXT'),
          this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TITLE'),
          { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 10000 }
        );
      });
  }

  facebookPixel(eventName: string, totalAmount: number) {
    (function (f: any, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }; if (!f._fbq) f._fbq = n;
      n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    (window as any).fbq('track', eventName, { value: totalAmount.toFixed(2), currency: 'EUR' });
  }
}
