<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="pricing-plan" *ngIf="!showPaymentMethods">
      <div class="text-center">
        <h1 class="mt-5">{{ 'SERVICES.BUY.TITLE' | translate }}</h1>
        <p class="mb-2 pb-75"></p>
      </div>

      <!-- pricing plan cards -->
      <div class="row pricing-card">
        <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
          <div class="row">
            <div class="col-12 col-md-4" *ngFor="let price of priceList; let i = index">
              <div class="card standard-pricing {{ (price.markingComment!='' && price.markingComment!=null) ? 'popular': '' }} text-center">
                <div class="card-body">
                  <div class="pricing-badge text-right {{ (price.markingComment!='' && price.markingComment!=null) ? '': 'mb-2' }}">
                    <div class="badge badge-pill badge-light-primary">{{ price.markingComment }}</div>
                  </div>
                  <h3>{{ price.serviceName }}</h3>
                  <p class="card-text">{{ price.description }}</p>
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <span class="font-medium-3 font-weight-bold text-muted" *ngIf="price.discount==0">{{ '&nbsp;' }}</span>
                      <span class="font-medium-3 font-weight-bold text-muted" *ngIf="price.discount!=0"><strike>{{ price.price | currency:'EUR' }}</strike></span>
                    </div>
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 font-weight-bold text-primary">{{ 'COMMON.CURRENCY_SYMBOL' | translate }} </sup>
                      <span class="pricing-basic-value font-weight-bolder text-primary">{{ price.discountPrice | number: '1.2-2' }}</span>
                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold"> /{{ price.measure }}</sub>
                    </div>
                    <div class="plan-price mt-50">
                      <sub class="pricing-duration text-success font-medium-1 font-weight-bold">{{ 'SERVICES.BUY.PERIOD_1' | translate }}{{ price.quantity + ' ' + price.measure }}{{ 'SERVICES.BUY.PERIOD_2' | translate }}</sub>
                    </div>
                    <div class="plan-price mt-25 mb-1">
                      <sub class="pricing-duration font-small-3 text-warning">{{ 'SERVICES.BUY.TOTAL_PAYMENT_1' | translate }}{{ price.quantity + ' ' + price.measure }}{{ 'SERVICES.BUY.TOTAL_PAYMENT_2' | translate }}<span class="font-weight-bold font-small-4">{{ (price.quantity * price.discountPrice).toFixed(2) | currency:'EUR' }}</span></sub>
                    </div>
                    <div class="plan-price mt-25 mb-1">
                      <sub class="pricing-duration font-small-3 font-weight-bold text-muted">{{ 'COMMON.FORM.VAT_INFO' | translate }}</sub>
                    </div>
                  </div>
                  <div class="text-left pt-1" [innerHTML]="price.comments"></div>
                  <button [disabled]="!buttonsEnabled"
                          (click)="onBuyPlan(price.serviceID)"
                          class="btn btn-block {{ (price.markingComment!='' && price.markingComment!=null) ? 'btn-primary': 'btn-outline-primary' }} mt-2"
                          rippleEffect>
                    {{ 'COMMON.ACTION.ORDER_NOW' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/ pricing plan cards -->


      <div class="misc-inner pt-0">
        <div class="pricing-free-trial d-flex justify-content-center col-12 ml-0">
          <div class="d-flex col-12 col-lg-10">
            <div class="pricing-trial-content d-flex pb-md-0 pb-2">
              <img src="{{ _environment.picsPath}}faq-illustrations.svg"
                   class="pricing-trial-img img-fluid mr-2"
                   alt="svg img" />

              <div class="text-center text-md-left mt-2">
                <h5 class="font-medium-2 pb-2" [innerHTML]="infoText1"></h5>
                <h5 class="font-medium-2" [innerHTML]="infoText2"></h5>
              </div>
            </div>
          </div>
        </div>
      </div>



      <!--<div class="pricing-free-trial">
    <div class="row">
      <div class="col-12 col-lg-10 mx-auto">
        <div class="pricing-trial-content d-flex justify-content-between">
          <img src="{{ _environment.picsPath}}faq-illustrations.svg"
               class="pricing-trial-img img-fluid pr-5"
               alt="svg img" />
          <div class="text-center text-md-left mt-3">
            <h4 class="pb-2" [innerHTML]="infoText1"></h4>
            <h4 class="pb-2" [innerHTML]="infoText2"></h4>
          </div>
        </div>
      </div>
    </div>
  </div>-->
      <!-- pricing faq -->
      <div class="pricing-faq">
        <h3 class="text-center">{{ 'SERVICES.BUY.FAQ.TITLE' | translate }}</h3>
        <p class="text-center">{{ 'SERVICES.BUY.FAQ.TEXT' | translate }}</p>
        <div class="row my-2">
          <div class="col-12 col-lg-10 col-lg-offset-2 mx-auto">
            <!-- faq collapse -->
            <div class="accordion collapse-margin collapse-icon">
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel id="deliveryId{{ i }}"
                           [cardClass]="'collapse-margin'"
                           *ngFor="let qandA of faq; let i = index">
                  <ng-template ngbPanelTitle>
                    <span>{{ qandA.question }}</span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <span [innerHTML]="qandA.answer"></span>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>
      <!--/ pricing faq -->
    </section>

    <section id="payment-methods" *ngIf="showPaymentMethods">
      <payment-select 
                       (onSelect)="onPaymentMethodSelect()"                    
                       (onCancel)="onPaymentMethodCancel()"
                       [cryptoinServicePrice]="selectedCryptoinServicePrice"
                       [orderedService]="selectedService">
      </payment-select>
    </section>
  </div>
</div>
