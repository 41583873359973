import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { takeUntil, map } from 'rxjs/operators';
import { ColumnMode, DatatableComponent, DataTablePagerComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from '../../_i18n/en';
import { Lithuanian } from 'flatpickr/dist/l10n/lt';

import { environment } from 'environments/environment';
import { UrlList, AppSettingName, AppSettingsTypeList } from 'app/constant';
import { AppSettings, FilterData, CryptoinServicesPricesList } from '../../common/common.data';
import { CommonService } from '../../common/common.service';
import { OrderedServicesList, IOrderedServices, SalesInvoiceEntry } from '../service.data';
import { AccountService } from '../../account/account.service';
import { ServiceService } from '../service.service';
import { SettingService } from '../../common/setting.service';

@Component({
  selector: 'service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ServiceListComponent implements OnInit, OnDestroy {
  appSettings: AppSettings = <AppSettings>{};
  _appSettingsList = AppSettingsTypeList;
  _listUrl = UrlList;
  filterDataList: FilterData[] = [];
  filterData: FilterData;
  
  servicesList: OrderedServicesList[];
  priceList: CryptoinServicesPricesList[];
  selectedCryptoinServicePrice: CryptoinServicesPricesList;
  salesInvoice: SalesInvoiceEntry;
  merchantLogo: string;
  merchantLogoWidth: number;

  contentHeader: object = [];
  buttonsEnabled: boolean = true;
  buttonActivatePressed: boolean = false;
  buttonChangePasswordPressed: boolean = false;

  selectedClientID: number = -1;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  selectedService: IOrderedServices;
  showPaymentMethods: boolean = false;

  recordCount = 0;

  //-- Datų nustatymai (pradžia)
  datePeriodSelect: boolean = false;
  dateFrom: string = '';
  dateTo: string = '';
  datePeriod: string[] = [];
  public DatePeriodOptions = {
    locale: Lithuanian,
    altInput: true,
    mode: 'range',
    altInputClass: 'form-control flat-picker flatpickr-input invoice-edit-input',
    altFormat: 'Y-m-d',
    onChange: this.onDatePeriodChange.bind(this)
  };
  //-- Datų nustatymai (pabaiga)

  todayDate = new Date();

  _unsubscribeAll: Subject<any>;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _serviceService: ServiceService,
    private _settingService: SettingService,
    private _accountService: AccountService,
    private _commonService: CommonService,
    private _toastrService: ToastrService,
    private _coreSidebarService: CoreSidebarService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private _datePipe: DatePipe
  ) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en);
  }

  ngOnInit() {
    this.DatePeriodOptions.locale = Lithuanian;

    //--- Gauname app nustatymus ---
    this._commonService.getAppSetting(
      AppSettingName.VpnAccountNotPaidStatus,
      this._appSettingsList.find(({ name }) => name === AppSettingName.VpnAccountNotPaidStatus).type)
      .subscribe(result => {
        this.appSettings.vpnAccountNotPaidStatus = result ? +result : 0;
      });

    //-- Nustatome prieš tai buvusį filtrą (pradžia)
    //jeigu nėra - sukūriame, jeigu yra priskiriame
    if (!JSON.parse(localStorage.getItem('filterData'))) {
      this.filterData = <FilterData>{};
      this.filterData.urlList = UrlList.ActiveServices;
      this.filterData.filterCleared = true;
      this.filterData.datePeriodOnOff = false;
      this.filterData.dateFrom = this.dateFrom;
      this.filterData.dateTo = this.dateTo;
      this.filterDataList.push(this.filterData);
      localStorage.setItem('filterData', JSON.stringify(this.filterDataList));
    } else {
      this.filterDataList = JSON.parse(localStorage.getItem('filterData'));
      this.filterData = this.filterDataList.find(({ urlList }) => urlList === UrlList.ActiveServices)
      if (!this.filterData) {
        this.filterData = <FilterData>{};
        this.filterData.urlList = UrlList.ActiveServices;
        this.filterData.filterCleared = true;
        this.filterData.datePeriodOnOff = false;
        this.filterData.dateFrom = this.dateFrom;
        this.filterData.dateTo = this.dateTo;
        this.filterDataList.push(this.filterData);
        localStorage.setItem('filterData', JSON.stringify(this.filterDataList));
      }
    }

    if (this.filterData.dateFrom == '' || !this.filterData.datePeriodOnOff) {
      //-- Nustatome filtro periodą
      this.dateFrom = this._datePipe.transform(this.todayDate.getFullYear().toString() + "-" + (this.todayDate.getMonth() + 1).toString() + "-01", 'yyyy-MM-dd');
      let _lastMonthDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, 0).getDate();
      this.dateTo = this._datePipe.transform(this.todayDate.getFullYear().toString() + "-" + (this.todayDate.getMonth() + 1).toString() + "-" + _lastMonthDay.toString(), 'yyyy-MM-dd');
    } else {
      this.dateFrom = this.filterData.dateFrom;
      this.dateTo = this.filterData.dateTo;
    }
    this.datePeriod.push(this.dateFrom, this.dateTo);
    this.datePeriodSelect = this.filterData.datePeriodOnOff;
    //-- Nustatome prieš tai buvusį filtrą (pabaiga)

    this.formHeader();

    //--- Gauname Merchant'o logo
    this._commonService.getAppSetting(
      AppSettingName.MerchantID,
      this._appSettingsList.find(({ name }) => name === AppSettingName.MerchantID).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.merchantLogo = result ? environment.merchantsPath + result + '/logo.jpg' : '';
      });
    //--- Gauname Merchant'o logo dydį
    this._commonService.getAppSetting(
      AppSettingName.MerchantLogoWidth,
      this._appSettingsList.find(({ name }) => name === AppSettingName.MerchantLogoWidth).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.merchantLogoWidth = result ? +result : 50;
      });

    //--- Get price list ---
    this._commonService.getCryptoinServicesPriceList<CryptoinServicesPricesList>(-1)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.priceList = result;
      });

    //--- Ištraukiame prisijungusio vartotojo užsakytų paslaugų sąrašą
    this._accountService.getClientID().subscribe(clientID => {
      this.selectedClientID = clientID;
      this.getServicesList();
    })
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  formHeader() {
    this.contentHeader = {
      headerTitle: this._translateService.instant('SERVICES.CONTENT.HEADER.TITLE'),
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('SERVICES.CONTENT.HEADER.NAME'),
            isLink: true,
            link: '/' + UrlList.ActiveServices
          }
        ]
      }
    };
  }

  loadData() {
    this.getServicesList();
    this.saveFilter(false);
  }

  getServicesList() {
    this._serviceService.getClientServicesList<OrderedServicesList>(
      this.selectedClientID,
      this.datePeriodSelect ? this.dateFrom : null,
      this.datePeriodSelect ? this.dateTo : null,
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .pipe(
        map((items) => items
          .map(item  => ({
            orderedServiceID: item.orderedServiceID,
            vpnid: item.vpnid,
            vpnUserName: item.vpnUserName,
            newPassword: '',
            clientName: item.clientName,
            companyName: item.companyName,
            companyCode: item.companyCode,
            companyVATCode: item.companyVATCode,
            companyAddress: item.companyAddress,
            orderDate: item.orderDate,
            orderNo: item.orderNo,
            paymentDate: item.paymentDate,
            serviceID: item.serviceID,
            serviceName: item.serviceName,
            serviceDescription: item.serviceDescription,
            serviceComments: item.serviceComments,
            serviceMeasure: item.serviceMeasure,
            quantity: item.quantity,
            price: item.price,
            total: item.total,
            vatPercent: item.vatPercent,
            vatSum: item.vatSum,
            totalPaid: item.totalPaid,
            activeFrom: item.activeFrom,
            activeTo: item.activeTo,
            statusID: item.statusID,
            status: item.status,
            statusColorCode: item.statusColorCode,
            salesInvoiceID: item.salesInvoiceID,
            buttonChangePsswd: false,
            buttonActivate: false
          })))
    )
      .subscribe(mappedItems => {
        this.servicesList = mappedItems;
        this.recordCount = this.servicesList.length;
      });
  }

  onDatePeriodSelect() {
    if (!this.datePeriodSelect) {
      this.dateFrom = this._datePipe.transform(this.todayDate.getFullYear().toString() + "-" + (this.todayDate.getMonth() + 1).toString() + "-01", 'yyyy-MM-dd');
      let _lastMonthDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, 0).getDate();
      this.dateTo = this._datePipe.transform(this.todayDate.getFullYear().toString() + "-" + (this.todayDate.getMonth() + 1).toString() + "-" + _lastMonthDay.toString(), 'yyyy-MM-dd');
    }

    this.loadData();
  }

  onDatePeriodChange(element) {
    if (element.length == 1) {
      this.dateFrom = this._datePipe.transform(element[0], 'yyyy-MM-dd');
    } else if (element.length == 2) {
      this.dateFrom = this._datePipe.transform(element[0], 'yyyy-MM-dd');
      this.dateTo = this._datePipe.transform(element[1], 'yyyy-MM-dd');
      this.datePeriod[0] = this.dateFrom;
      this.datePeriod[1] = this.dateTo;
      this.loadData();
    }
  }

  onInvoiceOpen(_orderedServiceID: number) {
    this._serviceService.getInvoiceEntry<SalesInvoiceEntry>(_orderedServiceID)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.salesInvoice = result;
        this.printInvoice();
      });   
  }

  onReNew(_orderedServiceID: number, _cryptoinServiceID: number) {
    this.selectedService = <IOrderedServices>{};
    this.selectedService.orderedServiceID = _orderedServiceID;
    this.selectedService.clientID = this.selectedClientID;
    this.selectedService.serviceID = -2;
    this.selectedCryptoinServicePrice = this.priceList.find(({ serviceID }) => serviceID === _cryptoinServiceID);

    this.buttonsEnabled = false;
    this.showPaymentMethods = true;
  }
  
  onChangePlan(_orderedServiceID: number, _cryptoinServiceID: number) {
    this.selectedService = <IOrderedServices>{};
    this.selectedService.orderedServiceID = _orderedServiceID;
    this.selectedService.serviceID = _cryptoinServiceID;
    this.selectedService.clientID = this.selectedClientID;
    this.selectedCryptoinServicePrice = this.priceList.find(({ serviceID }) => serviceID === _cryptoinServiceID);

    this.buttonsEnabled = false;
    this.showPaymentMethods = true;
  }

  onNewVPNUser(_orderedServiceID: number) {
    this.buttonsEnabled = false;
    this.servicesList.find(({ orderedServiceID }) => orderedServiceID === _orderedServiceID).buttonActivate = true;
  }

  onActivateUser(_orderedServiceID: number) {
    //--- Show message that process is in progress ---
    this._toastrService.info(
      this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
      this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE'),
      { toastClass: 'toast ngx-toastr', closeButton: false }
    );

    this.buttonActivatePressed = true;
    let userName = this.servicesList.find(({ orderedServiceID }) => orderedServiceID === _orderedServiceID).vpnUserName;
    this._serviceService.checkVPNUser(userName)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(found => {
        if (found) {
          //this.buttonsEnabled = true;
          this.buttonActivatePressed = false;
          this._toastrService.error(
            this._translateService.instant('COMMON.NOTIFICATION.VPN_USER_TAKEN.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.VPN_USER_TAKEN.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        } else {
          this._serviceService.activateVPNUser(_orderedServiceID, userName)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
              if (result[0].indexOf('success') > 0) {
                //--- Refresh list ---
                this.getServicesList();
                this.buttonsEnabled = true;

                this._toastrService.success(
                  result,
                  this._translateService.instant('COMMON.NOTIFICATION.VPN_ACCOUNT_ACTIVATED.TITLE'),
                  { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 7000 }
                );
              } else {
                this._toastrService.error(
                  result,
                  this._translateService.instant('COMMON.NOTIFICATION.VPN_ACCOUNT_NOT_ACTIVATED.TITLE'),
                  { toastClass: 'toast ngx-toastr', closeButton: false }
                );
              }

              this.buttonActivatePressed = false;
            });
        }
      });  
  }

  onCancelActivateUser(_orderedServiceID: number) {
    this.buttonsEnabled = true;
    this.servicesList.find(({ orderedServiceID }) => orderedServiceID === _orderedServiceID).buttonActivate = false;
  }

  onChangePassword(_orderedServiceID: number) {
    this.buttonsEnabled = false;
    this.servicesList.find(({ orderedServiceID }) => orderedServiceID === _orderedServiceID).buttonChangePsswd = true;
  }

  onSaveNewPassword(_orderedServiceID: number, vpnid: string) {
    //--- Show message that process is in progress ---
    this._toastrService.info(
      this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
      this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE'),
      { toastClass: 'toast ngx-toastr', closeButton: false }
    );

    this.buttonChangePasswordPressed = true;
    let newUserPassword = this.servicesList.find(({ orderedServiceID }) => orderedServiceID === _orderedServiceID).newPassword;
    this._serviceService.changeVPNUserPassword(_orderedServiceID, vpnid, newUserPassword)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        if (result[0].indexOf('success') > 0) {
          //--- Refresh list ---
          this.getServicesList();
          this.buttonsEnabled = true;

          this._toastrService.success(
            result,
            this._translateService.instant('COMMON.NOTIFICATION.VPN_USE_PASSWORD_CHANGED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        } else {
          this._toastrService.error(
            result,
            this._translateService.instant('COMMON.NOTIFICATION.VPN_USE_PASSWORD_NOT_CHANGED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        }

        this.buttonChangePasswordPressed = false;
      });
  }

  onCancelNewPassword(_orderedServiceID: number) {
    this.buttonsEnabled = true;
    this.servicesList.find(({ orderedServiceID }) => orderedServiceID === _orderedServiceID).buttonChangePsswd = false;
  }

  saveFilter(_filterCleared: boolean) {
    let _filterData: FilterData = <FilterData>{};
    _filterData.urlList = UrlList.ActiveServices;
    _filterData.filterCleared = _filterCleared;
    _filterData.datePeriodOnOff = this.datePeriodSelect;
    _filterData.dateFrom = this.dateFrom;
    _filterData.dateTo = this.dateTo;

    for (let _i in this.filterDataList) {
      if (this.filterDataList[_i].urlList == UrlList.ActiveServices) {
        this.filterDataList[_i] = _filterData
      }
    }
    localStorage.setItem('filterData', JSON.stringify(this.filterDataList));

    //-- Atnaujime duomenys
    this.filterData = _filterData;
  }

  clearFilter() {
    // Nuresetiname datas
    this.datePeriodSelect = false;
    this.dateFrom = this._datePipe.transform(this.todayDate.getFullYear().toString() + "-" + (this.todayDate.getMonth() + 1).toString() + "-01", 'yyyy-MM-dd');
    let _lastMonthDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, 0).getDate();
    this.dateTo = this._datePipe.transform(this.todayDate.getFullYear().toString() + "-" + (this.todayDate.getMonth() + 1).toString() + "-" + _lastMonthDay.toString(), 'yyyy-MM-dd');
    this.datePeriod = [this.dateFrom, this.dateTo];

    this.saveFilter(true);

    this.getServicesList();
  }

  printInvoice() {
    this.getBase64ImageFromUrl(this._serviceService.baseUrl + this.merchantLogo)
      .then(_logoBase64 => {
        this.generatePDF(_logoBase64);
      })
      .catch(() => this.generatePDF());
  }

  generatePDF(_logoBase64 = null) {
    if (this.merchantLogo == '') _logoBase64 = null;
    const _sellerFontSize = 11;
    const _buyerFontSize = 11;
    const _bodyFontSize = 10;
    const _emptyLogo = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';

    let docDefinition = {
      pageMargins: [20, 20, 20, 20],
      content: [
        {
          columns: [
            [
              {
                columns: [
                  {
                    image: !_logoBase64 ? _emptyLogo : _logoBase64,
                    fit: !_logoBase64 ? [0, 0] : [this.merchantLogoWidth, this.merchantLogoWidth],
                    width: !_logoBase64 ? -10 : this.merchantLogoWidth,
                    margin: [0, -5, 0, 5]
                  },
                  {
                    text: this.salesInvoice.cryptoinCompanyName,
                    //font: 'Helvetica',
                    fontSize: 18,
                    bold: true
                  },
                ],
                columnGap: 10
              },
              { text: '', margin: 3 },
              { text: this._translateService.instant('SALE.INVOICE.PRINT.COMPANY_CODE') + this.salesInvoice?.cryptoinCompanyCode, fontSize: _sellerFontSize },
              { text: this.salesInvoice?.cryptoinCompanyVatCode ? this._translateService.instant('SALE.INVOICE.PRINT.VAT_CODE') + this.salesInvoice?.cryptoinCompanyVatCode : '', fontSize: _sellerFontSize },
              { text: this.salesInvoice?.cryptoinCompanyFullAddress, fontSize: _sellerFontSize },
              { text: this.salesInvoice?.cryptoinCompanyEmail, fontSize: _sellerFontSize },
            ],
            [
              {
                text: this.salesInvoice.invoiceTypeName + ' ' + this.salesInvoice.invoiceSeries + this.salesInvoice.invoiceNo,
                fontSize: _sellerFontSize + 4,
                bold: true,
                alignment: 'right',
              },
              {
                text: this._translateService.instant('SALE.INVOICE.PRINT.INVOICE_DATE') + this._datePipe.transform(this.salesInvoice.invoiceDate, 'yyyy-MM-dd'),
                fontSize: _sellerFontSize,
                bold: true,
                alignment: 'right',
                margin: [0, 10, 0, 5]
              },
              {
                text: this._translateService.instant('SALE.INVOICE.PRINT.PAYMENT_DATE') + this._datePipe.transform(this.salesInvoice.paymentDate, 'yyyy-MM-dd'),
                fontSize: _sellerFontSize,
                bold: true,
                alignment: 'right'
              }
            ]
          ],
        },
        { text: '', margin: 15 },
        {
          columns: [
            [
              {
                text: this._translateService.instant('SALE.INVOICE.PRINT.BUYER_TITLE'),
                fontSize: _buyerFontSize,
                italics: true,
              },
              {
                text: this.salesInvoice.buyerName,
                fontSize: _buyerFontSize,
                bold: true
              },
              { text: this.salesInvoice.buyerCompanyCode ? this._translateService.instant('SALE.INVOICE.PRINT.COMPANY_CODE') + this.salesInvoice.buyerCompanyCode : '', fontSize: _buyerFontSize },
              { text: this.salesInvoice.buyerVatCode ? this._translateService.instant('SALE.INVOICE.PRINT.VAT_CODE') + this.salesInvoice.buyerVatCode : '', fontSize: _buyerFontSize },
              { text: this.salesInvoice.buyerAccountType == 1 ? this.salesInvoice.buyerAddress : '', fontSize: _buyerFontSize },
              { text: this.salesInvoice.buyerAccountType == 1 ? this.salesInvoice.buyerCity + (this.salesInvoice.buyerDistrict != '' ? ', ' + this.salesInvoice.buyerDistrict : '') : '', fontSize: _buyerFontSize },
              { text: this.salesInvoice.buyerAccountType == 0 ? (this.salesInvoice.buyerEmail ? this.salesInvoice.buyerEmail : '') : '', fontSize: _buyerFontSize },
              { text: this.salesInvoice.buyerCountry, fontSize: _buyerFontSize },
              { text: this.salesInvoice.buyerAccountType == 1 ? (this.salesInvoice.buyerEmail ? this.salesInvoice.buyerEmail : '') : '', fontSize: _buyerFontSize },
            ]
          ]
        },
        { text: '', margin: 15 },
        {
          layout: 'lightHorizontalLines',
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: (this.salesInvoice.cryptoinCompanyVatCode != '' && this.salesInvoice.cryptoinCompanyVatCode != null)
              ? ['*', 'auto', 'auto', 'auto', 'auto', 65]
              : ['*', 'auto', 'auto', 'auto', 65],

            body: [
              (this.salesInvoice.cryptoinCompanyVatCode != '' && this.salesInvoice.cryptoinCompanyVatCode != null)
                ? [{ text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.DESCRIPTION'), bold: true, fontSize: _bodyFontSize }, { text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.QUANTITY'), bold: true, fontSize: _bodyFontSize }, { text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.PRICE'), bold: true, fontSize: _bodyFontSize }, { text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.TOTAL'), bold: true, fontSize: _bodyFontSize }, { text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.VAT_AMOUNT'), bold: true, fontSize: _bodyFontSize }, { text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.TOTAL_AMOUNT'), bold: true, fontSize: _bodyFontSize, alignment: 'right' }]
                : [{ text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.DESCRIPTION'), bold: true, fontSize: _bodyFontSize }, { text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.QUANTITY'), bold: true, fontSize: _bodyFontSize }, { text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.PRICE'), bold: true, fontSize: _bodyFontSize }, { text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.TOTAL'), bold: true, fontSize: _bodyFontSize }, { text: this._translateService.instant('SALE.INVOICE.PRINT.SERVICE_LIST.TOTAL_AMOUNT'), bold: true, fontSize: _bodyFontSize, alignment: 'right' }],
              (this.salesInvoice.cryptoinCompanyVatCode != '' && this.salesInvoice.cryptoinCompanyVatCode != null)
                ? [{ text: this.salesInvoice.serviceDescription, fontSize: _bodyFontSize }, { text: this.salesInvoice.quantity + ' ' + this.salesInvoice.serviceMeasure.toLowerCase(), fontSize: _bodyFontSize }, { text: this.salesInvoice.price.toFixed(2), fontSize: _bodyFontSize }, { text: this.salesInvoice.total.toFixed(2), fontSize: _bodyFontSize }, { text: this.salesInvoice.vatSum.toFixed(2) + ' (' + this.salesInvoice.vatPercent + '%)', fontSize: _bodyFontSize }, { text: this.salesInvoice.totalPaid.toFixed(2), fontSize: _bodyFontSize, alignment: 'right' }]
                : [{ text: this.salesInvoice.serviceDescription, fontSize: _bodyFontSize }, { text: this.salesInvoice.quantity + ' ' + this.salesInvoice.serviceMeasure.toLowerCase(), fontSize: _bodyFontSize }, { text: this.salesInvoice.price.toFixed(2), fontSize: _bodyFontSize }, { text: this.salesInvoice.total.toFixed(2), fontSize: _bodyFontSize }, { text: this.salesInvoice.totalPaid.toFixed(2), fontSize: _bodyFontSize, alignment: 'right' }],
              (this.salesInvoice.cryptoinCompanyVatCode != '' && this.salesInvoice.cryptoinCompanyVatCode != null)
                ? [{}, {}, {}, {}, {}, {}]
                : [{}, {}, {}, {}, {}]
            ]
          }
        },
        {
          layout: 'noBorders',
          table: {
            widths: (this.salesInvoice.cryptoinCompanyVatCode != '' && this.salesInvoice.cryptoinCompanyVatCode != null) ? ['*', '*', '*', '*', '*', 90, 50] : ['*', '*', '*', '*', '*', 50],
            body: [
              (this.salesInvoice.cryptoinCompanyVatCode != '' && this.salesInvoice.cryptoinCompanyVatCode != null)
                ? [{}, {}, {}, {}, {}, { text: this._translateService.instant('SALE.INVOICE.PRINT.TOTALS.TOTAL') + this.salesInvoice.currencySymbol + '', bold: true, fontSize: _sellerFontSize, alignment: 'right' }, { text: this.salesInvoice.total.toFixed(2), fontSize: _sellerFontSize, alignment: 'right' }]
                : [{}, {}, {}, {}, {}, {}],
              (this.salesInvoice.cryptoinCompanyVatCode != '' && this.salesInvoice.cryptoinCompanyVatCode != null)
                ? [{}, {}, {}, {}, {}, { text: this._translateService.instant('SALE.INVOICE.PRINT.TOTALS.VAT') + this.salesInvoice.vatPercent + "%", bold: true, fontSize: _bodyFontSize, alignment: 'right' }, { text: this.salesInvoice.vatSum.toFixed(2), fontSize: _bodyFontSize, alignment: 'right' }]
                : [{}, {}, {}, {}, {}, {}],
              (this.salesInvoice.cryptoinCompanyVatCode != '' && this.salesInvoice.cryptoinCompanyVatCode != null)
                ? [{}, {}, {}, {}, {}, { text: this._translateService.instant('SALE.INVOICE.PRINT.TOTALS.TOTAL_AMOUNT') + this.salesInvoice.currencySymbol + '', bold: true, fontSize: _sellerFontSize, alignment: 'right' }, { text: this.salesInvoice.totalPaid.toFixed(2), fontSize: _sellerFontSize, alignment: 'right' }]
                : [{}, {}, {}, {}, { text: this._translateService.instant('SALE.INVOICE.PRINT.TOTALS.TOTAL_AMOUNT') + this.salesInvoice.currencySymbol + '', bold: true, fontSize: _sellerFontSize, alignment: 'right' }, { text: this.salesInvoice.totalPaid.toFixed(2), fontSize: _sellerFontSize, alignment: 'right' }]
            ]
          }
        },
        { text: '', margin: 10 },
        { text: '', margin: 20 },
        {
          text: this._translateService.instant('SALE.INVOICE.PRINT.ISSUED_BY') + this.salesInvoice.invoiceIssuedBy,
          fontSize: _sellerFontSize,
        }
      ]
    };

    pdfMake.createPdf(docDefinition).open();
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  onPaymentMethodSelect() {
    this.buttonsEnabled = true;
    this.showPaymentMethods = false;
    this.getServicesList();
  }

  onPaymentMethodCancel() {
    this.buttonsEnabled = true;
    this.showPaymentMethods = false;
  }

  onMakePayment(_orderedServiceID: number, _cryptoinServiceID: number) {
    this.selectedService = <IOrderedServices>{};
    this.selectedService.orderedServiceID = _orderedServiceID;
    this.selectedService.clientID = this.selectedClientID;
    this.selectedService.serviceID = -3;
    this.selectedCryptoinServicePrice = this.priceList.find(({ serviceID }) => serviceID === _cryptoinServiceID);

    this.buttonsEnabled = false;
    this.showPaymentMethods = true;
  }

  onCancelOrder(_orderedServiceID: number) {
    this._serviceService.deleteOrderedService(_orderedServiceID)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.getServicesList();

        if (result) {
          this._toastrService.success(
            this._translateService.instant('COMMON.NOTIFICATION.CANCELED_SUCCESS.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.CANCELED_SUCCESS.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        } else {
          this._toastrService.error(
            this._translateService.instant('COMMON.NOTIFICATION.CANCELED_FAILED.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.CANCELED_FAILED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        }
    });
  }
}
