import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'account-logout',
  template: ''
})
export class AccountLogout implements OnInit {

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this._authenticationService.logout();
    this._router.navigate(['/']);
  }
}
