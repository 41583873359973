<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <div class="card mb-0">
      <div class="card-body">
        <a href="{{ _environment.apiUrl }}" class="brand-logo align-items-center">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="47" />
          <h2 class="brand-text text-primary ml-1 mb-0">{{ coreConfig.app.appName }}</h2>
        </a>

        <h4 class="card-title mb-1">{{ 'ACCOUNT.DIALOG.LOGIN.TITLE' | translate }}</h4>
        <p class="card-text mb-2">{{ 'ACCOUNT.DIALOG.LOGIN.TEXT' | translate }}</p>

        <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="login-email" class="form-label">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME' | translate }}</label>
            <input type="text"
                   formControlName="email"
                   class="form-control"
                   placeholder="{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_PLACEHOLDER' | translate }}"
                   aria-describedby="login-email"
                   tabindex="2"
                   [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_ERROR1' | translate }}</div>
              <div *ngIf="f.email.errors.email">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_ERROR2' | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">{{ 'ACCOUNT.DIALOG.LOGIN.PASSWORD' | translate }}</label>
              <a routerLink="/forgot-password"><small>{{ 'ACCOUNT.DIALOG.LOGIN.FORGOT_PASSWORD' | translate }}</small></a>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input [type]="passwordTextType ? 'text' : 'password'"
                     formControlName="password"
                     class="form-control form-control-merge"
                     tabindex="3"
                     placeholder="·········"
                     aria-describedby="login-password"
                     [ngClass]="{ 'is-invalid error': submitted && f.password.errors }" />

              <div class="input-group-append">
                <span class="input-group-text cursor-pointer">
                  <i class="feather font-small-4"
                     [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                     (click)="togglePasswordTextType()"></i>
                </span>
              </div>
            </div>
            <div *ngIf="submitted && f.password.errors"
                 class="invalid-feedback"
                 [ngClass]="{ 'd-block': submitted && f.password.errors }">
              <div *ngIf="f.password.errors.required">{{ 'ACCOUNT.DIALOG.LOGIN.PASSWORD_ERROR1' | translate }}</div>
            </div>
          </div>

          <button class="btn btn-primary btn-block mt-2" tabindex="4" rippleEffect>{{ 'ACCOUNT.DIALOG.LOGIN.BUTTON_LOGIN' | translate }}</button>

          <hr class="invoice-spacing mt-2" />

          <div class="form-group row pl-0 mt-2 mb-0">
            <div class="pl-1 col-form-label">
              <label>{{ 'ACCOUNT.DIALOG.LOGIN.LOCALE' | translate }}</label>
            </div>
            <select [(ngModel)]="selectedMenuLocale" formControlName="locale" class="form-control ml-1 col-3" (change)="setDialogLanguage()">
              <option *ngFor="let loc of menuLocale" [ngValue]="loc.value">{{ loc.name }}</option>
            </select>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>
