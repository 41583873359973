import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { CoreConfigService } from '@core/services/config.service';

import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { KeyName } from 'app/constant';
import { WebService } from '../web.service';

@Component({
  selector: 'privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss']
})
export class PrivacyPolicyPageComponent implements OnInit, OnDestroy {
  _environment = environment;
  coreConfig: any;

  conditionHeader: string;
  privacyPolicy: string;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _webService: WebService,
    private _coreConfigService: CoreConfigService,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(en);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

    // Pasl�piame menu ir navigation bar
    this._coreConfigService.setConfig(
      { layout: { navbar: { hidden: true }, menu: { hidden: true } } },
      { emitEvent: true }
    );

    // Privatumo palitikos tekst�
    this._webService.getConditionsText(KeyName.CryptoinVPNPrivacyPolicy)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_conditions => {
        this.privacyPolicy = _conditions;
        this.conditionHeader = this._translateService.instant('WEB.PRIVACY_POLICY.HEADER');
      });
  }

  ngOnDestroy(): void {
    //--- Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
