import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';
import { TranslateModule } from '@ngx-translate/core';

import { EmailConfirmedComponent } from 'app/pages/miscellaneous/email-confirmed/email-confirmed.component';
import { ErrorComponent } from 'app/pages/miscellaneous/error/error.component';
import { NotAuthorizedComponent } from 'app/pages/miscellaneous/not-authorized/not-authorized.component';

// routing
const routes: Routes = [
  {
    path: 'confirm-email/:confirmAccessCode',
    component: EmailConfirmedComponent
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  }
];

@NgModule({
  declarations: [EmailConfirmedComponent, ErrorComponent, NotAuthorizedComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CoreCommonModule, TranslateModule.forRoot()]
})
export class MiscellaneousModule {}
