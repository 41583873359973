import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { encode } from 'js-base64';

import { CoreConfigService } from '@core/services/config.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { loadStripe } from "@stripe/stripe-js";

import { CryptoinServicesPricesList, AppSettings, AppSettingsList, IStripePaymentRequest, ICartStorage } from 'app/cryptoin-vpn/common/common.data';
import { UrlList, AppSettingName, KeyName } from 'app/constant';
import { CountriesList } from 'app/cryptoin-vpn/common/common.list.data';
import { IOrderedServices, IOrderedServicesNewAccount } from 'app/cryptoin-vpn/service/service.data';
import { CommonService } from 'app/cryptoin-vpn/common/common.service';
import { PaymentService } from 'app/cryptoin-vpn/payment/payment.service';
import { WebService } from 'app/pages/web/web.service';

@Component({
  selector: 'payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentPageComponent implements OnInit, OnDestroy, AfterViewInit {
  _environment = environment;
  coreConfig: any;
  appSettingsList: AppSettingsList[] = [];
  appSettings: AppSettings = <AppSettings>{};
  cryptoinLogo: string;

  buyNowLoad: boolean = false;
  priceList: CryptoinServicesPricesList[];

  createAccountForm: FormGroup;
  emailFound: boolean = false; 
  discountPercent: number = 0;
  discountAmount: number = 0;

  countriesList: CountriesList[];
  countryName: string;
  countryISO: string;
  commentText: string;
  termsPrivacyText1: string;
  termsPrivacyText2: string;
  termsPrivacyText3: string;
  termsPrivacyText4: string;
 
  payPalConfig?: IPayPalConfig;
  stripe;
  stripePaymentSelected: boolean = false;

  processPaymentStatus: boolean = false;
  buttonsEnabled: boolean = true;
  payseraText: string;
  paypalText: string;
  cryptoText: string;
  creditCardText: string;
  stripeText: string;
  dateFrom: Date = new Date();
  dateTo: Date = new Date();

  termsOfUse: string;
  privacyPolicy: string;
  conditionHeader: string;
  conditionBody: string
  
  @Input() public orderedService: IOrderedServicesNewAccount; 
  @Input() public cryptoinServicePrice: CryptoinServicesPricesList;
  @Input() public merchantID: string;
  @Input() public merchantEmail: string;  
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  contentHeader: object = [];
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _router: Router,
    private _commonService: CommonService,
    private _paymentService: PaymentService,
    private _webService: WebService,
    private _modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private _coreConfigService: CoreConfigService,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(en);
    this._unsubscribeAll = new Subject();

    // Pasiimame naudojimosi s�lyg� tekst�
    this._webService.getConditionsText(KeyName.CryptoinVPNTermsOfUse)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_conditions => {
        this.termsOfUse = _conditions;
      });

    // Privatumo palitikos tekst�
    this._webService.getConditionsText(KeyName.CryptoinVPNPrivacyPolicy)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_conditions => {
        this.privacyPolicy = _conditions;
      });
  }

  handler: any = null;

  ngOnInit(): void {
    //--- Patikriname ar n�ra kreipiamasi per buy-now nuorod� ---
    this.buyNowLoad = this.cryptoinServicePrice ? false : true;

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

    localStorage.removeItem("__cart_storage__");
    this.cryptoinLogo = this._webService.baseUrl + 'assets/images/logo/logo.png'
    this.dateTo.setMonth(!this.buyNowLoad ? this.cryptoinServicePrice.quantity + 1 : 1);

    this.createAccountForm = this._formBuilder.group({      
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      countryID: ['', Validators.required],
    });

    //=== Gauname app nustatymus ===
    this._webService.getPaymentsMethodsParams()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.appSettingsList = result;

        //--- Cryproin Merchant company name
        this.appSettings.cryptoinVPNCompanyName = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.CryptoinVPNCompanyName).valueStr;
        //--- Cryptoin Merchant country
        this.appSettings.cryptoinVPNCountry = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.CryptoinVPNCountry).valueStr;
        this.countryName = this.appSettings.cryptoinVPNCountry;
        //--- Cryptoin Merchant currency
        this.appSettings.cryptoinVPNCurrency = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.CryptoinVPNCurrency).valueStr;
        //--- Cryptoin Merchant company code
        this.appSettings.cryptoinVPNCompanyCode = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.CryptoinVPNCompanyCode).valueStr;
        //--- Cryptoin Merchant VAT code
        this.appSettings.cryptoinVPNCompanyVatCode = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.CryptoinVPNCompanyVatCode).valueStr;
        //--- Cryptoin Merchant full address
        this.appSettings.cryptoinVPNCompanyFullAddress = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.CryptoinVPNCompanyFullAddress).valueStr;
        //--- Cryptoin Merchant phone
        this.appSettings.cryptoinVPNCompanyPhone = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.CryptoinVPNCompanyPhone).valueStr;
        //--- Cryptoin Merchant email
        this.appSettings.cryptoinVPNCompanyEmail = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.CryptoinVPNCompanyEmail).valueStr;
        //--- Cryptoin VAT percent
        this.appSettings.cryptoinVPNCompanyVatPercent = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.CryptoinVPNCompanyVatPercent).valueDec;
        //--- Paypal Gateway Enabled
        this.appSettings.paypalGatewayEnabled = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.PaypalGatewayEnabled).valueInt;
        //--- Stripe Gateway Enabled
        this.appSettings.stripeGatewayEnabled = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.StripeGatewayEnabled).valueInt;
        //--- Bitpay Gateway Enabled
        this.appSettings.bitpayGatewayEnabled = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.BitpayGatewayEnabled).valueInt;
        //--- Paysera Gateway Enabled
        this.appSettings.payseraGatewayEnabled = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.PayseraGatewayEnabled).valueInt;
        //--- Paypal Client ID
        this.appSettings.paypalClientID = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.PaypalClientID).valueStr;
        //--- Stripe Publishable Key
        this.appSettings.stripePublishableKey = this.appSettingsList.find(({ settingName }) => settingName === AppSettingName.StripePublishableKey).valueStr;
        this.stripe = loadStripe(this.appSettings.stripePublishableKey);

        //--- Assign info text ---
        this.payseraText = this._translateService.instant('PAYMENT.PAYSERA.TEXT_1') + this.appSettings.cryptoinVPNCurrency + this._translateService.instant('PAYMENT.PAYSERA.TEXT_2');
        this.paypalText = this._translateService.instant('PAYMENT.PAYPAL.TEXT_1') + this.appSettings.cryptoinVPNCurrency + this._translateService.instant('PAYMENT.PAYPAL.TEXT_2');
        this.cryptoText = this._translateService.instant('PAYMENT.CRYPTO.TEXT');
        this.creditCardText = this._translateService.instant('PAYMENT.CREDITCARD.TEXT_1') + this.appSettings.cryptoinVPNCurrency + this._translateService.instant('PAYMENT.CREDITCARD.TEXT_2');
        this.stripeText = this._translateService.instant('PAYMENT.STRIPE.TEXT_1') + this.appSettings.cryptoinVPNCurrency + this._translateService.instant('PAYMENT.STRIPE.TEXT_2');

        //--- Check merchant discounts and update payments systems values
        this.checkDiscount();
      });
    //===/ Gauname app nustatymus ===

    //Gauname plan� kainas
    this._webService.getPriceList<CryptoinServicesPricesList>()
      .pipe(takeUntil(this._unsubscribeAll))
      .pipe(
        map((items) => items
          .map(item => ({
            serviceID: item.serviceID,
            serviceType: item.serviceType,
            externalSystemCode: item.externalSystemCode,
            serviceName: item.serviceName,
            description: item.description,
            measure: item.measure,
            quantity: item.quantity,
            price: item.price,
            discount: item.discount,
            discountPrice: item.discountPrice,
            comments: item.comments,
            markingComment: item.markingComment,
            imageName: item.imageName,
            colorCode: item.comments,
            selected: false
          })))
      )
      .subscribe(mappedItems => {
        this.priceList = mappedItems;
      });

    //--- Paimame �ali� s�ra��
    this._webService.getCountriesList()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.countriesList = result;
    });

    //--- Assign info text ---
    this.commentText = this._translateService.instant('WEB.PAYMENT_PAGE.CREATE_ACCOUNT.COMMENT');
    this.termsPrivacyText1 = this._translateService.instant('WEB.PAYMENT_PAGE.CREATE_ACCOUNT.TERMS_PRIVACY1');
    this.termsPrivacyText2 = this._translateService.instant('WEB.PAYMENT_PAGE.CREATE_ACCOUNT.TERMS_PRIVACY2');
    this.termsPrivacyText3 = this._translateService.instant('WEB.PAYMENT_PAGE.CREATE_ACCOUNT.TERMS_PRIVACY3');
    this.termsPrivacyText4 = this._translateService.instant('WEB.PAYMENT_PAGE.CREATE_ACCOUNT.TERMS_PRIVACY4');
  }

  ngAfterViewInit() {
    //--- Go to page top 
    if (!this.buyNowLoad)
      document.querySelector('#startingPoint').scrollIntoView();
  }

  ngOnDestroy(): void {
    //--- Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  get f() {
    return this.createAccountForm.controls;
  }

  onChangeCountry(coutryID: number) {
    this.countryISO = this.countriesList.find(({ keyID }) => keyID === coutryID).shortName;
    this.countryName = this.countriesList.find(({ keyID }) => keyID === coutryID).fullName;
    this.appSettings.cryptoinVPNCompanyVatPercent = this.countriesList.find(({ keyID }) => keyID === coutryID).vatPercent;

    //--- Check email usage
    this.checkEmailAddress();

    //--- Set new payments systems values
    this.initPaypal();
  }

  private initPaypal(): void {
    this.payPalConfig = {
      currency: this.appSettings.cryptoinVPNCurrency,
      clientId: this.appSettings.paypalClientID,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: this.appSettings.cryptoinVPNCurrency,
            value: this.getTotals('TA'),
            breakdown: {
              item_total: {
                currency_code: this.appSettings.cryptoinVPNCurrency,
                value: this.getTotals('TA')
              }
            }
          },
          items: [{
            name: this.cryptoinServicePrice.serviceName,
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {
              currency_code: this.appSettings.cryptoinVPNCurrency,
              value: this.getTotals('TA'),
            },
          }]
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'pay',
        layout: 'vertical',
        color: 'silver'
      },
      onApprove: (data, actions) => {
        /*
        //--- Transaction was approved, but not authorized
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
        */
        this._toastrService.info(
          this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
          "PAYPAL payment " + this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE').toLowerCase(),
          { toastClass: 'toast ngx-toastr', closeButton: false, timeOut: 12000, progressBar: true }
        );
      },
      onClientAuthorization: (data) => {
        //--- Payment received: Create VPN service entry
        this.orderedService.firstName = this.createAccountForm.get("firstName").value;
        this.orderedService.lastName = this.createAccountForm.get("lastName").value;
        this.orderedService.email = this.createAccountForm.get("email").value;
        this.orderedService.countryID = this.createAccountForm.get("countryID").value;

        this._webService.createNewAccount(this.orderedService, data.id, this.merchantID)
          .subscribe(result => {
            let _success = result.message.includes('successfully');
            let _userName = result.userName;
            let _password = result.password;
            if (_success) {
              setTimeout(() => {
                this._router.navigate(['/' + UrlList.SetupVpnClient + '/' + _userName + '/' + _password])

                this._toastrService.success(
                  this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_SUCCESS.TEXT'),
                  this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_SUCCESS.TITLE'),
                  { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 10000 }
                );
              }, 1000);

              // Log info to FaceBook Pixel
              this.facebookPixel('Purchase', +this.getTotals('TA'));

            } else {
              this._toastrService.error(
                this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TEXT'),
                this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TITLE'),
                { toastClass: 'toast ngx-toastr', closeButton: false }
              );
            }

            //this.buttonsEnabled = true;
            //this.onSelect.emit();
          }, error => {
            this._toastrService.error(
              this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TEXT'),
              this._translateService.instant('COMMON.NOTIFICATION.NEW_ACCOUNT_FAILED.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 10000 }
            );
          });
      },
      onCancel: (data, actions) => {
        //--- User cancel payment request
      },
      onError: err => {
        console.log(err);

        this._toastrService.error(
          this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TEXT'),
          this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TITLE'),
          { toastClass: 'toast ngx-toastr', closeButton: false }
        );
      },
      onClick: (data, actions) => {
        //--- User press Paypal button click
      }     
    };
  }

  formHeader() {
    this.contentHeader = {
      headerTitle: this._translateService.instant('PAYMENT.CONTENT.HEADER.TITLE'),
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('PAYMENT.CONTENT.HEADER.NAME'),
            isLink: true,
            link: '/'
          }
        ]
      }
    };
  }

  getTotals(type) {
    this.discountAmount = this.cryptoinServicePrice?.price * (this.discountPercent / 100);
    let _price: number = this.cryptoinServicePrice?.price - this.discountAmount;
    let _quantity: number = this.cryptoinServicePrice?.quantity;

    if (type == 'T') {
      return !this.cryptoinServicePrice ? 0 : (_quantity * _price).toFixed(2);
    }
    else if (type == 'V') {
      return !this.cryptoinServicePrice ? 0 : ((_quantity * _price) * (this.appSettings.cryptoinVPNCompanyVatPercent / 100)).toFixed(2)
    }
    else if (type == 'TA') {
      return !this.cryptoinServicePrice ? 0 : ((_quantity * _price) * (1 + (this.appSettings.cryptoinVPNCompanyVatPercent / 100))).toFixed(2);
    }
    else if (type == 'PwVAT') {
      return !this.cryptoinServicePrice ? 0 : (_price * (1 + (this.appSettings.cryptoinVPNCompanyVatPercent / 100))).toFixed(2);
    }
    else {
      return 0;
    }
  }

  onSelectPlan(_cryptoinServiceID: number) {
    // Nuresetiname pasirinkimus
    this.priceList.forEach(value => {
      value.selected = false;
    });
    this.priceList.find(({ serviceID }) => serviceID === _cryptoinServiceID).selected = true;
    let _selectedCryptoinServicePrice = this.priceList.find(({ serviceID }) => serviceID === _cryptoinServiceID);
    this.orderedService = <IOrderedServicesNewAccount>{};
    this.orderedService.serviceID = _cryptoinServiceID;
    this.cryptoinServicePrice = _selectedCryptoinServicePrice;

    //-- Refresh date range
    this.dateTo = new Date();
    this.dateTo.setMonth(this.cryptoinServicePrice.quantity + 1);

    //--- Check merchant discounts and update payments systems values
    this.checkDiscount();

    // Log info to FaceBook Pixel
    this.facebookPixel('InitiateCheckout', _selectedCryptoinServicePrice.quantity * _selectedCryptoinServicePrice.discountPrice);
  }

  onPaymentMethodSelect(paymentMethod: string) {
    //--- I�saugojame duomenys, kad po mok�jimo patvirtinimo sukurti account'�
    let cartStorage: ICartStorage = <ICartStorage>{};
    cartStorage.serviceID = window.btoa(this.cryptoinServicePrice.serviceID.toString());
    cartStorage.firstName = window.btoa(encode(this.createAccountForm.get("firstName").value));
    cartStorage.lastName = window.btoa(encode(this.createAccountForm.get("lastName").value));
    cartStorage.email = window.btoa(this.createAccountForm.get("email").value);
    cartStorage.countryID = window.btoa(this.createAccountForm.get("countryID").value);
    cartStorage.merchantID = this.merchantID;
    cartStorage.totalAmount = +this.getTotals('TA');
    localStorage.setItem('__cart_storage__', JSON.stringify(cartStorage));
    //--/

    this.buttonsEnabled = false;
    if (paymentMethod == 'PAYSERA') {
      //--- Show message that process is in progress ---
      this._toastrService.info(
        this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
        paymentMethod + " payment " + this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE').toLowerCase(),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );

      let orderedService = <IOrderedServices>{};
      orderedService.serviceID = this.orderedService.serviceID;
      orderedService.totalPaid = +this.getTotals('TA');
      this._paymentService.payseraPayment(this.createAccountForm.get("email").value, orderedService, false)
        .subscribe(result => {
          window.open(result, '_self');
          this.buttonsEnabled = true;
          this.onSelect.emit();
        });
    } else if (paymentMethod == 'CRYPTO') {
      //--- Show message that process is in progress ---
      this._toastrService.info(
        this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
        paymentMethod + " payment " + this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE').toLowerCase(),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );

      let orderedService = <IOrderedServicesNewAccount>{};
      orderedService.serviceID = this.orderedService.serviceID;
      orderedService.firstName = this.createAccountForm.get("firstName").value;
      orderedService.lastName = this.createAccountForm.get("lastName").value;
      orderedService.email = this.createAccountForm.get("email").value;
      orderedService.countryID = this.createAccountForm.get("countryID").value;
      orderedService.totalPaid = +this.getTotals('TA');
      this._paymentService.cryptoPayment(orderedService, false)
        .subscribe(result => {
          localStorage.setItem('__crypto_storage__', result.id);
          window.open(result.url, '_self');
          this.buttonsEnabled = true;
          this.onSelect.emit();
        });
    } else if (paymentMethod == 'STRIPE') {
      //--- Show message that process is in progress ---
      this._toastrService.info(
        this._translateService.instant('COMMON.NOTIFICATION.PAYMENTS_METHOD_LOADING.TEXT'),
        this._translateService.instant('COMMON.NOTIFICATION.PAYMENTS_METHOD_LOADING.TITLE'),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );

      this.stripePaymentSelected = true;
      this.createPaymentIntent();
    }
  }

  createPaymentIntent() {
    let paymentRequest = <IStripePaymentRequest>{};
    paymentRequest.amount = +this.getTotals('TA');
    paymentRequest.currency = this.appSettings.cryptoinVPNCurrency;

    this._paymentService.stripeCreatePaymentIntent(paymentRequest, false)
      .subscribe(result => {
        let clientSecret = result.clientSecret;
        let paymentIntent = result.paymentIntent;

        this.stripe.then(res => {
          let elements = res.elements({ clientSecret });

          let paymentElement = elements.create('payment', {            
            layout: {
              type: 'accordion',
              defaultCollapsed: false,
              radios: false,
              spacedAccordionItems: true
            },
            paymentMethodOrder: ['card', 'google_pay', 'apple_pay', 'alipay']
          });
          const pElement = document.getElementById("payment-element");
          paymentElement.mount(pElement);

          const form = document.getElementById("payment-form");
          form.addEventListener('submit', (e) => {
            e.preventDefault();
            this.processPaymentStatus = true;

            this._toastrService.info(
              this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
              "Payment " + this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE').toLowerCase(),
              { toastClass: 'toast ngx-toastr', closeButton: false }
            );

            res.confirmPayment({
              elements,
              confirmParams: {
                return_url: this._paymentService.baseUrl + 'stripe-payment-accept?_l=n'
              }
            }).then(({ error }) => {
              this.processPaymentStatus = false;
              this._toastrService.error(
                this._translateService.instant('COMMON.NOTIFICATION.PAYMENT_FAILED.TEXT') + ' ' + error.message,
                this._translateService.instant('COMMON.NOTIFICATION.PAYMENT_FAILED.TITLE'),
                { toastClass: 'toast ngx-toastr', closeButton: false }
              );
            });
          });

          form.addEventListener('reset', (e) => {
            e.preventDefault();
            this.stripePaymentSelected = false;
            this.buttonsEnabled = true;

            this._paymentService.stripeCancelPaymentIntent(paymentIntent, false).subscribe(res => {
              if (res) {
                this._toastrService.info(
                  this._translateService.instant('COMMON.NOTIFICATION.CANCELED_SUCCESS.TEXT'),
                  this._translateService.instant('COMMON.NOTIFICATION.CANCELED_SUCCESS.TITLE'),
                  { toastClass: 'toast ngx-toastr', closeButton: false }
                );
              }
            })
          });
        });
      });
  }

  onPaymentCancel() {
    this.onCancel.emit();
  }

  login() {
    let cartStorage: ICartStorage = <ICartStorage>{};
    cartStorage.serviceID = window.btoa(this.cryptoinServicePrice.serviceID.toString());
    localStorage.setItem('__cart_storage__', JSON.stringify(cartStorage));
    this._router.navigate(['/login']);
  }

  checkEmailAddress() {
    if (!this.createAccountForm.get("email").errors) {
      this._webService.checkEmailAddress(
        this.createAccountForm.get("email").value)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
          this.emailFound = result;
        });
    }
  }

  checkDiscount() {
    this._commonService.getCryptoinServicesDiscount(
      this.cryptoinServicePrice?.serviceID,
      this.merchantID == null ? null : this.merchantEmail)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.discountPercent = result;

        //--- Upadate payments systems values
        this.initPaypal();
      });
  }

  onConditionsOpen(conditionsDialog, conditionType) {
    if (conditionType == KeyName.CryptoinVPNTermsOfUse) {
      this.conditionHeader = this._translateService.instant('WEB.TERM_OF_USE.HEADER');
      this.conditionBody = this.termsOfUse;
    }
    else if (conditionType == KeyName.CryptoinVPNPrivacyPolicy) {
      this.conditionHeader = this._translateService.instant('WEB.PRIVACY_POLICY.HEADER');
      this.conditionBody = this.privacyPolicy;
    }

    this._modalService.open(conditionsDialog, {
      centered: true,
      size: 'xl',
      windowClass: 'modal modal-primary'
    });
  }

  facebookPixel(eventName: string, totalAmount: number) {
    (function (f: any, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }; if (!f._fbq) f._fbq = n;
      n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    (window as any).fbq('track', eventName, { value: totalAmount.toFixed(2), currency: 'EUR' });
  }
}
