<div class="navbar navbar-shadow fixed-top navbar-light navbar-shadow" *ngIf="buyNowLoad">
  <div class="col-xxl-2 col-sm-1"></div>

  <div class="col-xxl-8 col-sm-10 col-12 px-0">
    <div class="d-flex flex-md-row flex-column">
      <div class="d-flex col-12 align-content-center align-items-center justify-content-center pl-0">
        <a href="{{ 'CRYPTOIN.CRYPTOINVPN_URL' | translate }}" class="row">
          <div>
            <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="40" />
          </div>
          <div>
            <span class="font-large-1 font-weight-bolder text-primary ml-1">{{ coreConfig.app.appName }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="col-xxl-2 col-sm-1"></div>
</div>

<div class="content-wrapper container-xxl p-0">
  <div class="content-body mb-md-5 pb-md-5">
    <div id="startingPoint"></div>

    <div class="col-xl-12 col-md-12 col-12 pl-md-0" *ngIf="!buyNowLoad">
      <a class="btn btn-primary mt-md-5 mt-2 mb-2 btn-sm-block" (click)="onPaymentCancel()" rippleEffect>
        <i data-feather="arrow-left-circle" class="mr-50"></i>
        {{ 'COMMON.ACTION.BACK' | translate }}
      </a>
    </div>

    <div class="navbar-horizontal col-xl-12 col-md-12 col-12 pl-0">
      <div class="row invoice-edit">
        <!-- Receipt -->
        <div class="d-flex flex-column col-xxl-7 col-12 px-xxl-1 pl-1 pr-0">
          <div class="text-center mb-1 d-flex flex-md-row flex-column align-items-center justify-content-center">
            <div class="font-medium-5 font-weight-bold pr-25 {{ buyNowLoad ? 'mt-5' : '' }}">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.TITLE' | translate }}</div>
            <div class="d-flex flex-md-row flex-column align-items-center" *ngIf="!buyNowLoad">
              <div class="font-medium-3 font-weight-normal pb-md-0 pb-25 pl-25 pr-50">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.OR' | translate }}</div>
              <a (click)="login()" class="btn btn-outline-primary font-medium-4 font-weight-bolder text-primary">
                <i size="17" data-feather="log-in" class="pr-25"></i>
                {{ 'WEB.LANDING_PAGE.ACTION.BUTTON_LOGIN' | translate }}
              </a>
              <span class="font-small-2 font-weight-bold pt-md-25 pt-50 pl-50 text-muted">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.HAVE_ACCOUNT' | translate }}</span>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-md-row flex-column mt-0 mb-0">
                <div class="col-12 pr-md-1 p-0 m-0">
                  <form [formGroup]="createAccountForm">
                    <!-- Create Account -->
                    <div class="col-12 text-left pl-0 pr-0">
                      <div class="form-group row">
                        <div class="col-6 pl-1 pr-25 mt-md-50">
                          <input type="text"
                                 formControlName="firstName"
                                 class="form-control"
                                 name="firstName"
                                 (focus)="checkEmailAddress()"
                                 [ngClass]="{ 'is-invalid': f.firstName.touched && f.firstName.errors }"
                                 placeholder="{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.FIRST_NAME' | translate }}" />
                          <div *ngIf="f.firstName.touched && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.FIRST_NAME' | translate }}!</div>
                          </div>
                        </div>
                        <div class="col-6 pr-md-0 pr-1 mt-md-50">
                          <input type="text"
                                 formControlName="lastName"
                                 class="form-control"
                                 name="lastName"
                                 (focus)="checkEmailAddress()"
                                 [ngClass]="{ 'is-invalid': f.lastName.touched && f.lastName.errors }"
                                 placeholder="{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.LAST_NAME' | translate }}" />
                          <div *ngIf="f.lastName.touched && f.lastName.errors" class="invalid-feedback">
                            <div *ngIf="f.lastName.errors.required">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.LAST_NAME' | translate }}!</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-6 pl-1 pr-25 mb-md-25">
                          <input type="text"
                                 formControlName="email"
                                 class="form-control"
                                 name="email"
                                 (focus)="checkEmailAddress()"
                                 (focusout)="checkEmailAddress()"
                                 [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }"
                                 placeholder="{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.EMAIL' | translate }}" />
                          <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.EMAIL' | translate }}!</div>
                            <div *ngIf="f.email.errors.email">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_ERROR2' | translate }}</div>
                          </div>
                        </div>
                        <div class="col-6 pr-md-0 pr-1 mb-md-25">
                          <ng-select formControlName="countryID"
                                     [clearable]="false"
                                     [items]="countriesList"
                                     bindLabel="fullName"
                                     bindValue="keyID"
                                     (change)="onChangeCountry($event.keyID)"
                                     [ngClass]="{ 'is-invalid': f.countryID.touched && f.countryID.errors }"
                                     placeholder="{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.COUNTRY' | translate }}">
                          </ng-select>
                          <div *ngIf="f.countryID.touched && f.countryID.errors" class="invalid-feedback">
                            <div *ngIf="f.countryID.errors.required">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.COUNTRY' | translate }}!</div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-1 mb-25">
                        <span class="font-small-2" [innerHTML]="commentText"></span>
                      </div>
                      <div class="">
                        <span class="font-small-2 mr-25" [innerHTML]="termsPrivacyText1"></span>
                        <a (click)="onConditionsOpen(conditionsDialog, 'CryptoinVPNTermsOfUse')"
                           class="font-small-3 mr-25 font-weight-bolder text-primary"
                           [innerHTML]="termsPrivacyText2">
                        </a>
                        <span class="font-small-2 mr-25" [innerHTML]="termsPrivacyText3"></span>
                        <a (click)="onConditionsOpen(conditionsDialog, 'CryptoinVPNPrivacyPolicy')"
                           class="font-small-3 font-weight-bolder text-primary"
                           [innerHTML]="termsPrivacyText4">
                        </a>
                      </div>
                    </div>
                    <!--/ Create Account -->
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mb-1">
            <span class="font-medium-5 font-weight-bold">{{ 'PAYMENT.ORDER_SUMMARY' | translate }}</span>
          </div>

          <div class="card">
            <div class="card-body px-1 pt-1 pb-0">
              <div class="d-flex justify-content-between invoice-spacing mt-0 mb-0">
                <div class="col-4 d-flex flex-column justify-content-center align-items-center mr-1">
                  <div class="mt-1">
                    <span class="brand-logo">
                      <img height="60" [src]="cryptoinLogo" alt="brand-logo" />
                    </span>
                  </div>
                  <div class="logo-wrapper mt-50">
                    <span class="text-primary font-medium-2 font-weight-bolder text-center">{{ appSettings.cryptoinVPNCompanyName }}</span>
                  </div>
                </div>

                <!-- Sellers Address and Contact starts -->
                <div class="col-8 align-items-center text-right justify-content-center pr-2 pb-1">
                  <h6 class="mb-50 font-italic text-muted">{{ 'PAYMENT.SELLER.TITLE' | translate }}</h6>
                  <p class="card-text mb-25">
                    <span class="p-0">{{ 'PAYMENT.SELLER.COMPANY_CODE' | translate }}{{ appSettings.cryptoinVPNCompanyCode }}</span>
                    <span class="card-text" *ngIf="appSettings.cryptoinVPNCompanyVatCode!='' && appSettings.cryptoinVPNCompanyVatCode!=null">,</span>
                    <span class="card-text ml-25" *ngIf="appSettings.cryptoinVPNCompanyVatCode!='' && appSettings.cryptoinVPNCompanyVatCode!=null">
                      {{ 'PAYMENT.SELLER.VAT_CODE' | translate }}{{ appSettings.cryptoinVPNCompanyVatCode }}
                    </span>
                  </p>
                  <p class="card-text mb-25">{{ appSettings.cryptoinVPNCompanyFullAddress }}, {{ appSettings.cryptoinVPNCountry }}</p>
                  <p class="card-text mb-0">{{ appSettings.cryptoinVPNCompanyEmail }}</p>
                </div>
                <!-- Sellers Address and Contact ends -->
              </div>
            </div>

            <hr class="invoice-spacing mt-0 mb-50" />

            <div class="row pricing-card mt-2 mx-50" *ngIf="buyNowLoad">
              <div class="col-12 mx-auto">
                <div class="row">
                  <div class="col-12 col-md-4" *ngFor="let price of priceList; let i = index">
                    <div class="card standard-pricing popular text-center">
                      <div class="card-body">
                        <div class="pricing-badge text-right {{ (price.markingComment!='' && price.markingComment!=null) ? '': 'mb-2' }}">
                          <div class="badge badge-pill badge-light-primary">{{ price.markingComment }}</div>
                        </div>
                        <h3>{{ price.serviceName }}</h3>
                        <p class="card-text">{{ price.description }}</p>
                        <div class="annual-plan">
                          <div class="plan-price mt-2">
                            <sup class="font-medium-1 font-weight-bold text-primary">{{ 'COMMON.CURRENCY_SYMBOL' | translate }} </sup>
                            <span class="pricing-basic-value font-weight-bolder text-primary">{{ price.discountPrice | number: '1.2-2' }}</span>
                            <sub class="pricing-duration text-body font-medium-1 font-weight-bold"> /{{ price.measure }}</sub>
                          </div>
                          <div class="plan-price mt-50" *ngIf="price.quantity!=0">
                            <sub class="pricing-duration text-success font-medium-1 font-weight-bold">{{ 'SERVICES.BUY.PERIOD_1' | translate }}{{ price.quantity + ' ' + price.measure }}{{ 'SERVICES.BUY.PERIOD_2' | translate }}</sub>
                          </div>
                        </div>
                        <button [disabled]="!buttonsEnabled"
                                (click)="onSelectPlan(price.serviceID)"
                                class="btn btn-block {{ price.selected ? 'btn-primary' : 'btn-outline-primary' }} mt-2"
                                rippleEffect>
                          <span *ngIf="price.selected">{{ 'COMMON.ACTION.SELECTED' | translate }}</span>
                          <span *ngIf="!price.selected">{{ 'COMMON.ACTION.SELECT' | translate }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr class="invoice-spacing mt-0 mb-50" *ngIf="buyNowLoad" />

            <div class="card-body pb-0">
              <div class="d-flex flex-column">
                <div class="align-items-center mr-1">
                  <p class="font-medium-2 text-{{ cryptoinServicePrice?.colorCode }} font-weight-bold mb-25" [ngbTooltip]="tipContent" container="body">
                    {{ cryptoinServicePrice?.serviceName }}
                    <i class="ml-25 feather font-small-4 text-dark icon-info"></i>
                  </p>
                  <p class="card-text text-nowrap">
                    <span class="font-medium-1 font-weight-bolder text-primary">
                      <span>
                        <i data-feather="calendar" class="mr-25" size="17"></i>
                      </span>
                      <span class="font-small-4 font-weight-normal text-primary">{{ 'PAYMENT.DATE_FROM' | translate }}</span>
                      {{ dateFrom | date:'yyyy-MM-dd' }}
                      <span class="font-small-4 font-weight-normal text-primary">{{ 'PAYMENT.DATE_TO' | translate }}</span>
                      {{ dateTo | date:'yyyy-MM-dd' }}
                    </span>
                  </p>
                  <ng-template #tipContent>
                    <div [innerHTML]="cryptoinServicePrice?.comments"></div>
                  </ng-template>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="col-7 justify-content-center align-items-center"></div>
                  <div class="col-5 align-items-center text-right justify-content-center">
                    <h6 class="mb-50 text-muted">{{ 'PAYMENT.AMOUNT' | translate }}</h6>
                  </div>
                </div>

                <hr class="invoice-spacing mt-0 mb-75" />

                <div class="d-flex justify-content-between font-medium-1">
                  <div class="col-7 align-items-center mb-75 pl-0">
                    <span class="font-weight-bold">{{ cryptoinServicePrice?.quantity }}</span>
                    <span> {{ cryptoinServicePrice?.measure }} </span>
                    x
                    <span class="font-weight-bold">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ cryptoinServicePrice?.price }}</span>
                  </div>
                  <div class="col-5 align-items-center text-right justify-content-center">
                    <span class="font-weight-bolder font-medium-2 text-primary">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ (cryptoinServicePrice?.quantity * cryptoinServicePrice?.price).toFixed(2) }}</span>
                  </div>
                </div>

                <div class="d-flex justify-content-between font-medium-1" *ngIf="discountPercent>0">
                  <div class="col-7 align-items-center mb-75 pl-0">
                    <div class="font-weight-normal font-small-4 text-danger">{{ 'COMMON.FORM.DISCOUNT' | translate }} {{ discountPercent + '%' }}</div>
                  </div>
                  <div class="col-5 align-items-center text-right justify-content-center">
                    <div class="font-weight-bold font-medium-2 text-danger">-{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ (cryptoinServicePrice?.quantity * discountAmount).toFixed(2) }}</div>
                  </div>
                </div>

                <hr class="invoice-spacing mt-0 mb-75" />

                <div class="d-flex justify-content-between">
                  <div class="col-3 d-flex flex-column align-self-end pl-0 mb-2">
                    <section *ngIf="merchantID!=null">
                      <span class="font-medium-1 font-weight-bold text-muted pb-25">
                        {{ 'CRYPTOIN.MERCHANT.TITLE' | translate }}
                        <span ngbTooltip="{{ 'CRYPTOIN.MERCHANT.ID_TOOLTIP' | translate }}" container="body">
                          <i class="feather font-medium-1 text-dark icon-info"></i>
                        </span>
                      </span>
                      <span class="col-8 pl-0 pr-0">
                        <input type="text"
                               class="form-control"
                               [disabled]="true"
                               name="merchantID"
                               (focusout)="checkDiscount()"
                               [(ngModel)]="merchantID" [ngModelOptions]="{standalone: true}"
                               placeholder="{{ 'CRYPTOIN.MERCHANT.ID_PLACEHOLDER' | translate }}" />
                      </span>
                    </section>
                  </div>
                  <div class="col-9 align-items-center d-flex justify-content-end mb-1 pb-25">
                    <!-- Total -->
                    <div class="d-flex flex-column pr-0">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="font-weight-normal font-small-4">{{ 'COMMON.FORM.TOTAL' | translate }}</div>
                        <div class="font-weight-bold font-medium-2">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('T') }}</div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between mt-25">
                        <div class="font-weight-normal font-small-4">{{ 'COMMON.FORM.VAT' | translate }} {{ appSettings.cryptoinVPNCompanyVatPercent + '%' }}</div>
                        <div class="font-weight-bold font-small-4">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('V') }}</div>
                      </div>
                      <hr class="invoice-spacing mt-50 mb-25" />
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="font-weight-normal font-small-3 text-muted mb-0 mt-0">{{ 'COMMON.FORM.TAX_COUNTRY' | translate  }}</div>
                        <div class="font-weight-bold font-small-3 text-muted mb-0 mt-0">{{ countryName }}</div>
                      </div>
                      <hr class="invoice-spacing mt-25 mb-50" />
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="font-weight-normal font-small-4">{{ 'COMMON.FORM.TOTAL_AMOUNT' | translate }}</div>
                        <div class="ml-1 font-weight-bold font-medium-5 text-{{ cryptoinServicePrice?.colorCode }}">{{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}</div>
                      </div>
                    </div>
                    <!--/ Total -->
                  </div>
                </div>
              </div>
            </div>
            <!--/ Receipt  -->
          </div>
        </div>

        <!-- Payment Methods -->
        <div class="col-xxl-5 col-12 invoice-actions {{ buyNowLoad ? 'mt-5' : 'mt-75' }} pr-0">
          <div class="text-center mb-1 pb-25">
            <span class="font-medium-5 font-weight-bold">{{ 'PAYMENT.PAYMENT_METHOD' | translate }}</span>
          </div>
          <div class="card collapse-icon">
            <ngb-accordion [closeOthers]="true" activeIds="ngb-panel-0">
              <!-- Paypal / Credit card payment -->
              <ngb-panel [disabled]="stripePaymentSelected" *ngIf="appSettings.paypalGatewayEnabled==1">
                <ng-template ngbPanelTitle>
                  <span class="lead collapse-title">
                    <img src="{{ _environment.picsPath }}/payment/paypal.png" class="ml-1" />
                    <img src="{{ _environment.picsPath }}/payment/creditcard.png" class="ml-1 pt-50" />
                  </span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="mb-1">
                    <div class="">
                      <div class="row">
                        <div class="col-12">
                          <div class="col-12 pl-2">
                            <div class="font-small-3 text-muted mb-1" [innerHTML]="paypalText"></div>
                            <div class="col-12 pt-25 pl-0">
                              <section *ngIf="!emailFound && f.firstName.value!='' && f.lastName.value!='' && f.email.value!='' && f.countryID.value>0 && !f.email.errors?.email && buttonsEnabled  && getTotals('TA')!=0">
                                <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                              </section>
                              <section *ngIf="f.firstName.value=='' || f.lastName.value=='' || f.email.value=='' || f.countryID.value <= 0">
                                <div class="alert alert-warning mb-1" role="alert">
                                  <h4 class="alert-heading">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.NOTIFICATION.PAYMENT_SELECT.TEXT' | translate }}</h4>
                                </div>
                              </section>
                              <section *ngIf="f.firstName.value!='' && f.lastName.value!='' && f.email.value!='' && f.countryID.value>0 && f.email.errors?.email">
                                <div class="alert alert-danger mb-50" role="alert">
                                  <h4 class="alert-heading">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_ERROR2' | translate }}</h4>
                                </div>
                              </section>
                              <section *ngIf="emailFound">
                                <div class="alert alert-danger mb-50" role="alert">
                                  <h4 class="alert-heading">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.NOTIFICATION.EMAIL_FOUND.TEXT' | translate }}</h4>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!--/ Paypal / Credit card payment -->
              <!-- Stripe payment -->
              <ngb-panel *ngIf="appSettings.stripeGatewayEnabled==1">
                <ng-template ngbPanelTitle>
                  <span class="lead collapse-title">
                    <img src="{{ _environment.picsPath }}/payment/stripe.png" class="ml-2" />
                  </span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card-body collapse-icon pt-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="col-12 pl-2">
                          <div class="font-small-3 text-muted" [innerHTML]="stripeText"></div>
                          <div class="col-12 pt-25 pl-0">
                            <section *ngIf="!emailFound && f.firstName.value!='' && f.lastName.value!='' && f.email.value!='' && f.countryID.value>0 && !f.email.errors?.email && !stripePaymentSelected">
                              <button [disabled]="!buttonsEnabled || getTotals('TA')==0"
                                      (click)="onPaymentMethodSelect('STRIPE')"
                                      class="btn btn-block btn-primary mt-2"
                                      rippleEffect>
                                <i data-feather="credit-card" class="mr-50"></i>
                                {{ 'PAYMENT.STRIPE.MAKE_PAYMENT' | translate }}
                                {{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}
                              </button>
                            </section>

                            <section *ngIf="stripePaymentSelected">
                              <form id="payment-form" class="mt-1">
                                <div id="payment-element"></div>
                                <button type="submit"
                                        [disabled]="processPaymentStatus"
                                        class="btn btn-block btn-primary mt-2"
                                        rippleEffect>
                                  <span class="spinner-border spinner-border-sm mr-50" *ngIf="processPaymentStatus"></span>
                                  <span *ngIf="!processPaymentStatus"><i data-feather="credit-card" class="mr-50"></i></span>
                                  {{ 'PAYMENT.STRIPE.COMPLETE_PURCHASE' | translate }}
                                  {{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}
                                </button>
                                <button type="reset"
                                        [disabled]="processPaymentStatus"
                                        class="btn btn-block btn-outline-secondary mt-2"
                                        rippleEffect>
                                  <i data-feather="x-circle" class="mr-50"></i>
                                  {{ 'COMMON.ACTION.CANCEL_PURCHASE' | translate }}
                                </button>
                              </form>
                            </section>

                            <section *ngIf="f.firstName.value=='' || f.lastName.value=='' || f.email.value=='' || f.countryID.value <= 0">
                              <div class="alert alert-warning mt-1 mb-50" role="alert">
                                <h4 class="alert-heading">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.NOTIFICATION.PAYMENT_SELECT.TEXT' | translate }}</h4>
                              </div>
                            </section>
                            <section *ngIf="f.firstName.value!='' && f.lastName.value!='' && f.email.value!='' && f.countryID.value>0 && f.email.errors?.email">
                              <div class="alert alert-danger mt-1 mb-50" role="alert">
                                <h4 class="alert-heading">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_ERROR2' | translate }}</h4>
                              </div>
                            </section>
                            <section *ngIf="emailFound">
                              <div class="alert alert-danger mt-1 mb-50" role="alert">
                                <h4 class="alert-heading">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.NOTIFICATION.EMAIL_FOUND.TEXT' | translate }}</h4>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!--/ Stripe payment -->
              <!-- Paysera payment -->
              <ngb-panel [disabled]="stripePaymentSelected" *ngIf="appSettings.payseraGatewayEnabled==1">
                <ng-template ngbPanelTitle>
                  <span class="lead collapse-title">
                    <img src="{{ _environment.picsPath }}/payment/paysera.png" class="ml-2" />
                  </span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card-body collapse-icon pt-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="col-12 pl-2">
                          <div class="font-small-3 text-muted" [innerHTML]="payseraText"></div>
                          <div class="col-12 pt-25 pl-0">
                            <section *ngIf="!emailFound && f.firstName.value!='' && f.lastName.value!='' && f.email.value!='' && f.countryID.value>0 && !f.email.errors?.email">
                              <button [disabled]="!buttonsEnabled || getTotals('TA')==0"
                                      (click)="onPaymentMethodSelect('PAYSERA')"
                                      class="btn btn-block btn-primary mt-2"
                                      rippleEffect>
                                <span class="spinner-border spinner-border-sm mr-50" *ngIf="processPaymentStatus"></span>
                                <span *ngIf="!processPaymentStatus"><i data-feather="credit-card" class="mr-50"></i></span>
                                {{ 'PAYMENT.PAYSERA.MAKE_PAYMENT' | translate }}
                                {{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}
                              </button>
                            </section>
                            <section *ngIf="f.firstName.value=='' || f.lastName.value=='' || f.email.value=='' || f.countryID.value <= 0">
                              <div class="alert alert-warning mt-1 mb-50" role="alert">
                                <h4 class="alert-heading">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.NOTIFICATION.PAYMENT_SELECT.TEXT' | translate }}</h4>
                              </div>
                            </section>
                            <section *ngIf="f.firstName.value!='' && f.lastName.value!='' && f.email.value!='' && f.countryID.value>0 && f.email.errors?.email">
                              <div class="alert alert-danger mt-1 mb-50" role="alert">
                                <h4 class="alert-heading">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_ERROR2' | translate }}</h4>
                              </div>
                            </section>
                            <section *ngIf="emailFound">
                              <div class="alert alert-danger mt-1 mb-50" role="alert">
                                <h4 class="alert-heading">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.NOTIFICATION.EMAIL_FOUND.TEXT' | translate }}</h4>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!--/ Paysera payment -->
              <!-- Crypto payment -->
              <ngb-panel [disabled]="stripePaymentSelected" *ngIf="appSettings.bitpayGatewayEnabled==1">
                <ng-template ngbPanelTitle>
                  <span class="lead collapse-title">
                    <img src="{{ _environment.picsPath }}/payment/bitpay.png" class="ml-1" />
                    <img src="{{ _environment.picsPath }}/payment/crypto.png" class="ml-1" />
                  </span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card-body collapse-icon pt-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="col-12 pl-2">
                          <div class="font-small-3 text-muted" [innerHTML]="cryptoText"></div>
                          <div class="col-12 pt-25 pl-0">
                            <section *ngIf="!emailFound && f.firstName.value!='' && f.lastName.value!='' && f.email.value!='' && f.countryID.value>0 && !f.email.errors?.email">
                              <button [disabled]="!buttonsEnabled || getTotals('TA')==0"
                                      (click)="onPaymentMethodSelect('CRYPTO')"
                                      class="btn btn-block btn-primary mt-2"
                                      rippleEffect>
                                <span class="spinner-border spinner-border-sm mr-50" *ngIf="processPaymentStatus"></span>
                                <span *ngIf="!processPaymentStatus"><i data-feather="credit-card" class="mr-50"></i></span>
                                {{ 'PAYMENT.CRYPTO.MAKE_PAYMENT' | translate }}
                                {{ 'COMMON.CURRENCY_SYMBOL' | translate }}{{ getTotals('TA') }}
                              </button>
                            </section>
                            <section *ngIf="f.firstName.value=='' || f.lastName.value=='' || f.email.value=='' || f.countryID.value <= 0">
                              <div class="alert alert-warning mt-1 mb-50" role="alert">
                                <h4 class="alert-heading">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.NOTIFICATION.PAYMENT_SELECT.TEXT' | translate }}</h4>
                              </div>
                            </section>
                            <section *ngIf="f.firstName.value!='' && f.lastName.value!='' && f.email.value!='' && f.countryID.value>0 && f.email.errors?.email">
                              <div class="alert alert-danger mt-1 mb-50" role="alert">
                                <h4 class="alert-heading">{{ 'ACCOUNT.DIALOG.LOGIN.USER_NAME_ERROR2' | translate }}</h4>
                              </div>
                            </section>
                            <section *ngIf="emailFound">
                              <div class="alert alert-danger mt-1 mb-50" role="alert">
                                <h4 class="alert-heading">{{ 'WEB.PAYMENT_PAGE.CREATE_ACCOUNT.NOTIFICATION.EMAIL_FOUND.TEXT' | translate }}</h4>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!--/ Crypto payment -->
            </ngb-accordion>
          </div>
        </div>
        <!--/Payment Method  -->
      </div>
    </div>
  </div>
</div>

<ng-template #conditionsDialog let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="conditionsDialog">{{ conditionHeader }}</h5>
  </div>
  <div class="modal-body text-justify" tabindex="0" ngbAutofocus [innerHTML]="conditionBody"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()" rippleEffect>
      {{ 'WEB.ACTION.ACCEPT' | translate }}
    </button>
  </div>
</ng-template>