import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from '../../_i18n/en';
import { environment } from 'environments/environment';
import { FAQTitlesList, FAQList } from '../../help/help.data';
import { HelpService } from '../../help/help.service';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit, OnDestroy {
  public contentHeader: object;
  public faqTitles: FAQTitlesList[];
  public faqData: FAQList[];
  public searchText: string;
  public selectedTitle: string = "";

  _environment = environment;
  bgBanner: string = "url('" + environment.picsPath + "banner.png')";

  _unsubscribeAll: Subject<any>;

  constructor(
    private _helpService: HelpService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService
  ) {
    this._coreTranslationService.translate(en);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._helpService.getFAQTitles<FAQTitlesList>().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
      this.faqTitles = result;

      this._helpService.getFAQ<FAQList>(null).pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
        this.faqData = result;
      });
    });
  
    this.contentHeader = {
      headerTitle: this._translateService.instant('HELP.FAQ.CONTENT.HEADER.TITLE'),
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('HELP.FAQ.CONTENT.HEADER.NAME'),
            isLink: false
          }
        ]
      }
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onTitleSelect(title) {
    this.selectedTitle = title;
  }
}
