import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { IOrderedServices, IOrderedServicesNewAccount } from '../service/service.data';
import { IStripePaymentRequest } from '../common/common.data';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public _currentUser: User;
  public entryData = new BehaviorSubject<any>([]);

  //-- Paimame sisteminės įmonės kodą iš localstorage, nes jis naudojamas indentifikuojant įmonę connection DB
  systemCompanyCode: string = localStorage.getItem('ccode');

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.currentUser.subscribe(x => (this._currentUser = x));
  }

  paypalPayment(transactionID: string, currentOrder: IOrderedServices): Observable<boolean> {
    var url = this.baseUrl + 'api/Paypal/PaymentAuthorized';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("transactionID", transactionID);
    return this.http.post<boolean>(url, currentOrder, { headers, params });
  }

  stripePayment(transactionID: string, currentOrder: IOrderedServices, loggedIn: boolean): Observable<boolean> {
    var url = this.baseUrl + 'api/Stripe/PaymentAuthorized';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("transactionID", transactionID);
    if (loggedIn)
      return this.http.post<boolean>(url, currentOrder, { headers, params });
    else
      return this.http.post<boolean>(url, currentOrder, { params });
  }

  payseraPayment(employeeEmail: string, currentOrder: IOrderedServices, loggedIn: boolean): Observable<any> {
    var url = this.baseUrl + 'api/Paysera/MakePayment';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("employeeEmail", employeeEmail);
    if (loggedIn) {
      params = params.set("logged", "y");
      return this.http.post<any>(url, currentOrder, { headers, params });
    }
    else {
      params = params.set("logged", "n");
      return this.http.post<any>(url, currentOrder, { params });
    }
  }

  cryptoPayment(currentOrder: IOrderedServicesNewAccount, loggedIn: boolean): Observable<any> {
    var url = this.baseUrl + 'api/Crypto/MakePayment';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams();
    if (loggedIn) {
      params = params.set("logged", "y");
      return this.http.post<any>(url, currentOrder, { headers, params });
    }
    else {
      params = params.set("logged", "n");
      return this.http.post<any>(url, currentOrder, { params });
    }
  }

  stripeCreatePaymentIntent(payment: IStripePaymentRequest, loggedIn: boolean): Observable<any> {
    var url = this.baseUrl + 'api/Stripe/MakePayment';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    if (loggedIn)
      return this.http.post<any>(url, payment, { headers });
    else
      return this.http.post<any>(url, payment);
  }

  stripeCancelPaymentIntent(paymentIntent: string, loggedIn: boolean): Observable<boolean> {
    var url = this.baseUrl + 'api/Stripe/PaymentCanceled';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("paymentIntent", paymentIntent);
    if (loggedIn)
      return this.http.get<boolean>(url, { headers, params });
    else
      return this.http.get<boolean>(url, { params });
  }

}
