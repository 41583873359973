import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { ToastrService } from 'ngx-toastr';
import { Login } from 'app/auth/models/login';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'auth-forgot-password',
  templateUrl: './auth-forgot-password.component.html',
  styleUrls: ['./auth-forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthForgotPasswordComponent implements OnInit {
  // Public
  public emailVar;
  public coreConfig: any;
  public forgotPasswordForm: FormGroup;
  public submitted = false;
  linkSend = false;

  public menuLocale = [
    { name: 'en', value: 'en' },
  ];
  public selectedMenuLocale: string = "en";

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   *
   */
  constructor(
    private _toastrService: ToastrService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
  ) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en);

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };

    // Set the translations for the menu
    this._coreTranslationService.translate(en);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgotPasswordForm.controls;
  }

  setDialogLanguage() {
    // Use the selected language id for translations
    this._translateService.use(this.selectedMenuLocale);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this._toastrService.info(
      this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
      this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE'),
      { toastClass: 'toast ngx-toastr', closeButton: false }
    );

    // Išsaugome į localstorage įmonės kodą, kuris naudojamas identifikuojant klientą connection DB
    localStorage.setItem("ccode", window.btoa(this.forgotPasswordForm.get("email").value));

    let _login: Login = <Login>{};
    _login.userName = this.forgotPasswordForm.get("email").value;
    _login.password = "";
    this._authenticationService.sendResetLink(_login).subscribe((res: boolean) => {
      this.linkSend = true;
      if (res) {
        this._toastrService.success("",
          this._translateService.instant('ACCOUNT.DIALOG.FORGOT_PASSWORD.INFO.LINK_SENT'),
          { toastClass: 'toast ngx-toastr', closeButton: false }
        );
      } else {
        this._toastrService.error("",
          this._translateService.instant('ACCOUNT.DIALOG.FORGOT_PASSWORD.INFO.LINK_NOT_SENT'),
          { toastClass: 'toast ngx-toastr', closeButton: false }
        );
      }
      this.forgotPasswordForm.setValue({ email: '', locale: this.selectedMenuLocale });
    });
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      email: [window.atob(localStorage.getItem("ccode")), [Validators.required, Validators.email]],
      locale: ['en']
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
