export const locale = {
  lang: 'en',
  data: {
    MENU: {
      DASHBOARD: 'Home',
      SERVICES: {
        SECTION: 'Services',
        SERVICES: 'Active Services',
        ORDER_NEW: 'New Order',
      },
      HELP: {
        SECTION: 'Help',
        VPN_CLIENTS: 'VPN Clients',
        FAQ: 'FAQ'
      },
      VPN_CLIENTS: {
        SECTION: 'VPN Clients',
        WINDOWS: 'Windows',
        IOS: 'iOS',
        ANDROID: 'Android',
        LINUX: 'Linux'        
      },
      ACCOUNT: {
        SECTION: 'Account',
        MY_PROFILE: 'My Profile',
      },
      LOGOUT: 'Logout'
    }
  }
}
