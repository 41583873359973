import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService, ApiResult } from 'app/base.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { Login } from 'app/auth/models/login';

@Injectable({
  providedIn: 'root',
})

export class AccountService {
  public _currentUser: User;

  //-- Paimame sistemin�s �mon�s kod� i� localstorage, nes jis naudojamas indentifikuojant �mon� connection DB
  systemCompanyCode: string = localStorage.getItem('ccode'); 
    
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.currentUser.subscribe(x => (this._currentUser = x));
  }

  getUserProfile<UserProfile>(clientID: number = -1): Observable<UserProfile> {
    var url = this.baseUrl + "api/Account/UserProfile";
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("clientID", clientID);
    return this.http.get<UserProfile>(url, { headers, params });
  }

  updateUserProfile<UserProfile>(clientID: number = -1, userProfile: UserProfile): Observable<UserProfile> {
    var url = this.baseUrl + "api/Account/UserProfile";
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("clientID", clientID);
    return this.http.put<UserProfile>(url, userProfile, { headers, params });
  }

  sendEmailConfirmationLink(): Observable<boolean> {
    var url = this.baseUrl + "api/ApplicationUser/SendEmailConfirmLink";
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    let payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    let login: Login = <Login>{};
    login.userName = payLoad.userID;
    return this.http.post<boolean>(url, login, { headers });
  }

  confirmEmailAddress(confirmAccessCode: string): Observable<boolean> {
    var url = this.baseUrl + "api/ApplicationUser/ConfirmEmail/" + confirmAccessCode;
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    return this.http.get<boolean>(url, { headers });
  }

  getClientID(): Observable<number>{
    let payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var url = this.baseUrl + "api/ApplicationUser/GetClientID";
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("userID", payLoad.userID);
    return this.http.get<number>(url, { headers, params });
  }

  checkUserProfileEmail(email: string, clientID: number): Observable<boolean> {
    var url = this.baseUrl + "api/Account/CheckUserProfileEmail";
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("email", email).set("clientID", clientID);
    return this.http.get<boolean>(url, { headers, params });
  }

  checkVATValidation(country: string, vatNumber: string): Observable<any> {
    var url = this.baseUrl + "api/Account/CheckVATValidation";
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("country", country).set("vatNumber", vatNumber);
    return this.http.get(url, { headers, params });
  }
}
