<div class="navbar navbar-shadow fixed-top navbar-light navbar-shadow">
  <div class="col-xxl-2 col-sm-1"></div>

  <div class="col-xxl-8 col-sm-10 col-12 px-0">
    <div class="d-flex flex-md-row flex-column">
      <div class="d-flex col-12 align-content-center align-items-center justify-content-center pl-0">
        <a href="{{ 'CRYPTOIN.CRYPTOINVPN_URL' | translate }}" class="row">
          <div>
            <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="40" />
          </div>
          <div>
            <span class="font-large-1 font-weight-bolder text-primary ml-1">{{ coreConfig.app.appName }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="col-xxl-2 col-sm-1"></div>
</div>

<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="modal-header mt-5">
      <h2 class="modal-title" id="conditionsDialog">{{ conditionHeader }}</h2>
    </div>
    <div class="modal-body text-justify" tabindex="0" ngbAutofocus [innerHTML]="privacyPolicy"></div>
  </div>
</div>
