import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  CommonListEntry,
  CountriesList } from './common.list.data';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  public _currentUser: User;

  //-- Paimame sisteminės įmonės kodą iš localstorage, nes jis naudojamas indentifikuojant įmonę connection DB
  systemCompanyCode: string = localStorage.getItem('ccode');

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.currentUser.subscribe(x => (this._currentUser = x));
  }

  getCountriesList(): Observable<CountriesList[]> {
    var url = this.baseUrl + 'api/CommonLists/CountriesList';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    return this.http.get<CountriesList[]>(url, { headers });
  }
}
