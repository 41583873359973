import { Role } from './role';

export class User {
  id: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  description: string;
  photo: string;
  menuLocale: string;
  role: Role;
}

export interface RegisterUser {
  userName: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  description: string;
  photo: string;
  menuLocale: string;
  role: string;
}

