<!-- Not authorized-->
<div class="misc-wrapper">
  <!-- Brand logo-->
  <a class="brand-logo" href="https://www.virtualusbuhalteris.lt" target="_blank">
    <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="48" />
    <h2 class="brand-text text-primary ml-1 mt-1">{{ coreConfig.app.appName }}</h2>
  </a>
  <!-- /Brand logo-->
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">{{ 'COMMON.NOT_AUTHORIZED_PAGE.TITLE' | translate }}</h2>
      <p class="mb-2">
        {{ 'COMMON.NOT_AUTHORIZED_PAGE.TEXT' | translate }}
      </p>
      <a class="btn btn-primary mb-1 btn-sm-block" routerLink="/pages/authentication/login" rippleEffect>{{ 'COMMON.NOT_AUTHORIZED_PAGE.BUTTON_BACK' | translate }}</a><img class="img-fluid"
                                                                                                                                                                            [src]="
          coreConfig.layout.skin === 'dark'
            ? 'assets/images/pages/not-authorized-dark.svg'
            : 'assets/images/pages/not-authorized.svg'
        "
                                                                                                                                                                            alt="Not authorized page" />
    </div>
  </div>
</div>
<!-- / Not authorized-->
