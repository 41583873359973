import { AuthGuard } from '../../auth/helpers/auth.guards';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreSidebarModule } from '@core/components';
import { Role } from 'app/auth/models';

// Payment systems
import { NgxPayPalModule } from 'ngx-paypal';

import { UrlList } from 'app/constant';
import { PaymentSelectComponent } from './payment-select/payment-select.component';
import { StripePaymentAccept } from './payment-accept/stripe-payment-accept';
import { PayseraPaymentAccept } from './payment-accept/paysera-payment-accept';
import { CryptoPaymentAccept } from './payment-accept/crypto-payment-accept';
import { PaymentService } from './payment.service';

const routes: Routes = [
  {
    path: UrlList.PayseraPaymentAccept + '/:logged',
    component: PayseraPaymentAccept
  },
  {
    path: UrlList.StripePaymentAccept,
    component: StripePaymentAccept
  },
  {
    path: UrlList.CryptoPaymentAccept + '/:logged',
    component: CryptoPaymentAccept
  }
];

@NgModule({
  declarations: [
    PaymentSelectComponent,
    PayseraPaymentAccept,
    StripePaymentAccept,
    CryptoPaymentAccept,
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    CoreCommonModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    Ng2FlatpickrModule,
    ContentHeaderModule,
    CoreSidebarModule,

    //Payment systems
    NgxPayPalModule,
  ],
  exports: [
    PaymentSelectComponent
  ],
  providers: [
    PaymentService
  ]
})
export class PaymentModule {}
