<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section id="faq-search-filter">
      <div class="card faq-search height-200" [ngStyle]="{ 'background-image': bgBanner }">
        <div class="pt-3 text-center mx-md-0 mx-1">
          <h2 class="text-primary">{{ 'HELP.FAQ.MAIN_TITLE' | translate }}</h2>
          <p class="card-text mb-2">{{ 'HELP.FAQ.MAIN_SUBTITLE' | translate }}</p>

          <!-- search input -->
          <form class="faq-search-input">
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i data-feather="search"></i>
                </div>
              </div>
              <input
                type="text"
                [(ngModel)]="searchText"
                name="searchbar"
                class="form-control"
                placeholder="{{ 'HELP.FAQ.SEARCH' | translate }}"
              />
            </div>
          </form>
        </div>
      </div>
    </section>

  <!-- account setting page -->
    <section id="faq">
      <div class="row nav-vertical d-flex">
        <div class="col-md-3 mb-2 mb-md-0">
          <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
            <li class="nav-item" *ngFor="let title of faqTitles; let i = index" ngbNavItem>
              <a ngbNavLink
                 class="nav-link d-flex py-75"
                 id="title{{ i }}"
                 data-toggle="pill"
                 href="#faq-title{{ i }}"
                 aria-expanded="true"
                 role="tab"
                 (click)="onTitleSelect(title.titleName)">
                <i [data-feather]="title.titleFeatherIconName" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">{{ title.titleName }}</span>
              </a>

              <ng-template ngbNavContent>
                <div role="tabpanel"
                     class="tab-pane active collapse-icon"
                     id="faq-title{{ i }}"
                     aria-labelledby="title{{ i }}"
                     aria-expanded="true">
                  <!-- icon and header -->
                  <div class="d-flex align-items-center">
                    <div class="avatar avatar-tag bg-light-primary mr-1">
                      <i [data-feather]="title.titleFeatherIconName" class="font-medium-4"></i>
                    </div>
                    <div>
                      <h4 class="mb-0">{{ title.titleName }}</h4>
                      <span>{{ '' }}</span>
                    </div>
                  </div>

                  <!-- frequent answer and question collapse  -->
                  <div class="accordion collapse-margin mt-2">
                    <ng-container *ngIf="(faqData | filter: searchText:'keywords').length; else noResults">
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="title{{ i }}">
                        <ngb-panel id="faqId{{ i }}"
                                   [cardClass]="'collapse-margin'"
                                   *ngFor="let faq of faqData | filter: searchText:'keywords' | filter: selectedTitle:'titleName'; let i = index">
                          <ng-template ngbPanelTitle>
                            <span>{{ faq.question }}</span>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="mail-message" [innerHTML]="faq.answer"></div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                    </ng-container>
                    <ng-template #noResults>
                      <div class="p-5 text-center">
                        <h5 class="p-1"><i data-feather="info" size="19" class="mr-25"></i> {{ 'HELP.FAQ.NOT_FOUND' | translate }}</h5>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem>
              <a ngbNavLink
                 class="nav-link d-flex py-75"
                 id="faqAll"
                 data-toggle="pill"
                 href="#faq-all"
                 aria-expanded="false"
                 role="tab">
                <i data-feather="book" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">{{ 'HELP.FAQ.ALL' | translate }}</span>
              </a>

              <ng-template ngbNavContent>
                <div role="tabpanel"
                     class="tab-pane active collapse-icon"
                     id="faq-all"
                     aria-labelledby="faqAll"
                     aria-expanded="true">
                  <!-- icon and header -->
                  <div class="d-flex align-items-center">
                    <div class="avatar avatar-tag bg-light-primary mr-1">
                      <i data-feather="book" class="font-medium-4"></i>
                    </div>
                    <div>
                      <h4 class="mb-0">{{ 'HELP.FAQ.ALL' | translate }}</h4>
                      <span>{{ '' }}</span>
                    </div>
                  </div>

                  <!-- frequent answer and question  collapse  -->
                  <div class="accordion collapse-margin mt-2">
                    <ng-container *ngIf="(faqData | filter: searchText:'keywords').length; else noResults">
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="faqId1">
                        <ngb-panel id="faqId{{ i }}"
                                   [cardClass]="'collapse-margin'"
                                   *ngFor="let faq of faqData | filter: searchText:'keywords'; let i = index">
                          <ng-template ngbPanelTitle>
                            <span>{{ faq.question }}</span>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="mail-message" [innerHTML]="faq.answer"></div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                    </ng-container>
                    <ng-template #noResults>
                      <div class="p-5 text-center">
                        <h5 class="p-1"><i data-feather="info" size="19" class="mr-25"></i> {{ 'HELP.FAQ.NOT_FOUND' | translate }}</h5>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </li>
            <!-- FAQ image -->
            <img src="{{ _environment.picsPath}}faq-illustrations.svg"
                 class="img-fluid d-none d-md-block pt-5"
                 alt="demand img" />
          </ul>
        </div>
        <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
      </div>
    </section>
    <!--/ account setting page -->

    <!-- contact us -->
    <section class="faq-contact">
      <div class="row mt-5 pt-75">
        <div class="col-12 text-center">
          <h2>{{ 'HELP.FAQ.HAVE_QUESTION' | translate }}</h2>
          <p class="mb-3">
            {{ 'HELP.FAQ.HAVE_QUESTION_CAPTION' | translate }}
          </p>
        </div>
        <div class="col-sm-12">
          <div class="card text-center faq-contact-card shadow-none py-1">
            <div class="card-body">
              <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                <i data-feather="mail" class="font-medium-3"></i>
              </div>
              <h4>{{ 'HELP.FAQ.CONTACT.EMAIL' | translate }}</h4>
              <span class="text-body">{{ 'HELP.FAQ.CONTACT.EMAIL_CAPTION' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/ contact us -->
  </div>
</div>
