import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { CoreConfigService } from '@core/services/config.service';
import { User, Role } from 'app/auth/models';
import { Login } from 'app/auth/models/login';
import { ToastrService } from 'ngx-toastr';
import { UrlList } from 'app/constant';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private systemCompanyCode: string;
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService, private _router: Router, private _coreConfigService: CoreConfigService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * User login
   *   
   */
  login(login: Login) {
    //-- Paimame i� localstorage el. pa�to adres�
    this.systemCompanyCode = localStorage.getItem('ccode');
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);

    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    return this._http.post(environment.apiUrl + '/api/ApplicationUser/Login', login, { headers })
      .pipe(
        map((res: any) => {
          // login successful if there's a jwt token in the response
          if (res && res.token) {
            // store JWT TOKEN in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', res.token);
            //store USER DETAILS in local storage to keep user logged in between page refreshes
            let payLoad = JSON.parse(window.atob(res.token.split('.')[1]));
            this._http.get(environment.apiUrl + '/api/ApplicationUser/' + payLoad.userID, { headers }).toPromise().then((result: any) => {
              let currentUserData: User = <User>{};
              currentUserData.userName = result.userName;
              currentUserData.firstName = result.firstName;
              currentUserData.lastName = result.lastName;
              currentUserData.description = result.description;
              currentUserData.photo = result.photo;
              currentUserData.menuLocale = result.menuLocale == null ? 'en' : result.menuLocale;

              // U�koduojame Role
              let _er1 = window.btoa(this.systemCompanyCode);
              let _er2 = window.btoa(payLoad.role);
              currentUserData.role = <any>window.btoa(_er1 + _er2);

              localStorage.setItem('currentUser', JSON.stringify(currentUserData));

              this.currentUserSubject.next(currentUserData);

              if (localStorage.getItem("__cart_storage__") != null) {
                this._router.navigate(['/' + UrlList.OrderNow]);
              }
              else {
                this._router.navigate(['/' + UrlList.StartupPage]);
              }
            });
          }
          return res;
        })
      );
  }

  sendResetLink(login: Login) {
    this.systemCompanyCode = localStorage.getItem('ccode');
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var url = environment.apiUrl + '/api/ApplicationUser/SendResetLink';
    return this._http.post(url, login, { headers })
  }

  checkResetAccessCode(resetAccessCode: string): Observable<boolean> {
    this.systemCompanyCode = localStorage.getItem('ccode');
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var url = environment.apiUrl + '/api/ApplicationUser/CheckResetAccessCode/' + resetAccessCode;
    return this._http.get<boolean>(url, { headers });
  }

  resetPassword(resetAccessCode: string, login: Login) {
    this.systemCompanyCode = localStorage.getItem('ccode');
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var url = environment.apiUrl + '/api/ApplicationUser/ResetPassword/' + resetAccessCode;
    return this._http.put(url, login, { headers })
  }

  changePassword(newPassword: Login) {
    this.systemCompanyCode = localStorage.getItem('ccode');
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var url = environment.apiUrl + '/api/ApplicationUser/ChangePassword';
    return this._http.put(url, newPassword, { headers })
  }

  /**
   * Check User role
   *
   */
  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    allowedRoles.forEach(element => {
      if (userRole == element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  /**
   * User logout
   *
   */
  logout() {
    // Pasl�piame menu ir navigation bar
    this._coreConfigService.setConfig(
      { layout: { navbar: { hidden: true }, menu: { hidden: true } } },
      { emitEvent: true }
    );

    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
