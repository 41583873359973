export const locale = {
  lang: 'en',
  data: {
    CRYPTOIN: {
      CRYPTOINVPN_URL: 'https://vpnservice.online',
      COMPANY: {
        NAME: 'Cryptoin MB',
        EMAIL: 'info@cryptoinvpn.com',
        WWW: 'http://cryptoin.lt'
      },
      NOTIFICATION: {
        TITLE: 'Messages',
        NEW: 'New',
        ACTION: {
          SHOW_ALL: 'Show all messages'
        },
        NEW_MESSAGE: {
          TITLE: 'New message!',
          TEXT: 'You received a new message.'
        }
      },
      MERCHANT: {
        TITLE: 'Merchant ID',
        ID_PLACEHOLDER: 'Enter merchant ID...',
        ID_TOOLTIP: 'CryptoinVPN merchants can give better price offers than regular price. If so, then you will see discount on order summary.',
        NOTIFICATION: {
          NOT_FOUND: {
            TITLE: 'WARNING!',
            TEXT: 'Such merchant ID not found!'
          }
        },
      },
    },
    COMMON: {
      QUICK_MENU: {       
        ORDERS: 'Active Services',
        BUY_NOW: 'New Order',
        MY_PROFILE: 'My Profile',
        VPN_CLIENTS: 'Download VPN Client'
      },
      DATE_LOCALE: 'EN',
      EXPAND_COLLAPSE: 'Expand/Collapse Row',
      COLLAPSE: 'Collapse Row',
      CURRENCY_SYMBOL: '€',
      SECTION: {
        ADDITIONAL_FILTER: 'Additional filter settings',
      },
      WEEKDAYS:
      {
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday'
      },
      WEEKDAYS_NO:
      {
        _1: 'Mon',
        _2: 'Tue',
        _3: 'Wed',
        _4: 'Thu',
        _5: 'Fri',
        _6: 'Sat',
        _0: 'Sun'
      },
      MONTH: {
        _1: 'January',
        _2: 'February',
        _3: 'March',
        _4: 'April',
        _5: 'May',
        _6: 'June',
        _7: 'July',
        _8: 'August',
        _9: 'September',
        _10: 'October',
        _11: 'November',
        _12: 'December',
      },
      MONTH_IN_GENTIVE: {
        _1: 'January',
        _2: 'February',
        _3: 'March',
        _4: 'April',
        _5: 'May',
        _6: 'June',
        _7: 'July',
        _8: 'August',
        _9: 'September',
        _10: 'October',
        _11: 'November',
        _12: 'December'
      },
      FILE_UPLOAD: {
        CHOOSE_FILE: 'Choose files:',
        LOADED_FILES: 'Uploaded files',
        NEW_LOADED_FILES: 'Newly uploaded files',
        BUTTON_DOWNLOAD: 'Download',
        BUTTON_DELETE: 'Delete',
        BUTTON_UPLOAD: 'Upload all',
        BUTTON_SAVE: 'Save uploaded files',
        COUNT: 'Files count:',
        TABLE: {
          STATUS: 'Status',
          STATUS_TOOLTIP: 'Uploaded/Not uploaded',
          NAME: 'Name',
          SIZE: 'Size',
          ACTIONS: ''
        }
      },
      EMAIL_CONFIRM_PAGE: {
        TITLE: 'Email address has been confirmed! 🚀',
        TEXT: 'Your new email address is active!',
      },
      ERROR_PAGE: {
        TITLE: 'Page Not Found 🕵🏻‍♀️',
        TEXT: 'Oops! 😖 The requested URL was not found on this server.',
        BUTTON_BACK: 'Back'
      },
      NOT_AUTHORIZED_PAGE: {
        TITLE: 'You are not authorized! 🔐',
        TEXT: 'You have no rights to perform this action.',
        BUTTON_BACK: 'Back'
      },
      DELETE: {
        DIALOG: {
          TEXT: 'Deleting record: ',
          TITLE: 'Do you really want delete data?',
          YES: 'Yes',
          NO: 'No'
        },
        CONFIRM: {
          TEXT: 'Deleted record: ',
          TITLE: 'Data is deleted!'
        },
        ERROR: {
          TEXT: 'Not deleted record: ',
          TITLE: 'Data is NOT deleted!'
        }
      },
      DIALOG: {
        MISSED_INFO: {
          TEXT: '',
          TITLE: 'Missed values!',
        },
        VIES: {
          TEXT: 'To check the validity of the VAT code, enter the VAT code of the company.',
          TITLE: 'ERROR!',
          TOOLTIP: 'Check the validity of the VAT code in VIES',
          VALID: 'VAT code is valid!',
          NOT_VALID: 'VAT code is INVALID or not found!'
        },
      },
      FORM: {
        COMMENT: 'Comments',
        COLOR_CODE: 'Color',
        IS_ACTIVE: 'Active',
        IS_DEFAULT: 'By default',
        SHOW_IN_REPORT: 'Show in reports',
        SORT_NO: 'Sort No.',
        VAT_INFO: 'VAT may apply',
        TOTAL: 'Total',
        DISCOUNT: 'Discount',
        VAT: 'VAT ',
        TAX_COUNTRY: 'Tax country: ',
        TOTAL_AMOUNT: 'Total amount'
      },
      TABLE: {
        COMMENT: 'Comments',
        COLOR_CODE: 'Color',
        IS_ACTIVE: 'Active',
        IS_DEFAULT: 'By default',
        SHOW_IN_REPORT: 'Show in general reports',
        ACTIONS: 'Actions',
        INVOICE: 'Invoice'
      },
      ACTION: {
        EDIT: 'Edit',
        SAVE: 'Save',
        SAVE_CHANGES: 'Save changes',
        SAVE_PASSWORD: 'Save password',
        APPLY: 'Apply',
        UPDATE: 'Update',
        CANCEL: 'Cancel',
        CLOSE: 'Close',
        DELETE: 'Delete',
        PRINT: 'Print',
        SEND: 'Send',
        SHOW_ROWS: 'Show ',
        REFRESH: 'Refresh list',
        ORDER_NOW: 'Buy Now',
        RENEW: 'Buy again',
        CHANGE_PLAN: 'Change',
        CHANGE_PSSWD: 'Change password',
        NEW_VPN_ACCOUNT: 'Activate user',
        ACTIVATE_VPN_ACCOUNT: 'Activate',
        SELECT: 'Select',
        SELECTED: 'Selected',
        BACK: 'Back',
        MAKE_PAYMENT: 'Make payment',
        CANCEL_PAYMENT: 'Cancel order',
        COMPLETE_PURCHASE: 'Complete Purchase',
        CANCEL_PURCHASE: 'Cancel Payment',
      },
      ERROR: {
        DUBLICATE_TITLE: 'ERROR!',
        DUBLICATE_BODY: 'already exists.',
      },
      CONSTANT: {
        ALL: 'All',
        SELECT_PERIOD: 'Select period',
        CLEAR_FILTER: 'Clear filter'
      },
      NOTIFICATION: {
        SAVED: {
          TITLE: 'Saved!',
          TEXT: 'Data saved successfully.'
        },
        NOT_SAVED: {
          TITLE: 'ERROR!',
          TEXT: 'Save failed!'
        },
        FOUND_EMAIL: {
          TEXT: 'Entered email address is already used and cannot be saved!',
          TITLE: 'ERROR! ',
        },
        CONFIRM_EMAIL: {
          TEXT_1: 'Your new email confirmation link has been sent to ',
          TEXT_2: '! Please check you inbox.',
          TITLE: 'ATTENTION! ',
        },
        CONFIRM_CODE_NOT_FOUND: {
          TEXT: 'Email address confirmation code not found!',
          TITLE: 'ERROR!',
        },
        CONFIRM_ERROR: {
          TEXT: 'Email address confirmation error! Please, resend confirmation link again!',
          TITLE: 'ERROR!',
        },
        EMAIL_NOT_FOUND: {
          TEXT: 'Email address NOT found! Please, enter email address in your profile.',
          TITLE: 'ERROR!',
        },
        VPN_ACCOUNT_ACTIVATED: {
          TITLE: 'Activated!',
          TEXT: 'VPN user activated successfully.'
        },
        VPN_ACCOUNT_NOT_ACTIVATED: {
          TITLE: 'ERROR!',
          TEXT: 'VPN user activation failed.'
        },
        VPN_USER_TAKEN: {
          TITLE: 'ERROR!',
          TEXT: 'VPN username has already been taken.'
        },
        VPN_USE_PASSWORD_CHANGED: {
          TITLE: 'Success!',
          TEXT: 'VPN user password changed successfully.'
        },
        VPN_USE_PASSWORD_NOT_CHANGED: {
          TITLE: 'ERROR!',
          TEXT: 'VPN user password not changed.'
        },
        NEW_SERVICE_BUY_SUCCESS: {
          TITLE: 'Success!',
          TEXT: 'Your new VPN service purchase was successful. Now you can activate your new VPN subscription, if need.'
        },
        NEW_SERVICE_BUY_FAILED: {
          TITLE: 'FAILED!',
          TEXT: 'An error occurred while purchasing new VPN services. Try again or contact us by email: support@cryptoinvpn.com'
        },
        PROCESS_STARTED: {
          TITLE: 'In progress!',
          TEXT: 'Selected action is in progress. Please wait...'
        },
        PAYMENT_PROCESS_STARTED: {
          TITLE: 'Payment process is in progress! Please wait...',
          TEXT_NOTLOGGED: 'After the payment is accepted, you will be automatically transferred to the "How to setup VPN client" page.',
          TEXT_LOGGED: 'After the payment is accepted, you will be automatically transferred to your Active services list.'
        },
        DELETE_SUCCESS: {
          TEXT: 'Record deleted successfully.',
          TITLE: 'DELETED!',
        },
        DELETE_FAILED: {
          TEXT: 'Record NOT deleted.',
          TITLE: 'FAILED!',
        },
        CANCELED_SUCCESS: {
          TEXT: 'Order canceled successfully.',
          TITLE: 'Canceled!',
        },
        CANCELED_FAILED: {
          TEXT: 'Order NOT canceled.',
          TITLE: 'FAILED!',
        },
        NEW_ACCOUNT_SUCCESS: {
          TITLE: 'Success!',
          TEXT: 'Your CryptoinVPN account successfully created! We sent you an email with your login data.'
        },
        NEW_ACCOUNT_FAILED: {
          TITLE: 'FAILED!',
          TEXT: 'An error occurred while creating your CryptoinVPN account. Contact us by email: support@cryptoinvpn.com'
        },
        PAYMENTS_METHOD_LOADING: {
          TITLE: 'Loading...',
          TEXT: 'Preparing payments methods. Please wait...'
        },
        PAYMENT_FAILED: {
          TITLE: 'FAILED!',
          TEXT: 'Payment not processed!'
        },
        DOWNLOAD: {
          START: 'Downloading, please wait...',
          FINISH: 'Downloaded!'
        },
        NOT_EMPTY: {
          TITLE: 'Enter value!',
          TEXT: ' can not be empty.'
        },
      }
    },
    HOME: {
      TITLE: 'CryptoinVPN - Secure your private data and browse on internet safely! 🚀',
      BODY: ''
    },
    DASHBOARD: {
      CHART: {
      },
      CONTENT: {
        HEADER: {
          TITLE: 'Dashboard',
          NAME: 'Analytics'
        },
      }
    },
    SERVICES: {
      TABLE: {
        VPN_USER_NAME: 'VPN Username',
        ORDER_INFO: 'Order info',
        ORDER_NO: 'No. ',
        ORDER_DATE: 'Date: ',
        SERVICE_NAME: 'Service name: ',
        SERVICE_DESCRIPTION: 'Description',
        SERICE_PRICE: 'Price: ',
        SERVICE_PERIOD: 'Active: From - To',
        STATUS: 'Status',
        NEW_VNP_USER_PLACEHOLDER: 'Create VPN username...',
        NEW_VNP_USER_TOOLTIP: 'Create your VPN username and enter here',
        NEW_PASSWORD_PLACEHOLDER: 'Enter new password...'
      },
      BUY: {
        TITLE: 'Pricing Plans',
        INFO: {
          TEXT_1: 'If there is <b>NO</b> active service in the list of active services (menu item <b>“Active Services”</b>), then a new service record will be created, which you will need to activate. To activate the service, you need to click button <b>“Activate user”</b>.',
          TEXT_2: 'If there <b>ARE</b> active services in the services list, then the active service with the highest expiration date will be taken, and its expiration date will be extended, by creating a new entry. This entry does not require additional activation.'
        },
        PERIOD_1: '- for ',
        PERIOD_2: 's period -',
        TOTAL_PAYMENT_1: 'Total payment for ',
        TOTAL_PAYMENT_2: 's is ',
        GUARANTEE: {
          TITLE: 'If you are not satisfied with the quality, you can always take advantage of the 30-day money-back guarantee!',
          TEXT: 'Get started now and ensure you are safe online!'
        },
        FAQ: {
          TITLE: 'FAQ`s',
          TEXT: 'Let us help answer the most common questions.'
        },
      },
      ACTION: {
      },
      CONTENT: {
        HEADER: {
          TITLE: 'Services',
          NAME: 'Active services'
        },
      }
    },
    PAYMENT: {
      ORDER_SUMMARY: 'Order summary',
      PAYMENT_METHOD: 'Select a payment method',
      DATE_FROM: 'from',
      DATE_TO: ' to ',
      AMOUNT: 'Amount',
      BUYER: {
        TITLE: 'Buyer',
      },
      SELLER: {
        TITLE: 'Seller',
        COMPANY_CODE: 'Company code ',
        VAT_CODE: 'VAT '
      },
      CREDITCARD: {
        TITLE: 'Make fiat payment via Credit card...',
        TEXT_1: 'Payments are charged in ',
        TEXT_2: '. Payment provider fees may apply.',
        MAKE_PAYMENT: 'Make Credit or debit card payment for '
      },
      PAYSERA: {
        TITLE: 'Make fiat payment via Paysera...',
        TEXT_1: 'Payments are charged in ',
        TEXT_2: '. Payment provider fees may apply. You will be redirected to Paysera to complete your purchase securely.',
        MAKE_PAYMENT: 'Make Paysera payment for '
      },
      PAYPAL: {
        TITLE: 'Make fiat payment via Paypal...',
        TEXT_1: 'Payments are charged in ',
        TEXT_2: '. Payment provider fees may apply. You will be redirected to Paypal to complete your purchase securely.',
        MAKE_PAYMENT: 'Make Paypal payment for '
      },
      GPAY: {
        TITLE: 'Make fiat payment via Google Pay...',
        TEXT_1: 'Payments are charged in ',
        TEXT_2: '. Payment provider fees may apply. You will be redirected to Google Pay to complete your purchase securely.',
        MAKE_PAYMENT: 'Make Google Pay payment for '
      },
      CRYPTO: {
        TITLE: 'Make crypto currency payment via BitPay...',
        TEXT: '<b>This is crypto currencies payments</b>. Payment provider fees may apply. You will be redirected to BitPay to complete your purchase securely. It may take up to 24 hours to process your payment.You will get an email notification as soon as the service is active.',
        MAKE_PAYMENT: 'Make BitPay payment for '
      },
      STRIPE: {
        TITLE: 'Make fiat payment via Stripe...',
        TEXT_1: 'Payments are charged in ',
        TEXT_2: '. Payment provider fees may apply. You will be redirected to Stripe to complete your purchase securely.',
        MAKE_PAYMENT: 'Make Stripe payment for ',
        COMPLETE_PURCHASE: 'Complete Purchase for ',
      },
      ACTION: {
      },
      CONTENT: {
        HEADER: {
          TITLE: 'Payment',
          NAME: 'Checkout'
        },
      }
    },
    SALE: {
      INVOICE: {
        PRINT: {
          INVOICE_DATE: 'Invoice date ',
          PAYMENT_DATE: 'Payment date ',
          COMPANY_CODE: 'Company code ',
          VAT_CODE: 'VAT code ',
          BUYER_TITLE: 'Buyer',
          ISSUED_BY: 'Issued by ',
          SERVICE_LIST: {
            DESCRIPTION: 'Description',
            MEASURE: 'Measure',
            QUANTITY: 'Quantity',
            PRICE: 'Price',
            TOTAL: 'Total',
            VAT_AMOUNT: 'VAT',
            TOTAL_AMOUNT: 'Total Amount'
          },
          TOTALS: {
            DISCOUNT: 'Discount ',
            VAT: 'VAT ',
            TOTAL: 'Total ',
            TOTAL_AMOUNT: 'Total Amount '
          }
        }
      }
    },
    HELP: {
      FAQ: {
        MAIN_TITLE: 'Let us answer some questions',
        MAIN_SUBTITLE: 'or select a category to quickly find the help you need',
        SEARCH: 'Search FAQ base...',
        NOT_FOUND: 'No results found',
        HAVE_QUESTION: 'You still have a question?',
        HAVE_QUESTION_CAPTION: 'If you can`t find a question in our FAQ, you can always contact us. We will answer you soon!',
        CONTACT: {
          PHONE: '+370 616 46347',
          PHONE_CAPTION: 'We are always happy to help!',
          EMAIL: 'support@cryptoinvpn.com',
          EMAIL_CAPTION: 'The best way to get a response faster!',
        },
        ALL: 'All',
        CONTENT: {
          HEADER: {
            TITLE: 'Help',
            NAME: 'FAQ'
          }
        },
      },
      VPN_CLIENTS: {
        TITLE: 'VPN Clients List',
        SUB_TITLE: 'Here you will find apps with the help of which you can connect to the VPN network and surf on internet safely. Depending on the device you are using, download the required app and install it.',
        WINDOWS: {
          TITLE: 'For Windows',
          SUB_TITLE: 'VPN client for Windows PC',
          ACTION: {
            DOWNLOAD: 'Download Windows App'
          }
        },
        MAC: {
          TITLE: 'For Mac',
          SUB_TITLE: 'VPN client for Mac PC',
          ACTION: {
            DOWNLOAD: 'Download Mac App'
          }
        },
        LINUX: {
          TITLE: 'For Linux',
          SUB_TITLE: 'VPN client for Ubuntu 16, 18',
          ACTION: {
            DOWNLOAD: 'Download Linux App (beta)'
          }
        },
        ANDROID: {
          TITLE: 'For Android',
          SUB_TITLE: 'VPN client for tablet & smart phone with Android',
          ACTION: {
            DOWNLOAD_1: 'Download via Google Play',
            DOWNLOAD_2: 'Download APK'
          }
        },
        IOS: {
          TITLE: 'For iPhone & iPad',
          SUB_TITLE: 'VPN client for tablet & smart phone with iOS',
          ACTION: {
            DOWNLOAD: 'Download via iTunes Store'
          }
        }
      },
      SETUP_VPN_CLIENT: {
        TITLE: 'How to setup VPN client',
        SUB_TITLE: 'Here you find detailed instructions on how to setup the VPN client to use CryptoinVPN service',
        DOWNLOAD: '1. DOWNLOAD YOUR PREFERABLE VPN CLIENT',
        SET_USER_PASSWORD: {
          TITLE: '2. SET VPN CLIENT USERNAME AND PASSWORD',
          OPEN_SETTING: 'Open the <b>CryptoinVPN app</b> and press the <b>Settings</b> button',
          SETTING: '',
          USERNAME_PASSWORD: 'Enter your <b>Username</b> and <b>Password</b>. Then press the <b>Update</b> button and close <b>[X]</b> settings window.',
          USERNAME: 'Username:',
          PASSWORD: 'Password:'
        },
        CONNECT: {
          TITLE: '3. CONNECT TO VPN',
          SELECT: 'Select your preferable Server location',
          CONNECT: 'Press <b>Power (SWITCH ON)</b> button',
        },
      }
    },
    ACCOUNT: {
      PROFILE: {
        GENERAL: {
          TITLE: 'General',
          STATUS_INFO: 'Account status',
          CLIENT_NO: 'Client No.',
          FIRST_NAME: 'First name',
          FIRST_NAME_ERROR1: 'Enter first name!',
          LAST_NAME: 'Last name',
          LAST_NAME_ERROR1: 'Enter last name!',
          EMAIL: 'Email',
          EMAIL_PLACEHOLDER: 'Email also is used for login purposes',
          EMAIL_ERROR1: 'Enter email address!',
          EMAIL_ERROR2: 'Bad email address format!',
          EMAIL_ATTENTION: 'Email address is using for login to your account. Keep it in mind when you plan to change it.',
          MOBILE_PHONE: 'Mobile phone',
        },
        PASSWORD: {
          TITLE: 'Change Password',
        },
        COMPANY_INFO: {
          TITLE: 'Company Information',
          POSITION: 'Position',
          POSITION_PLACEHOLDER: 'Your position in company...',
          PHONE: 'Phone',
          PHONE_PLACEHOLDER: 'Company phone number...',
          NAME: 'Company name',
          CODE: 'Company code',
          VAT_CODE: 'VAT code',
          ADDRESS: 'Address',
          CITY: 'City',
          DISTRICT: 'District',
          ZIP: 'ZIP',
          COUNTRY: 'Country',
          COUNTRY_ERROR1: 'Select company country!',
        },
        NOTIFICATION: {
          TITLE: 'Notifications',
          INVOICE: 'Invoices and notifications about service validity',
          NEWS_LETTER: 'Additional service announcements and newsletters',
          NEW_SERVICE: 'Offers of new services'
        },
        LOCALE: 'Language',
        LOCALE_ERROR1: 'Select language!',
      },
      CONTENT: {
        HEADER: {
          TITLE: 'Account',
          NAME: 'My Profile'
        }
      },
      DIALOG: {
        LIST: {
          HEADER: 'New user registration',
          BUTTON_SELECT: 'Register'
        },
        LOGIN: {
          TITLE: 'Welcome! 👋',
          TEXT: 'Please log in to use the system',
          COMPANY_CODE: 'Company code',
          COMPANY_CODE_ERROR1: 'Enter company code!',
          USER_NAME: 'Log-in name',
          USER_NAME_PLACEHOLDER: 'your_name@example.com',
          USER_NAME_ERROR1: 'Enter email address!',
          USER_NAME_ERROR2: 'Wrong email address format!',
          PASSWORD: 'Password',
          PASSWORD_ERROR1: 'Enter password!',
          FORGOT_PASSWORD: 'Forgot password?',
          REMEMBER_ME: 'Remember me',
          BUTTON_LOGIN: 'Log in',
          LOCALE: 'Language',
          SUCCESS: {
            TITLE_START: '👋 Welcome',
            TITLE_END: '!',
            TEXT: 'You successfully log in to the CryptoinVPN system.'
          },
          FAILED: {
            TITLE: 'Authorization error!',
            TEXT: 'Invalid username or password.'
          }
        },
        FORGOT_PASSWORD: {
          TITLE: 'Forgot Password? 🔒',
          TEXT: 'Enter your email and we will send you password reset link',
          EMAIL: 'Email',
          EMAIL_ERROR1: 'Enter email address!',
          EMAIL_ERROR2: 'Wrong email address format!',
          EMAIL_PLACEHOLDER: 'your_name@example.com',
          ACTION: {
            RESET: 'Send password reset link',
            LOGIN: 'Back to login page',
          },
          INFO: {
            LINK_SENT: 'Password reset link sent!',
            LINK_NOT_SENT: 'Password reset link NOT sent!'
          }
        },
        RESET_PASSWORD: {
          TITLE: 'Password reset 🔒',
          TEXT: 'Your new password must be different from your previous passwords',
          NEW_PASSWORD: 'New password',
          NEW_PASSWORD_ERROR1: 'Enter password!',
          NEW_PASSWORD_ERROR2: 'Password must be at least 8 characters long!',
          CONFIRM_PASSWORD: 'Retype New Password',
          CONFIRM_PASSWORD_ERROR1: 'Passwords do not match!',
          RAC_NOT_VALID: 'Bad password change code!',
          ACTION: {
            RESET: 'Save new password',
            LOGIN: 'Back to login page',
          },
          INFO: {
            PASSWORD_CHANGED: 'Password successfully changed!',
            PASSWORD_NOT_CHANGED: 'Password Unchanged!'
          }
        },
      }
    },
    VB: {
      CART: {
        TITLE: 'My Cart',
        SERVICE_ONE: 'service',
        SERVICE_MANY: 'services',
        TOTAL: 'Total:',
        VAT: 'VAT 21%:',
        TOTAL_AMOUNT: 'Total to pay:',
        ACTION: {
          PAYMENT: 'Make payment'
        },
        DIALOG: {
          TITLE: 'Your subscription is not active!',
          TEXT: 'To activate your subscription, make a payment.'
        }
      },
      NOTIFICATION: {
        TITLE: 'Notifications',
        NEW: 'New',
        ACTION: {
          SHOW_ALL: 'Show all notifications'
        },
        NEW_MESSAGE: {
          TITLE: 'New notification!',
          TEXT: 'You have new message.'
        }
      },
      SUBSCRIPTION: {
        SERVICE_LIST: {
          TITLE: 'List of purchased services',
          SERVICE_NAME: 'Service name',
          QUANTITY: 'Quantity',
          PRICE: 'Price',
          SUM: 'Amount',
          TOTAL: 'Total',
          VAT_SUM: 'VAT amount',
          TOTAL_AMOUNT: 'Total amount',
          PERIOD: 'Period: From-To',
          ACTIVE: 'Active',
          INVOICE_NO: 'VAT invoice No. ',
        },
        CONTENT: {
          HEADER: {
            TITLE: 'Virtualus buhalteris',
            NAME: 'Subscription'
          }
        },
      },
      INVOICE: {
        INVOICE_DATE: 'Date ',
        PAYMENT_DATE: 'Pay by ',
        SUM_IN_WORDS: 'Sum in words: ',
        ISSUED_BY: 'Issued by: ',
        ACCEPTED_BY: 'Acccepted by',
        VB: {
          COMPANY_CODE: 'Company code: ',
          VAT_CODE: 'VAT code: ',
          PHONE: 'Tel. ',
          EMAIL: ', email ',
          BANK_NAME: 'Bank: ',
          BANK_ACCOUT_NO: 'Acc. No. '
        },
        BUYER: {
          TITLE: 'Buyer',
          COMPANY_CODE: 'Company code: ',
          VAT_CODE: 'VAT code: ',
          PHONE: 'Tel. ',
          EMAIL: ', email ',
        },
        LINE: {
          SERVICE_NAME: 'Service name',
          QUANTITY: 'Quantity',
          PRICE: 'Price',
          SUM: 'Amount',
          TOTAL: 'Total ',
          VAT_SUM: 'VAT amount',
          TOTAL_AMOUNT: 'Total amount '
        }
      }
    },
    REPORT: {
      DASHBOARD: {
        CHART: {
          SALE: {
            TITLE: 'Sales statistics',
            SUB_TITLE: 'Sales and discounts granted',
            TOTAL: 'Calculated',
            TOTAL_PAID: 'Paid',
            LOSS: 'Discount',
          }
        },
        SOL_MESSAGES: {
          TITLE: 'Libra messaging history',
          MARK_AS_READ: 'Mark as readed',
        },
        CONTENT: {
          HEADER: {
            TITLE: 'Home',
            NAME: 'Statistics'
          }
        }
      },
    },
    WEB: {
      TERM_OF_USE: {
        HEADER: 'Terms of Service',
        HEADER_1: 'terms of use',
        ACCEPT_LABEL: 'I agree with '
      },
      PRIVACY_POLICY: {
        HEADER: 'Privacy Policy',
        ACCEPT_LABEL: 'I agree with '
      },
      MERCHANT_PROGRAM: {
        HEADER: 'Terms and conditions of the merchant program',
        HEADER_1: 'terms and conditions of the merchant program',
        ACCEPT_LABEL: 'I agree with '
      },
      LANDING_PAGE: {
        SECTION: {
          TITLE: 'Secure your private data and browse on internet safely! 🚀',
          TITLE_1: 'The Best VPN Service for Online Privacy and Security',
          VULNERABLE: {
            TITLE: 'Did you know that your data is vulnerable by default?',
            P1: 'Once you connect to the Internet, all of your traffic goes through your Internet Service Provider (ISP). That`s how the Internet works.To get to a website, you must submit a request to an ISP who will direct you to your destination.As a result, everything you do online is an open book for the ISP.',
            P2: 'No technological barrier prevents an ISP from tracking and recording your online behavior, selling data to advertisers, or sharing it with authorities. There are very few ethical ISPs that protect the privacy of their customers. And even if your ISP is trustworthy, the government can force them to scrap your browsing history.'
          },
          GET_SECURE: {
            TITLE: 'Get secure and private access to the internet',
            P1: 'A good VPN service provides you with a secure, encrypted tunnel for your online traffic to pass through. No one can peek through the tunnel, access your online data, or know your real IP address and location.',
            P2: 'CryptoinVPN is the best VPN if you are looking for peace of mind when using public Wi-Fi and from your home network when connected to the Internet. Secure access to personal information or work files, encrypt your internet connection and keep your browsing history and online identification private.',
            P3: 'Enjoy online privacy and security with CryptoinVPN.'
          },
          PROTECT_DEVICE: {
            TITLE: 'Protect all of your devices',
            P1: 'Enjoy online privacy and security on any platform like Windows, macOS and Linux. The CryptoinVPN app is available for Windows, macOS, iOS, Android, Linux. With one CryptoinVPN account, you can protect up to 10 devices at the same time.',
            P2: 'Try our risk-free VPN with a 30-day money-back guarantee and surf the web in privacy!'
          },
          WHY_VPN: {
            TITLE1: 'Why do you need a VPN?',
            TITLE: 'WHY DO YOU NEED A VPN?',
            P1: 'No one likes to be watched or tracked, even if they have nothing to hide. That`s why it`s so important to protect your privacy. When you surf the web through a VPN, your traffic is encrypted so no one can see what you are doing online. Here are a few more reasons why you need a VPN:',
            R_1: {
              TITLE: 'You regularly use public Wi-Fi',
              TEXT: 'A VPN is used to secure your connection over public Wi-Fi, so you can surf the web in complete privacy. Hackers have many ways to steal your data from public hotspots, but with a VPN, your online traffic is invisible to them.'
            },
            R_2: {
              TITLE: 'You want to access your content',
              TEXT: 'If you want to access your home content while traveling around the world, a VPN can help. Install CryptoinVPN on your device and view your data freely.'
            },
            R_3: {
              TITLE: 'You want to stay safe online',
              TEXT: 'Government agencies, marketers, ISPs all would like to track and collect your browsing history, messages and other personal data. The best way to hide it is to use a VPN to encrypt your traffic, hide your IP address, and cover your tracks online. Use it at home, at work and on the go to enjoy uninterrupted protection.'
            },
          },
          WHAT_VPN_DO: {
            TITLE: 'WHAT DOES A VPN DO?',
            TITLE1: 'What does a VPN do?',
            P1: 'Typically, when you try to access a website, your ISP (Internet Service Provider) receives the request and redirects you to your destination. But when you connect to a VPN, it first redirects your internet traffic through the VPN server before sending it to its destination. Here`s what happens when you connect to a VPN:',
            R_1: {
              TITLE: 'VPN changes your IP address and masks your virtual location',
              TEXT: 'People working in countries with limited freedom of speech rely on a private Internet connection for their work. Sometimes their life can depend on it. Anyone living under an authoritarian regime should use a VPN to hide their IP address and provide extra security for their sensitive communications.'
            },
            R_2: {
              TITLE: 'VPN encrypts your data',
              TEXT: 'Using a VPN is a good idea even when browsing from your home. VPN encryption is important when you want to protect your internet traffic and minimize your online presence. This way, your ISP will not be able to sell your entire browsing history to the highest bidder.'
            }
          },
          HOW_VPN_WORK: {
            TITLE: 'How does a VPN work?',
            P1: 'When you connect to a VPN service, it authenticates your client with the VPN server',
            P2: 'The server then applies the encryption protocol to all data you send and receive',
            P3: 'The VPN service creates an encrypted "tunnel" through the Internet. This ensures the security of the data being transferred between you and the destination',
            P4: 'To guarantee the security of each data packet, the VPN wraps it in an outer packet, which is then encrypted using encapsulation. This is the main element of the VPN tunnel, ensuring the security of data during transmission',
            P5: 'When the data arrives at the server, the outer packet is removed through the decryption process'
          },
          HOW_TUNNEL_WORK: {
            TITLE: 'How does VPN tunneling work?',
            P1: 'A VPN tunnel is created by first authenticating your client—computer, smartphone, or tablet—with a VPN server. The server then uses one of several encryption protocols to make sure no one can monitor on the information being sent between you and your online destination.',
            P2: 'Here you must remember that before sending and receiving over the Internet, any data must first be divided into packets. To guarantee the security of each data packet, the VPN service wraps it in an outer packet, which is then encrypted using a process called encapsulation.',
            P3: 'This outer pocket keeps the data secure while in transit and is the core of the VPN tunnel. When the data arrives at the VPN server, the outer packet is dropped to access the inner data, which requires a decryption process.'
          },
          HOW_SERVER_WORK: {
            TITLE: 'How do VPN servers operate?',
            P1: 'Once a VPN tunnel is established, your device sends encrypted information (such as the website you want to visit) to the VPN server. It decrypts it and forwards the information to the specified web server. It also hides your real IP address before sending data. Instead, you`ll get the IP address of the VPN server you`re connected to.',
            P2: 'When the web server responds, the VPN server encrypts the data and sends it to you through your ISP. Your VPN client will decrypt the data as soon as it reaches your device.'
          },
          HOW_CHOOSE_VPN: {
            TITLE: 'WHAT SHOULD I LOOK FOR WHEN CHOOSING A VPN?',
            TITLE1: 'What should I look for when choosing a VPN?',
            R_1: {
              TITLE: 'Speed, security and privacy',
              TEXT: 'People turn to VPNs for a variety of reasons, and whatever yours, there are three questions you need to ask before choosing a provider. How fast is it? Will this keep my data safe? And will it respect my privacy? Of course, you want your connections to be secure and your data to remain private. But to have it all without losing speed? This is the whole focus.'
            },
            R_2: {
              TITLE: 'Paid vs. Free',
              TEXT1: 'Price is always one of the most important factors, regardless of the product. And if a free option comes up, people show interest. But when it comes to free VPNs, it`s best to stay away. You`re looking for speed, security, and privacy, and with free VPNs, you`re unlikely to get any of that.',
              TEXT2: 'These providers usually don`t invest in their server network, so their VPN connections can be unstable and leak users` IP addresses. They also have to make money somehow - in some cases they may resort to selling their users` data. So instead of looking for the cheapest option, try to find a VPN provider you can trust.'
            }
          },
          ENCRYPTION_PROTOCOL: {
            TITLE: 'VPN encryption protocols',
            P1: 'What is VPN without encryption? Just a reliable way to slow down your connection. When people use a VPN, their main concerns are privacy and speed. That`s why we offer the most popular OpenVPN encryption protocol, which is currently used by most VPN providers in the world.',
            P2: 'One of the biggest strengths of OpenVPN is that it is highly customizable. It also strikes a good balance between speed and security as you can use it on both TCP and UDP ports for secure browsing, gaming and live streaming. While the TCP port is the more secure option, UDP is faster and many users prefer it for seamless access to online games or watching videos.'
          },
          SERVER_LIST: {
            TITLE: 'CHOOSE ONE OF THE VPN SERVERS THAT SUITS YOUR NEEDS',
            TITLE1: 'Choose one of the VPN servers that suits your needs',
            ONLINE: 'ONLINE',
            COUNTRY_CODE: 'Country',
            CITY: 'City'
          },
          MOBILE_DEVICE: {
            TITLE: 'Stay safe wherever you go when you use VPN on mobile devices',
            P1: 'A VPN, or virtual private network, guarantees the safety of your data, whether you use your home Wi-Fi, hotspot at a local cafe, or a mobile network. Here`s why you need a VPN on your phone or tablet:',
            R_1: {
              TEXT: 'Every time you connect to unsecured Wi-Fi, you are exposed to cyber threats.'
            },
            R_2: {
              TEXT: 'Everything you do online can be tracked and logged by your mobile internet provider.'
            },
            R_3: {
              TEXT: 'Every website you visit sees your IP address.'
            }
          },
          SECURE_UP_TO: {
            TITLE: 'One CryptoinVPN account secures up to 10 devices',
            P1: 'One CryptoVPN account will cover 10 devices at once, giving you 10 times more protection. Windows, macOS, Android, iOS, Linux - no matter what operating system you use, CryptoinVPN will protect your IP address and encrypt your traffic.',
            P2: 'This is very useful when you decide to share VPN security with your family.',
            P3: 'Just like you, your family members put themselves at risk when they use social media, email, or online banking. Fortunately, one CryptoVPN account is enough to protect the whole family.',
            P4: 'Share your 10 device slots with people you trust by letting them use your subscription.'
          },
          SETTING_UP: {
            TITLE: 'Setting up a VPN connection',
            P1: 'Join the VPN security trend, set up your CryptoinVPN account, use user-friendly CryptoinVPN apps for all major platforms such as Mac, Android tablet or Windows laptop, and get an encrypted VPN connection wherever you go.'
          },
          PRICE_LIST: {
            TITLE1: 'CHOOSE MOST APPROPRIATE PLAN AND GET BENEFITS FROM USING OUR CRYPTOINVPN SERVICES',
            TITLE: 'Choose most appropriate plan and get benefits from using our CryptoinVPN services'
          },
          FAQ: {
            TITLE: 'Let us help answer the frequently asked questions'
          },
        },
        ACTION: {
          BUTTON_LOGIN: 'Log In',
          BUTTON_REGISTER: 'Sign Up'
        }
      },
      PAYMENT_PAGE: {
        CREATE_ACCOUNT: {
          TITLE: 'Create account',
          OR: 'or',
          HAVE_ACCOUNT: 'if you already have CryptoinVPN account',
          FIRST_NAME: 'Enter your first name',
          LAST_NAME: 'Enter your last name',
          EMAIL: 'Enter your email address',
          COUNTRY: 'Select your country',
          COMMENT: 'If you don`t want to get a marketing email, you can change the notification settings in the <b>CryptoinVPN</b> >> <b>My profile</b> menu section.',
          TERMS_PRIVACY1: 'By submitting your information and continuing to purchase, you agree to our',
          TERMS_PRIVACY2: 'Terms of Service',
          TERMS_PRIVACY3: 'and',
          TERMS_PRIVACY4: 'Privacy Policy.',
          NOTIFICATION: {
            ERROR: {
              TITLE: 'ERROR!',
              TEXT: 'Please, fill required fields...'
            },
            PAYMENT_SELECT: {
              TEXT: 'To make payment please, create account by filling fields: first name, last name, email, country; or Log In.'
            },
            EMAIL_FOUND: {
              TEXT: 'Entered email address is already used! You cannot create account with that email address.'
            }
          }
        },
      },
      ACTION: {
        ACCEPT: 'Accept'
      }
    }
  }
}