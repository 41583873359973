import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from '../../_i18n/en';

import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { loadStripe } from "@stripe/stripe-js";

import { CryptoinServicesPricesList, AppSettings, AppSettingsList, IStripePaymentRequest, ICartStorage } from '../../common/common.data';
import { UrlList, AppSettingName, AppSettingsTypeList } from 'app/constant';
import { CommonService } from '../../common/common.service';
import { IOrderedServices, IOrderedServicesNewAccount } from '../../service/service.data';
import { AccountService } from '../../account/account.service';
import { PaymentService } from '../payment.service';
import { UserProfile } from '../../account/account.data';

@Component({
  selector: 'payment-select',
  templateUrl: './payment-select.component.html',
  styleUrls: ['./payment-select.component.scss']
})
export class PaymentSelectComponent implements OnInit, OnDestroy {
  _environment = environment;
  appSettingsList: AppSettingsList[] = [];
  appSettings: AppSettings = <AppSettings>{}; 
  _appSettingsList = AppSettingsTypeList;
  userProfile: UserProfile = <UserProfile>{};
  cryptoinLogo: string;

  merchantID: string;
  discountPercent: number = 0;
  discountAmount: number = 0;

  payPalConfig?: IPayPalConfig;
  stripe;
  stripePaymentSelected: boolean = false;

  processPaymentStatus: boolean = false;
  buttonsEnabled: boolean = true;
  payseraText: string;
  paypalText: string;
  cryptoText: string;
  creditCardText: string;
  stripeText: string;
  dateFrom: Date = new Date();
  dateTo: Date = new Date();;
  vatPerc: number;
  
  @Input() public orderedService: IOrderedServices;
  @Input() public cryptoinServicePrice: CryptoinServicesPricesList;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  contentHeader: object = [];
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _router: Router,
    private _commonService: CommonService,
    private _paymentService: PaymentService,
    private _accountService: AccountService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(en);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.cryptoinLogo = this._paymentService.baseUrl + 'assets/images/logo/logo.png'
    this.dateTo.setMonth(this.cryptoinServicePrice.quantity + 1);

    //=== Gauname app nustatymus ===
    //--- Cryproin company name
    this._commonService.getAppSetting(
      AppSettingName.CryptoinVPNCompanyName,
      this._appSettingsList.find(({ name }) => name === AppSettingName.CryptoinVPNCompanyName).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.appSettings.cryptoinVPNCompanyName = result ? result : '';
      });
    //--- Cryptoin currency
    this._commonService.getAppSetting(
      AppSettingName.CryptoinVPNCurrency,
      this._appSettingsList.find(({ name }) => name === AppSettingName.CryptoinVPNCurrency).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.appSettings.cryptoinVPNCurrency = result ? result : '';

        //--- Assign info text ---
        this.payseraText = this._translateService.instant('PAYMENT.PAYSERA.TEXT_1') + this.appSettings.cryptoinVPNCurrency + this._translateService.instant('PAYMENT.PAYSERA.TEXT_2');
        this.paypalText = this._translateService.instant('PAYMENT.PAYPAL.TEXT_1') + this.appSettings.cryptoinVPNCurrency + this._translateService.instant('PAYMENT.PAYPAL.TEXT_2');
        this.cryptoText = this._translateService.instant('PAYMENT.CRYPTO.TEXT');
        this.creditCardText = this._translateService.instant('PAYMENT.CREDITCARD.TEXT_1') + this.appSettings.cryptoinVPNCurrency + this._translateService.instant('PAYMENT.CREDITCARD.TEXT_2');
        this.stripeText = this._translateService.instant('PAYMENT.STRIPE.TEXT_1') + this.appSettings.cryptoinVPNCurrency + this._translateService.instant('PAYMENT.STRIPE.TEXT_2');
      });
    //--- Paypal Gateway enabled
    this._commonService.getAppSetting(
      AppSettingName.PaypalGatewayEnabled,
      this._appSettingsList.find(({ name }) => name === AppSettingName.PaypalGatewayEnabled).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.appSettings.paypalGatewayEnabled = result ? +result : 0;
      });
    //--- Stripe Gateway enabled
    this._commonService.getAppSetting(
      AppSettingName.StripeGatewayEnabled,
      this._appSettingsList.find(({ name }) => name === AppSettingName.StripeGatewayEnabled).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.appSettings.stripeGatewayEnabled = result ? +result : 0;
      });
    //--- Bitpay Gateway enabled
    this._commonService.getAppSetting(
      AppSettingName.BitpayGatewayEnabled,
      this._appSettingsList.find(({ name }) => name === AppSettingName.BitpayGatewayEnabled).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.appSettings.bitpayGatewayEnabled = result ? +result : 0;
      });
    //--- Paysera Gateway enabled
    this._commonService.getAppSetting(
      AppSettingName.PayseraGatewayEnabled,
      this._appSettingsList.find(({ name }) => name === AppSettingName.PayseraGatewayEnabled).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.appSettings.payseraGatewayEnabled = result ? +result : 0;
      });
    //--- Paypal Client ID
    this._commonService.getAppSetting(
      AppSettingName.PaypalClientID,
      this._appSettingsList.find(({ name }) => name === AppSettingName.PaypalClientID).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.appSettings.paypalClientID = result ? result : '';
      });
    //--- Stripe Publishable Key
    this._commonService.getAppSetting(
      AppSettingName.StripePublishableKey,
      this._appSettingsList.find(({ name }) => name === AppSettingName.StripePublishableKey).type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.appSettings.stripePublishableKey = result ? result : '';
        this.stripe = loadStripe(this.appSettings.stripePublishableKey);
      });
    //===/ Gauname app nustatymus ===

    //--- Get client ID ---
    this._accountService.getClientID()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(clientID => {
      this._accountService.getUserProfile<UserProfile>(clientID).subscribe(result => {
        this.userProfile = result;

        //--- Check discount ---
        this.checkDiscount();

        if (this.userProfile.email == '') {
          this.buttonsEnabled = false;
          this._toastrService.error(
            this._translateService.instant('COMMON.NOTIFICATION.EMAIL_NOT_FOUND.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.EMAIL_NOT_FOUND.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: false }
          );
        }
      });
    })
  }

  ngOnDestroy(): void {
    //--- Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private initPaypal(): void {
    this.payPalConfig = {
      currency: this.appSettings.cryptoinVPNCurrency,
      clientId: this.appSettings.paypalClientID,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: this.appSettings.cryptoinVPNCurrency,
            value: this.getTotals('TA'),
            breakdown: {
              item_total: {
                currency_code: this.appSettings.cryptoinVPNCurrency,
                value: this.getTotals('TA')
              }
            }
          },
          items: [{
            name: this.cryptoinServicePrice.serviceName,
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {              
              currency_code: this.appSettings.cryptoinVPNCurrency,
              value: this.getTotals('TA'),
            },
          }]
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'pay',
        layout: 'vertical',
        color: 'silver'
      },
      onApprove: (data, actions) => {
        /*
        //--- Transaction was approved, but not authorized
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
        */
        this._toastrService.info(
          this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
          this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE'),
          { toastClass: 'toast ngx-toastr', closeButton: false }
        );
      },
      onClientAuthorization: (data) => {
        //--- Payment received: Create VPN service entry
        this._paymentService.paypalPayment(data.id, this.orderedService)
        .subscribe(result => {
          if (result) {
            //this._router.navigate(['/' + UrlList.ActiveServices])

            this._toastrService.success(
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_SUCCESS.TEXT'),
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_SUCCESS.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 10000 }
            );
          } else {
            this._toastrService.error(
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TEXT'),
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: false }
            );
          }

          this.buttonsEnabled = true;
          this.onSelect.emit();
        }, error => {
          this._toastrService.error(
            this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TEXT'),
            this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TITLE'),
            { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 10000 }
          );

          this.buttonsEnabled = true;
          this.onSelect.emit();
        });
      },
      onCancel: (data, actions) => {
        //--- User cancel payment request
      },
      onError: err => {
        console.log(err);

        this._toastrService.error(
          this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TEXT'),
          this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TITLE'),
          { toastClass: 'toast ngx-toastr', closeButton: false }
        );
      },
      onClick: (data, actions) => {
        //--- User press Paypal button click
      }
    };
  }

  formHeader() {
    this.contentHeader = {
      headerTitle: this._translateService.instant('PAYMENT.CONTENT.HEADER.TITLE'),
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('PAYMENT.CONTENT.HEADER.NAME'),
            isLink: true,
            link: '/'
          }
        ]
      }
    };
  }

  getTotals(type) {
    this.discountAmount = this.cryptoinServicePrice.price * (this.discountPercent / 100);
    let _price: number = this.cryptoinServicePrice.price - this.discountAmount;
    let _quantity: number = this.cryptoinServicePrice.quantity;

    if (type == 'T') {
      return (_quantity * _price).toFixed(2);
    }
    else if (type == 'V') {
      return ((_quantity * _price) * (this.userProfile.vatPercent / 100)).toFixed(2)
    }
    else if (type == 'TA') {
      return ((_quantity * _price) * (1 + (this.userProfile.vatPercent / 100))).toFixed(2);
    }
    else if (type == 'PwVAT') {
      return (_price * (1 + (this.userProfile.vatPercent / 100))).toFixed(2);
    }
    else {
      return 0;
    }
  }

  onPaymentMethodSelect(paymentMethod: string) {
    //--- I�saugojame duomenys, kad po mok�jimo patvirtinimo sukurti servise'�
    let cartStorage: ICartStorage = <ICartStorage>{};
    cartStorage.serviceID = window.btoa(this.orderedService.serviceID.toString());
    cartStorage.orderedServiceID = window.btoa(this.orderedService.orderedServiceID.toString());
    cartStorage.clientID = window.btoa(this.orderedService.clientID.toString());
    localStorage.setItem('__cart_storage__', JSON.stringify(cartStorage));
    //--/

    this.buttonsEnabled = false;
    if (paymentMethod == 'PAYSERA') {
      //--- Show message that process is in progress ---
      this._toastrService.info(
        this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
        paymentMethod + " payment " + this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE').toLowerCase(),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );

      this.orderedService.totalPaid = + this.getTotals('TA');
      this._paymentService.payseraPayment(this.userProfile.email, this.orderedService, true)
        .subscribe(result => {
          window.open(result, '_self');
          /*
          if (result != 'NULL') {
            this._toastrService.success(
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_SUCCESS.TEXT'),
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_SUCCESS.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: false }
            );
          } else {
            this._toastrService.error(
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TEXT'),
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: false }
            );
          }
          */
          this.buttonsEnabled = true;
          this.onSelect.emit();
        });
    } else if (paymentMethod == 'CRYPTO') {
      //--- Show message that process is in progress ---
      this._toastrService.info(
        this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
        paymentMethod + " payment " + this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE').toLowerCase(),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );

      let orderedService = <IOrderedServicesNewAccount>{};
      orderedService.serviceID = this.orderedService.serviceID;
      orderedService.firstName = this.userProfile.firstName;
      orderedService.lastName = this.userProfile.lastName;
      orderedService.email = this.userProfile.email;
      orderedService.countryID = this.userProfile.countryID;
      orderedService.totalPaid = +this.getTotals('TA');

      this._paymentService.cryptoPayment(orderedService, true)
        .subscribe(result => {
          localStorage.setItem('__crypto_storage__', result.id);
          window.open(result.url, '_self');
          /*
          window.open(result, '_self');
          if (result != 'NULL') {
            this._toastrService.success(
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_SUCCESS.TEXT'),
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_SUCCESS.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: false }
            );
          } else {
            this._toastrService.error(
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TEXT'),
              this._translateService.instant('COMMON.NOTIFICATION.NEW_SERVICE_BUY_FAILED.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: false }
            );
          }
          */
          this.buttonsEnabled = true;
          this.onSelect.emit();
        });
    } else if (paymentMethod == 'STRIPE') {
      //--- Show message that process is in progress ---
      this._toastrService.info(
        this._translateService.instant('COMMON.NOTIFICATION.PAYMENTS_METHOD_LOADING.TEXT'),
        this._translateService.instant('COMMON.NOTIFICATION.PAYMENTS_METHOD_LOADING.TITLE'),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );

      this.stripePaymentSelected = true;
      this.createPaymentIntent();
    }
  }

  createPaymentIntent() {
    let paymentRequest = <IStripePaymentRequest>{};
    paymentRequest.amount = +this.getTotals('TA');
    paymentRequest.currency = this.appSettings.cryptoinVPNCurrency;

    this._paymentService.stripeCreatePaymentIntent(paymentRequest, true)
      .subscribe(result => {
        let clientSecret = result.clientSecret;
        let paymentIntent = result.paymentIntent;

        this.stripe.then(res => {
          let elements = res.elements({ clientSecret });

          let paymentElement = elements.create('payment', {
            layout: {
              type: 'accordion',
              defaultCollapsed: false,
              radios: false,
              spacedAccordionItems: true
            },
            paymentMethodOrder: ['card', 'google_pay', 'apple_pay', 'alipay']
          });
          const pElement = document.getElementById("payment-element");
          paymentElement.mount(pElement);

          const form = document.getElementById("payment-form");
          form.addEventListener('submit', (e) => {
            e.preventDefault();
            this.processPaymentStatus = true;

            this._toastrService.info(
              this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
              "Payment " + this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE').toLowerCase(),
              { toastClass: 'toast ngx-toastr', closeButton: false }
            );

            res.confirmPayment({
              elements,
              confirmParams: {
                return_url: this._paymentService.baseUrl + 'stripe-payment-accept?_l=y'
              }
            }).then(({ error }) => {
              this.processPaymentStatus = false;
              this._toastrService.error(
                this._translateService.instant('COMMON.NOTIFICATION.PAYMENT_FAILED.TEXT') + ' ' + error.message,
                this._translateService.instant('COMMON.NOTIFICATION.PAYMENT_FAILED.TITLE'),
                { toastClass: 'toast ngx-toastr', closeButton: false }
              );
            });
          });

          form.addEventListener('reset', (e) => {
            e.preventDefault();
            this.stripePaymentSelected = false;
            this.buttonsEnabled = true;

            this._paymentService.stripeCancelPaymentIntent(paymentIntent, true).subscribe(res => {
              if (res) {
                this._toastrService.info(
                  this._translateService.instant('COMMON.NOTIFICATION.CANCELED_SUCCESS.TEXT'),
                  this._translateService.instant('COMMON.NOTIFICATION.CANCELED_SUCCESS.TITLE'),
                  { toastClass: 'toast ngx-toastr', closeButton: false }
                );
              }
            })
          });
        });
      });
  }

  onPaymentCancel() {
    this.onCancel.emit();
  }

  checkDiscount() {
    this._commonService.getCryptoinServicesDiscount(
      this.cryptoinServicePrice.serviceID, null)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.discountPercent = result;

        //--- Upadate payments systems values
        this.initPaypal();
      });
  }
}
