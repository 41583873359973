import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { IOrderedServices } from './service.data';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  public _currentUser: User;
  public entryData = new BehaviorSubject<any>([]);

  //-- Paimame sistemin�s �mon�s kod� i� localstorage, nes jis naudojamas indentifikuojant �mon� connection DB
  systemCompanyCode: string = localStorage.getItem('ccode');

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.currentUser.subscribe(x => (this._currentUser = x));
  }

  setPassData(data: any) {
    this.entryData.next(data);
  }

  getPassData() {
    return this.entryData.asObservable();
  }

  getClientServicesList<OrderedServicesList>(clientID: number = -1, dateFrom?: string, dateTo?: string): Observable<OrderedServicesList[]> {
    var url = this.baseUrl + 'api/Services/ClientServicesList';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("clientID", clientID);
    if (dateFrom) params = params.set("dateFrom", dateFrom).set("dateTo", dateTo);
    return this.http.get<OrderedServicesList[]>(url, { headers, params });
  }

  checkVPNUser(vpnUserName: string): Observable<boolean> {
    var url = this.baseUrl + 'api/VpnResellers/CheckVPNUserName';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("vpnUserName", vpnUserName);
    return this.http.get<boolean>(url, { headers, params });
  }

  activateVPNUser(orderedServiceID: number, vpnUserName: string): Observable<any> {
    var url = this.baseUrl + 'api/VpnResellers/CreateAccount';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("orderedServiceID", orderedServiceID).set("vpnUserName", vpnUserName);
    return this.http.get<any>(url, { headers, params });
  }

  changeVPNUserPassword(orderedServiceID: number, vpnid: string, vpnUserPassword: string): Observable<any> {
    var url = this.baseUrl + 'api/VpnResellers/ChangeVPNUserPassword';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("orderedServiceID", orderedServiceID).set("vpnid", vpnid).set("vpnUserPassword", vpnUserPassword);
    return this.http.get<any>(url, { headers, params });
  }

  deleteOrderedService(orderedServiceID: number): Observable<boolean> {
    var url = this.baseUrl + 'api/Services/' + orderedServiceID.toString();
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    return this.http.delete<boolean>(url, { headers });
  }

  getInvoiceEntry<SalesInvoiceEntry>(orderServiceID: number): Observable<SalesInvoiceEntry> {
    var url = this.baseUrl + 'api/Services/GetInvoice';
    var headers = new HttpHeaders().set("ccode", this.systemCompanyCode);
    var params = new HttpParams().set("orderServiceID", orderServiceID);
    return this.http.get<SalesInvoiceEntry>(url, { headers, params });
  }
}
