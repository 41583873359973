<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section *ngIf="!showPaymentMethods">
      <div class="card">
        <!-- Header -->
        <div class="d-flex flex-column">
          <div class="col-12 pb-0">
            <div class="d-flex align-items-center mt-1 ml-0 mb-1">
              <div class="d-flex col-12 align-items-center justify-content-start pr-0">
                <div>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="datePeriodSelect" [(ngModel)]="datePeriodSelect" (change)="onDatePeriodSelect()" />
                    <label class="custom-control-label" for="datePeriodSelect"></label>
                  </div>
                </div>
                <div *ngIf="!datePeriodSelect">
                  <span class="font-weight-bold text-primary">{{ 'COMMON.CONSTANT.SELECT_PERIOD' | translate }}</span>
                </div>
                <div class="custom-date-period-width" *ngIf="datePeriodSelect">
                  <ng2-flatpickr [config]="DatePeriodOptions" name="DatePeriod" [setDate]="datePeriod"></ng2-flatpickr>
                </div>
                <div class="pl-50 pr-0" *ngIf="!filterData?.filterCleared">
                  <a (click)="clearFilter()" ngbTooltip="{{ 'COMMON.CONSTANT.CLEAR_FILTER' | translate }}">
                    <i class="text-primary mr-1" size="17" data-feather="eye-off"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/ Header -->
      </div>

      <ul class="col-12 pl-0 pr-0">
        <li class="col-12 card ml-0 mb-1 p-1 border-{{ service.statusColorCode }} border-lighten-5" *ngFor="let service of servicesList; let i = index">
          <div class="card-body p-75">
            <div class="d-flex flex-column flex-md-row align-content-between align-items-center">
              <!-- Column #1 -->
              <div class="d-flex align-items-center col-md-3 col-12 p-0">
                <div class="p-0">
                  <a (click)="onInvoiceOpen(service.orderedServiceID)">
                    <img class="" src="assets/images/icons/pdf.png" height="50" />
                  </a>
                </div>
                <div class="pl-1">
                  <div class="font-small-3 font-weight-normal line-height-1 mb-50">{{ 'SERVICES.TABLE.ORDER_DATE' | translate }} {{ service.orderDate | date:'yyyy-MM-dd HH:mm' }}</div>
                  <div class="font-small-3 font-weight-normal line-height-1 mb-75">{{ 'SERVICES.TABLE.ORDER_NO' | translate }}{{ service.orderNo }}</div>
                  <div class="font-small-3 font-weight-normal line-height-1 mb-50" [ngbTooltip]="tipContent" container="body">
                    {{ 'SERVICES.TABLE.SERVICE_NAME' | translate }}<span class="font-medium-1 font-weight-bold">{{ service.serviceName }}</span>
                    <i class="ml-50 feather font-small-3 text-info icon-info"></i>
                  </div>
                  <div class="font-small-3 font-weight-normal line-height-1">
                    {{ 'SERVICES.TABLE.SERICE_PRICE' | translate }}
                    <span class="font-medium-1 font-weight-bold">
                      {{ service.price | currency:'EUR' }}
                    </span>
                    /{{ service.serviceMeasure }}
                  </div>
                </div>
                <ng-template #tipContent>
                  <div [innerHTML]="service.serviceComments"></div>
                </ng-template>
              </div>

              <!-- Column #2 -->
              <div class="col-md-3 col-12 px-0 mt-md-0 mt-2">
                <div class="d-flex align-items-center justify-content-center">
                  <!-- Activate account -->
                  <div class="cell-line-height" *ngIf="service.buttonActivate">
                    <div class="cell-line-height">
                      <div class="d-flex">
                        <input type="text"
                               [(ngModel)]="service.vpnUserName"
                               class="form-control"
                               id="vpnUserName"
                               name="vpnUserName"
                               ngbTooltip="{{ 'SERVICES.TABLE.NEW_VNP_USER_TOOLTIP' | translate }}" container="body"
                               placeholder="{{ 'SERVICES.TABLE.NEW_VNP_USER_PLACEHOLDER' | translate }}" />
                      </div>
                      <div class="d-flex mt-1">
                        <div class="col-6 pl-0">
                          <button class="btn btn-success"
                                  [disabled]="service.vpnUserName=='' || service.vpnUserName==null || buttonActivatePressed"
                                  (click)="onActivateUser(service.orderedServiceID)"
                                  rippleEffect>
                            <span class="add-to-cart">{{ 'COMMON.ACTION.ACTIVATE_VPN_ACCOUNT' | translate }}</span>
                          </button>
                        </div>
                        <div class="d-flex col-6 pl-0 pr-0 align-content-end justify-content-end">
                          <button class="btn btn-outline-secondary"
                                  [disabled]="buttonActivatePressed"
                                  (click)="onCancelActivateUser(service.orderedServiceID)"
                                  rippleEffect>
                            <span class="add-to-cart">{{ 'COMMON.ACTION.CANCEL' | translate }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Show Activate user button if payment was successfull -->
                  <div class="cell-line-height" *ngIf="(service.vpnid=='' || service.vpnid==null) && !service.buttonActivate && service.statusID != this.appSettings.vpnAccountNotPaidStatus">
                    <div class="cell-line-height">
                      <button class="ml-0 btn btn-warning"
                              [disabled]="!buttonsEnabled"
                              (click)="onNewVPNUser(service.orderedServiceID)"
                              rippleEffect>
                        <span class="power">{{ 'COMMON.ACTION.NEW_VPN_ACCOUNT' | translate }}</span>
                      </button>
                    </div>
                  </div>
                  <!--/ Activate account -->
                  <!-- Change password -->
                  <div class="cell-line-height" *ngIf="service.buttonChangePsswd">
                    <p class="font-medium-2 font-weight-bolder text-center text-primary" *ngIf="!service.buttonChangePsswd">
                      {{ service.vpnUserName }}
                    </p>
                    <div class="cell-line-height">
                      <div class="d-flex">
                        <input type="text"
                               [(ngModel)]="service.newPassword"
                               class="form-control"
                               id="new-password"
                               name="newPassword"
                               placeholder="{{ 'SERVICES.TABLE.NEW_PASSWORD_PLACEHOLDER' | translate }}" />
                      </div>
                      <div class="d-flex mt-1">
                        <div class="col-6 pl-0">
                          <button class="btn btn-outline-success"
                                  [disabled]="service.newPassword=='' || buttonChangePasswordPressed"
                                  (click)="onSaveNewPassword(service.orderedServiceID, service.vpnid)"
                                  rippleEffect>
                            <span class="add-to-cart">{{ 'COMMON.ACTION.SAVE' | translate }}</span>
                          </button>
                        </div>
                        <div class="col-6 align-content-end justify-content-end">
                          <button class="btn btn-outline-secondary"
                                  (click)="onCancelNewPassword(service.orderedServiceID)"
                                  rippleEffect>
                            <span class="add-to-cart">{{ 'COMMON.ACTION.CANCEL' | translate }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="cell-line-height" *ngIf="(service.vpnid!='' && service.vpnid!=null) && !service.buttonChangePsswd">
                    <p class="font-medium-2 font-weight-bolder text-center text-primary">
                      {{ service.vpnUserName }}
                    </p>
                    <div class="cell-line-height">
                      <button class="ml-0 btn btn-outline-primary"
                              [disabled]="!buttonsEnabled"
                              (click)="onChangePassword(service.orderedServiceID)"
                              rippleEffect>
                        <span class="add-to-cart">{{ 'COMMON.ACTION.CHANGE_PSSWD' | translate }}</span>
                      </button>
                    </div>
                  </div>
                  <!--/ Change password -->
                </div>

              </div>

              <!-- Column #3 -->
              <div class="col-md-4 col-12 text-center px-0 mt-md-0 mt-2">
                <div class="badge badge-pill badge-light-{{ service.statusColorCode }} mb-50">
                  <span class="m-50 font-small-3">{{ service.status }}</span>
                </div>
                <div class="font-medium-1 font-weight-bold text-{{ service.statusColorCode }}">
                  {{ service.activeFrom | date:'yyyy-MM-dd' }} ... {{ service.activeTo | date:'yyyy-MM-dd' }}
                </div>
              </div>

              <!-- Column #4 -->
              <div class="col-md-2 col-12 px-md-0 pl-0 pr-1 mt-md-0 mt-2">
                <div class="d-flex flex-md-column" *ngIf="service.statusID != this.appSettings.vpnAccountNotPaidStatus">
                  <div class="col-md-12 col-6 mb-md-1 px-0">
                    <button class="col-12 btn btn-primary"
                            [disabled]="!buttonsEnabled"
                            (click)="onReNew(service.orderedServiceID, service.serviceID)"
                            rippleEffect>
                      <i data-feather="shopping-cart" class="mr-50"></i>
                      <span class="add-to-cart">{{ 'COMMON.ACTION.RENEW' | translate }}</span>
                    </button>
                  </div>


                  <div class="col-md-12 col-6 ml-md-0 ml-1 px-0">
                    <div class="form-group">
                      <div ngbDropdown container="body">
                        <button ngbDropdownToggle
                                class="col-12 btn btn-outline-success"
                                [disabled]="!buttonsEnabled"
                                rippleEffect>
                          <i data-feather="shopping-cart" class="mr-50"></i>
                          <span class="add-to-cart">{{ 'COMMON.ACTION.CHANGE_PLAN' | translate }}</span>
                        </button>
                        <div ngbDropdownMenu>
                          <div *ngFor="let price of priceList; let i = index; let c = count">
                            <a ngbDropdownItem (click)="onChangePlan(-1, price.serviceID)">
                              <h4 class="text-{{ price.colorCode }}">
                                <span class="bullet bullet-sm mr-md-50 bullet-{{ price.colorCode }}"></span>
                                {{ price.serviceName }}
                              </h4>
                              <h5 class="plan-price mt-md-2">
                                <span class="text-primary">{{ price.discountPrice | currency:'EUR'}}</span>
                                <span>/{{ price.measure }}</span>
                                <span *ngIf="price.quantity>1"> x {{ price.quantity + ' ' + price.measure }}</span>
                              </h5>
                              <div class="dropdown-divider" *ngIf="c!=i+1"></div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>

                <!-- If payment was failed show make payment and cancel order buttons -->
                <div class="d-flex flex-column align-items-center" *ngIf="service.statusID == this.appSettings.vpnAccountNotPaidStatus">
                  <div class="cell-line-height mb-1">
                    <button class="ml-0 btn btn-success"
                            [disabled]="!buttonsEnabled"
                            (click)="onMakePayment(service.orderedServiceID, service.serviceID)"
                            rippleEffect>
                      <i data-feather="credit-card" class="mr-50"></i>
                      <span class="add-to-cart">{{ 'COMMON.ACTION.MAKE_PAYMENT' | translate }}</span>
                    </button>
                  </div>
                  <div class="cell-line-height">
                    <button class="ml-0 btn btn-outline-secondary"
                            [disabled]="!buttonsEnabled"
                            (click)="onCancelOrder(service.orderedServiceID)"
                            rippleEffect>
                      <i data-feather="x-circle" class="mr-50"></i>
                      <span class="add-to-cart ml-50 mr-50">{{ 'COMMON.ACTION.CANCEL_PAYMENT' | translate }}</span>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>

    <section id="payment-methods" *ngIf="showPaymentMethods">
      <payment-select (onSelect)="onPaymentMethodSelect()"
                      (onCancel)="onPaymentMethodCancel()"
                      [cryptoinServicePrice]="selectedCryptoinServicePrice"
                      [orderedService]="selectedService">
      </payment-select>
    </section>
  </div>

</div>
