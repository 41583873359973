<div class="misc-wrapper">
  <a class="brand-logo" href="https://www.cryptoin.lt" target="_blank">
    <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="48" />
    <h2 class="brand-text text-primary ml-1 mt-1">{{ coreConfig.app.appName }}</h2>
  </a>
  <div class="misc-inner p-2 p-sm-3" *ngIf="!confirmError">
    <div class="w-100 text-center">
      <h2 class="mb-1">{{ 'COMMON.EMAIL_CONFIRM_PAGE.TITLE' | translate }}</h2>
      <p class="mb-2">{{ 'COMMON.EMAIL_CONFIRM_PAGE.TEXT' | translate }}</p>
      <img class="img-fluid"
           src="{{ _environment.picsPath }}/badge.svg"
           alt="Email address confirm page" />
    </div>
  </div>
</div>