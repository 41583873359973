import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import { CoreConfigService } from '@core/services/config.service';

import { CurrentPeriod } from 'app/common.function';
//import { PlanManagementService } from '../../plan-management/plan-management.service';
import { CommonService } from '../../common/common.service';
import { CryptoinMessagesList } from '../../common/common.data';
//import { VBCartList, VBPayedServicesList } from '../../plan-management/plan-management.data';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionResolve implements Resolve<any[]> {
  vbCurrentSubscription: any[];
  vbPayedServicesList: any[];
  cartList: any[];
  cryptoinMessagesList: CryptoinMessagesList[];

  public appLanguage: string = 'lt';

  public onVBCurrentSubscriptionChange: BehaviorSubject<any>;
  public onCartListChange: BehaviorSubject<any[]>;
  public onSOLCrmMessagesListChange: BehaviorSubject<any[]>;
  public onNewSOLCrmMessagesListChange: BehaviorSubject<any[]>;

  constructor(
    private _commonService: CommonService,
    //private _planManagementService: PlanManagementService,
    private _coreConfigService: CoreConfigService,
    private _datePipe: DatePipe
  ) {
    this.onVBCurrentSubscriptionChange = new BehaviorSubject({});
    this.onCartListChange = new BehaviorSubject([]);
    this.onSOLCrmMessagesListChange = new BehaviorSubject([]);
    this.onNewSOLCrmMessagesListChange = new BehaviorSubject([]);

    // Gauname sistemos kalbą
    this._coreConfigService.config.subscribe(config => {
      this.appLanguage = config.app.appLanguage;
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any[]> {
    return new Promise((resolve, reject) => {
      Promise.all([]).then(res => {
        resolve(res);
      }, reject);
    });
  }

/*
  getCurrentSubscriptionData(): Promise<any> {
    return new Promise((resolve, reject) => {
      let _currentPeriod = CurrentPeriod(this._datePipe);
      this._planManagementService.getCurrentSubscriptionData<VBPayedServicesList>(_currentPeriod.dateFrom, _currentPeriod.dateTo).subscribe((response: any) => {
        this.vbCurrentSubscription = response;
        this.onVBCurrentSubscriptionChange.next(this.vbCurrentSubscription);
        resolve(this.vbCurrentSubscription);
      }, reject);
    });
  }
*/
/*
  getPayedServicesList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._planManagementService.getPayedServicesList<VBPayedServicesList>().subscribe((response: any[]) => {
        this.vbPayedServicesList = response;
        resolve(this.vbPayedServicesList);
      }, reject);
    });
  }
*/
  /*
  getCartList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._planManagementService.getCartList<VBCartList>(-1).subscribe((response: any[]) => {
        this.cartList = response;
        this.onCartListChange.next(this.cartList);        
        resolve(this.cartList);
      }, reject);
    });
  }

  getNewSOLMessagesList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._commonService.getNewSOLMessages<SOLCrmMessagesList>().subscribe((response: any) => {
        this.solCrmMessagesList = response;
        this.onNewSOLCrmMessagesListChange.next(this.solCrmMessagesList);
        resolve(this.solCrmMessagesList);
      }, reject)
    });
  }

  getSOLMessagesList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._commonService.getSOLMessages<SOLCrmMessagesList>(1).subscribe((response: any) => {
        this.solCrmMessagesList = response;
        this.onSOLCrmMessagesListChange.next(this.solCrmMessagesList);
        resolve(this.solCrmMessagesList);
      }, reject)
    });
  }
  */

}
