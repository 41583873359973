import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { CoreConfigService } from '@core/services/config.service';

import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { CommonService } from 'app/cryptoin-vpn/common/common.service';
import { HelpService } from 'app/cryptoin-vpn/help/help.service';

@Component({
  selector: 'instruction-page',
  templateUrl: './instruction-page.component.html',
  styleUrls: ['./instruction-page.component.scss']
})
export class InstructionPageComponent implements OnInit, OnDestroy {
  _environment = environment;
  coreConfig: any;

  userName: string;
  password: string;

  openText: string;
  usernamePasswordText: string;
  userText: string;
  passwordText: string;
  powerText: string;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _route: ActivatedRoute,
    private _helpService: HelpService,
    private _httpClient: HttpClient,
    private _coreConfigService: CoreConfigService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(en);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.userName = this._route.snapshot.paramMap.get('UserName');
    this.password = this._route.snapshot.paramMap.get('Password');

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

    // Pasl�piame menu ir navigation bar
    this._coreConfigService.setConfig(
      { layout: { navbar: { hidden: true }, menu: { hidden: true } } },
      { emitEvent: true }
    );

    //--- Assign info text ---
    this.openText = this._translateService.instant('HELP.SETUP_VPN_CLIENT.SET_USER_PASSWORD.OPEN_SETTING');
    this.usernamePasswordText = this._translateService.instant('HELP.SETUP_VPN_CLIENT.SET_USER_PASSWORD.USERNAME_PASSWORD');
    //this.userText = this._translateService.instant('HELP.SETUP_VPN_CLIENT.SET_USER_PASSWORD.USERNAME') + '<b>' + this.userName + '</b>';
    //this.passwordText = this._translateService.instant('HELP.SETUP_VPN_CLIENT.SET_USER_PASSWORD.PASSWORD') + '<b>' + this.password + '</b>';
    this.powerText = this._translateService.instant('HELP.SETUP_VPN_CLIENT.CONNECT.CONNECT');
  }

  ngOnDestroy(): void {
    //--- Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onWindowsDownload() {
    //this.downloadFile('cryptoinvpn.exe');
    window.open("https://vpnclient.app/current/cryptoinvpn/cryptoinvpn.exe", "_self");
  }

  onMacDownload() {
    //this.downloadFile('cryptoinvpn.dmg');
    window.open("https://vpnclient.app/current/cryptoinvpn/cryptoinvpn.dmg", "_self");
  }

  onLinuxDownload() {
    //this.downloadFile('cryptoinvpn.run');
    window.open("https://vpnclient.app/current/cryptoinvpn/cryptoinvpn.run", "_self");
  }

  onGooglePayOpen() {
    window.open("https://play.google.com/store/apps/details?id=com.cryptoin.vpn", "_blank");
  }

  onAndroidDownload() {
    this.downloadFile('cryptoinvpn.apk');
  }

  oniTunesOpen() {
    window.open("https://apps.apple.com/app/cryptoinvpn-secure-private/id1670876184", "_blank");
  }

  downloadFile(_fileName: string) {
    this._toastrService.info('', this._translateService.instant('COMMON.NOTIFICATION.DOWNLOAD.START'),
      { toastClass: 'toast ngx-toastr', closeButton: false }
    );

    this._httpClient.get(this._helpService.baseUrl + 'assets/downloads/' + _fileName, { responseType: 'blob' }).subscribe((response: any) => {
      let blob: any = new Blob([response]);
      fileSaver.saveAs(blob, _fileName);

      this._toastrService.success('', this._translateService.instant('COMMON.NOTIFICATION.DOWNLOAD.FINISH'),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );
    });
  }
}
