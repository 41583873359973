import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { ToastrService } from 'ngx-toastr';
import { Login } from 'app/auth/models/login';
import { AuthenticationService } from 'app/auth/service/authentication.service';

@Component({
  selector: 'auth-reset-password',
  templateUrl: './auth-reset-password.component.html',
  styleUrls: ['./auth-reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthResetPasswordV2Component implements OnInit {
  // Public
  public coreConfig: any;
  public passwordTextType: boolean;
  public confPasswordTextType: boolean;
  public resetPasswordForm: FormGroup;
  public submitted = false;
  public resetAccessCode: string;

  public menuLocale = [
    { name: 'en', value: 'en' },
  ];
  public selectedMenuLocale: string = "en";

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _toastrService: ToastrService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService
  ) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en);

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };

    // Set the translations for the menu
    this._coreTranslationService.translate(en);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  /**
   * Toggle confirm password
   */
  toggleConfPasswordTextType() {
    this.confPasswordTextType = !this.confPasswordTextType;
  }

  setDialogLanguage() {
    // Use the selected language id for translations
    this._translateService.use(this.selectedMenuLocale);
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this._toastrService.info(
      this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TEXT'),
      this._translateService.instant('COMMON.NOTIFICATION.PROCESS_STARTED.TITLE'),
      { toastClass: 'toast ngx-toastr', closeButton: false }
    );

    if (this.resetPasswordForm.get("newPassword").value == this.resetPasswordForm.get("confirmPassword").value) {
      let _login: Login = <Login>{};
      _login.userName = "";
      _login.password = this.resetPasswordForm.get("newPassword").value;
      this._authenticationService.resetPassword(this.resetAccessCode, _login).subscribe((res: boolean) => {
        this._toastrService.success("",
          (res ? this._translateService.instant('ACCOUNT.DIALOG.RESET_PASSWORD.INFO.PASSWORD_CHANGED') : this._translateService.instant('ACCOUNT.DIALOG.RESET_PASSWORD.INFO.PASSWORD_NOT_CHANGED')),
          { toastClass: 'toast ngx-toastr', closeButton: false }
        );
        this._router.navigate(['/login']);
      });
    } else {
      this._toastrService.error("",
        this._translateService.instant('ACCOUNT.DIALOG.RESET_PASSWORD.CONFIRM_PASSWORD_ERROR1'),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );
    }
  }

  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      locale: ['en']
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

    this.resetAccessCode = this._route.snapshot.paramMap.get('ResetAccessCode');
    this._authenticationService.checkResetAccessCode(this.resetAccessCode).subscribe((res: boolean) => {
      if (!res) {
        // Rodome toastr ir nukeliame į klaidos puslapį
        this._toastrService.error("",
          this._translateService.instant('ACCOUNT.DIALOG.RESET_PASSWORD.RAC_NOT_VALID'),
          { toastClass: 'toast ngx-toastr', closeButton: false }
        );
        this._router.navigate(['/error']);
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
