import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';

import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as en } from 'app/cryptoin-vpn/_i18n/en';

import { CryptoinServicesPricesList } from '../../common/common.data';
import { CommonService } from '../../common/common.service';
import { FAQList } from '../../help/help.data';
import { HelpService } from '../../help/help.service';
//import { ServiceService } from '../service.service';

@Component({
  selector: 'vpn-client-list',
  templateUrl: './vpn-client-list.component.html',
  styleUrls: ['./vpn-client-list.component.scss']
})
export class VPNClientListComponent implements OnInit, OnDestroy {
  _environment = environment;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _commonService: CommonService,
    private _helpService: HelpService,
    private _httpClient: HttpClient,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(en);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    //--- Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onWindowsDownload() {
    //OLD version >> window.open(this._helpService.baseUrl + "assets/downloads/vpnclient.exe", "_self");
    window.open("https://vpnclient.app/current/cryptoinvpn/cryptoinvpn.exe", "_self");
  }

  onMacDownload() {
    //OLD version >> window.open(this._helpService.baseUrl + "assets/downloads/vpnclient.dmg", "_self");
    window.open("https://vpnclient.app/current/cryptoinvpn/cryptoinvpn.dmg", "_self");
  }

  onLinuxDownload() {
    //OLD version >>  window.open(this._helpService.baseUrl + "assets/downloads/vpnclient.run", "_self");
    window.open("https://vpnclient.app/current/cryptoinvpn/cryptoinvpn.run", "_self");
  }

  onGooglePayOpen() {
    window.open("https://play.google.com/store/apps/details?id=com.cryptoin.vpn", "_blank");
  }

  onAndroidDownload() {
    //OLD version >> window.open(this._helpService.baseUrl + "assets/downloads/app-release-VPNClient.apk", "_self");
    this.downloadFile('cryptoinvpn.apk');
  }

  oniTunesOpen() {
    window.open("https://apps.apple.com/app/cryptoinvpn-secure-private/id1670876184", "_blank");
  }

  downloadFile(_fileName: string) {
    this._toastrService.info('', this._translateService.instant('COMMON.NOTIFICATION.DOWNLOAD.START'),
      { toastClass: 'toast ngx-toastr', closeButton: false }
    );

    this._httpClient.get(this._helpService.baseUrl + 'assets/downloads/' + _fileName, { responseType: 'blob' }).subscribe((response: any) => {
      let blob: any = new Blob([response]);
      fileSaver.saveAs(blob, _fileName);

      this._toastrService.success('', this._translateService.instant('COMMON.NOTIFICATION.DOWNLOAD.FINISH'),
        { toastClass: 'toast ngx-toastr', closeButton: false }
      );
    });
  }
}
